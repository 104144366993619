import React, { useEffect, useState } from "react";
import { getCmsContentDetails, getFaqList } from "../../Api/apiHandler";
import * as Common from "../../utils/Common";
import { useParams } from "react-router-dom";

const Preview = () => {
  let { page } = useParams();

  const [cmsContent, setCmsContent] = useState(null);

  const fetchCMSContentDetails = async (request) => {
    try {
      const response =
        request?.page_name === "faq"
          ? await getFaqList({ is_active: 1, ...request })
          : await getCmsContentDetails(request);

      if (response.code === 1) {
        setCmsContent(response.data);
      } else {
        setCmsContent(null);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setCmsContent(null);
    fetchCMSContentDetails({ page_name: page });
  }, [page]);

  const Faq = () => {
    return (
      <>
        <div className="row my-5">
          <div className="col-12">
            <div className="text-center">
              <i className="h1 mdi mdi-comment-multiple-outline text-muted" />
              <h3 className="my-3">Frequently Asked Questions</h3>
            </div>
          </div>
          {/* end col */}
        </div>

        <div className="row pt-md-5  px-md-5">
          {cmsContent?.data?.map((item) => {
            return (
              <div className="col-lg-6 px-3">
                <div className="faq-question-q-box">Q.</div>
                <h4 className="faq-question" data-wow-delay=".1s">
                  {item.question}
                </h4>
                <p className="faq-answer mb-4">{item.answer}</p>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      {page === "faq" ? (
        <Faq />
      ) : (
        <div
          style={{
            backgroundColor: "white",
            color: "black",
            minHeight: "100vh",
          }}
        >
          <div style={{ maxWidth: "800px", margin: "auto" }}>
            <center style={{ textAlign: "justify" }}>
              <div
              className="m-3"
                dangerouslySetInnerHTML={{ __html: cmsContent?.content }}
              ></div>
            </center>
          </div>
        </div>
      )}
    </>
  );
};

export default Preview;
