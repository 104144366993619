import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  editSubAdmin,
  getCountryList,
  detailsSubAdmin,
} from "../../Api/apiHandler";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Common from "../../utils/Common";
import Globals from "../../utils/Constant";
import Select from "react-select";

export default function Edit() {
  let { id } = useParams();

  const [countryList, setCountryList] = useState(null);
  console.log("countryList: ", countryList);
  const [adminDetails, setAdminDetails] = useState(null);

  const navigate = useNavigate();

  const fetchCountryList = async (request) => {
    try {
      const response = await getCountryList(request);

      // Update state with new data and total count
      if (response.code === 1) {
        setCountryList(response.data);
      } else {
        setCountryList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  };

  const fetchAdminDetails = async (request) => {
    try {
      const response = await detailsSubAdmin(request);

      // Update state with new data and total count
      if (response.code === 1) {
        console.log("in fetchAdminDetails list", response.data);
        setAdminDetails(response.data);
      } else {
        setAdminDetails();
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    email: Yup.string().required("Please select email"),
    country_code: Yup.string().required("Please select country code"),
    permission: Yup.array().min(1, "Select at least one permission"),
    phone: Yup.string()
      .required("Please enter phone number")
      .matches("^[0-9]", "Only numbers are allowed for this field")
      .min(8, "Phone must be at least 8 numbers")
      .max(14, "Phone must be at most 14 numbers"),
  });

  console.log("adminDetails?.country_code: ", adminDetails?.country_code);
  const { handleSubmit, register, setValue, watch, formState } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { errors } = formState;

  const onSubmit = async (data) => {
    let adminData = {
      admin_id: atob(id),
      name: data.name.trimStart().trimEnd(),
      email: data.email,
      permissions: data.permission,
      country_code: data.country_code,
      phone: data.phone,
    };
    console.log("adminData: ", adminData);

    try {
      const response = await editSubAdmin(adminData);
      if (response.code === 1) {
        Common.SuccessAlert(response.message);
        setTimeout(() => {
          navigate("/subAdmin");
        }, 1000);
      } else {
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error while adding data:", error);
    }
  };

  const handlePermissionChange = (selectedOptions) => {
    // Extract values from selected options
    const selectedValues = selectedOptions.map((option) => option.value);
    setValue("permission", selectedValues);
  };

  const handleCountryChange = (selectedOptions) => {
    console.log("selectedOptions: ", selectedOptions);
    setValue("country_code", selectedOptions?.phone_code);
  };
  // Get Listing
  useEffect(() => {
    fetchCountryList({});
    fetchAdminDetails({ admin_id: atob(id) });
  }, [id]);

  if (countryList === null) return <> </>;
  if (adminDetails === null) return <> </>;
  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">
                          {process.env.REACT_APP_NAME}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/subAdmin">Sub Admin</Link>
                      </li>
                      <li className="breadcrumb-item active">Edit Sub Admin</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Edit Sub Admin</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="mb-3">
                    <button
                      className="btn btn-custom text-capitalize custom-back"
                      onClick={() => navigate(-1)}
                    >
                      <i className="fe-arrow-left font-28"></i>
                    </button>
                  </div>
                  <div className="card-body mt-2">
                    <div className="row">
                      <form id="add_admin" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <label htmlFor="name" className="form-label">
                              Name
                            </label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              className="form-control"
                              placeholder="Name"
                              defaultValue={adminDetails?.name}
                              {...register("name")}
                            />
                            <div className="forerror">
                              <span className="validation_error text-justify">
                                {errors.name?.message}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-3">
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                            <input
                              type="text"
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Add Email"
                              defaultValue={adminDetails?.email}
                              {...register("email")}
                            />
                            <div className="forerror">
                              <span className="validation_error text-justify">
                                {errors.email?.message}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-3 mb-3">
                            <label
                              htmlFor="country_code"
                              className="form-label"
                            >
                              Country Code
                            </label>
                            <Select
                              defaultValue={countryList?.find(
                                (item) =>
                                  item.phone_code === adminDetails.country_code
                              )}
                              name="country_code"
                              options={countryList}
                              getOptionLabel={(option) =>
                                option.phone_code + " " + option.name
                              }
                              getOptionValue={(option) => option.phone_code}
                              placeholder="Select Country"
                              {...register("country_code", {
                                value: countryList?.find(
                                  (item) =>
                                    item.phone_code === adminDetails.country_code
                                )?.phone_code,
                              })}
                              onChange={(e) => {
                                handleCountryChange(e);
                              }}
                            />

                            <div className="forerror">
                              <span className="validation_error text-justify">
                                {errors.country_code?.message}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-4 mb-3">
                            <label htmlFor="phone" className="form-label">
                              Phone
                            </label>
                            <input
                              type="text"
                              id="phone"
                              defaultValue={adminDetails?.phone}
                              {...register("phone")}
                              className="form-control"
                              placeholder="Phone Number"
                            />
                            <div className="forerror">
                              <span className="validation_error text-justify">
                                {errors.phone?.message}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <label className="form-label">Permission</label>
                            <Select
                              {...register("permission", {
                                value: adminDetails?.permissions,
                              })}
                              isMulti
                              options={Globals.PERMISSIONS?.filter(
                                (i) => i.value !== "Sub Admin"
                              )}
                              placeholder="Select permissions"
                              onChange={handlePermissionChange}
                              defaultValue={
                                adminDetails?.permissions.map((permission) => ({
                                  value: permission,
                                  label: permission,
                                })) || []
                              }
                            />
                            <div className="forerror">
                              <span className="validation_error text-justify">
                                {!watch("permission")?.length &&
                                  errors.permission?.message}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-lg-6  offset-md-5">
                            <button
                              type="submit"
                              className="btn btn-raised btn-custom  waves-effect me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              className="btn btn-cancel waves-effect waves-light"
                              onClick={() => navigate(-1)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
