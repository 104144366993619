import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { addSubAdmin, getCountryList } from "../../Api/apiHandler";
import { Link, useNavigate } from "react-router-dom";
import * as Common from "../../utils/Common";
import Globals from "../../utils/Constant";
import Select from "react-select";

export default function Add() {
  const [countryList, setCountryList] = useState(null);

  const navigate = useNavigate();

  const fetchCountryList = async (request) => {
    try {
      const response = await getCountryList(request);

      // Update state with new data and total count
      if (response.code === 1) {
        setCountryList(response.data);
      } else {
        setCountryList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  };

  const handlePermissionChange = (selectedOptions) => {
    // Extract values from selected options
    const selectedValues = selectedOptions.map((option) => option.value);
    setValue("permission", selectedValues);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    email: Yup.string().email().required("Please Enter email"),
    password: Yup.string().required("Please enter password"),
    permission: Yup.array().required(),
    country_code: Yup.string().required("Please select country code"),
    phone: Yup.string()
      .required("Please enter phone number")
      .matches("^[0-9]", "Only numbers are allowed for this field")
      .min(8, "Phone must be at least 8 numbers")
      .max(14, "Phone must be at most 14 numbers"),
  });

  const { handleSubmit, register, setValue, watch, formState } =
    useForm({
      defaultValues: {
        country_code: "",
      },
      resolver: yupResolver(validationSchema),
    });

  const { errors } = formState;
  console.log("58errors: ", watch("country_code"), errors);

  const onSubmit = async (data) => {
    console.log(data, "data final123 ");

    // return
    let adminData = {
      name: data.name.trimStart().trimEnd(),
      email: data.email,
      password: data.password,
      permissions: data.permission,
      country_code: data.country_code,
      phone: data.phone,
    };

    try {
      const response = await addSubAdmin(adminData);
      if (response.code === 1) {
        Common.SuccessAlert(response.message);
        setTimeout(() => {
          navigate("/subAdmin");
        }, 1000);
      } else {
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error while adding data:", error);
    }
  };

  const handleCountryChange = (selectedOptions) => {
    console.log("selectedOptions: ", selectedOptions);
    setValue("country_code", selectedOptions?.phone_code);
  };

  // Get Listing
  useEffect(() => {
    fetchCountryList({});
  }, []);

  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">
                          {process.env.REACT_APP_NAME}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/subAdmin">Sub Admin</Link>
                      </li>
                      <li className="breadcrumb-item active">Add Sub Admin</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Add Sub Admin</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="mb-3">
                    <button
                      className="btn btn-custom text-capitalize custom-back"
                      onClick={() => navigate(-1)}
                    >
                      <i className="fe-arrow-left font-28"></i>
                    </button>
                  </div>
                  <div className="card-body mt-2">
                    <div className="row">
                      <form id="add_admin" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <label htmlFor="name" className="form-label">
                              Name
                            </label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              className="form-control"
                              placeholder="Name"
                              {...register("name")}
                            />
                            <div className="forerror">
                              <span className="validation_error text-justify">
                                {errors.name?.message}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-3">
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                            <input
                              type="text"
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Add Email"
                              {...register("email")}
                            />
                            <div className="forerror">
                              <span className="validation_error text-justify">
                                {errors.email?.message}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-3 mb-3">
                            <label
                              htmlFor="country_code"
                              className="form-label"
                            >
                              Country Code
                            </label>
                            <Select
                              {...register("country_code")}
                              onChange={handleCountryChange}
                              options={countryList}
                              getOptionLabel={(option) =>
                                option.phone_code + " " + option.name
                              }
                              getOptionValue={(option) => option.phone_code}
                              placeholder="Select Country"
                            />

                            <div className="forerror">
                              <span className="validation_error text-justify">
                                {watch("country_code") === "" &&
                                  errors.country_code?.message}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-4 mb-3">
                            <label htmlFor="phone" className="form-label">
                              Phone
                            </label>
                            <input
                              type="text"
                              id="phone"
                              {...register("phone")}
                              className="form-control"
                              placeholder="Phone Number"
                            />
                            <div className="forerror">
                              <span className="validation_error text-justify">
                                {errors.phone?.message}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-5 mb-3">
                            <label htmlFor="password" className="form-label">
                              Password
                            </label>
                            <input
                              type="password"
                              id="password"
                              {...register("password")}
                              className="form-control"
                              placeholder="password"
                            />
                            <div className="forerror">
                              <span className="validation_error text-justify">
                                {errors.password?.message}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <label className="form-label">Permission</label>
                            <Select
                              {...register("permission")}
                              isMulti
                              options={Globals.PERMISSIONS?.filter(
                                (i) => i.value !== "Sub Admin"
                              )}
                              placeholder="Select permissions"
                              onChange={handlePermissionChange}
                              defaultValue={[]}
                            />
                            <div className="forerror">
                              <span className="validation_error text-justify">
                                {!watch("permission")?.length &&
                                  errors.permission?.message}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-lg-6  offset-md-5">
                            <button
                              type="submit"
                              className="btn btn-raised btn-custom  waves-effect me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              className="btn btn-cancel waves-effect waves-light"
                              onClick={() => navigate(-1)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
