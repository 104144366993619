import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { contactUsSendReply, getContactUsDetails } from '../../Api/apiHandler';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Common from '../../utils/Common';


export default function ContactUsReply() {


    const navigate = useNavigate();
    let { id } = useParams();
    const [contactUsDetails, setContactUSDetails] = useState(null);


    useEffect(() => {
        const fetchcontactUsDetails = async (request) => {

            try {
                if (id !== undefined) {

                    const response = await getContactUsDetails(request);

                    if (response.code === 1) {
                        setContactUSDetails(response.data)
                    }
                }

            } catch (error) {
                Common.ErrorAlert('Oops! Something went wrong');
                console.error('Error fetching data:', error);
            }
        }

        fetchcontactUsDetails({ contactus_id: atob(id) })
    }, [id]);


    const validationSchema = Yup.object().shape({
        reply_text: Yup.string().required("Please enter reply text"),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = async (data) => {

        let replyText = {
            contactus_id: contactUsDetails._id,
            reply_text: data.reply_text,
            email: data.email
        }

        try {
            const response = await contactUsSendReply(replyText);

            if (response.code === 1) {
                Common.SuccessAlert(response.message)
                setTimeout(() => {
                    navigate("/contact-us")
                }, 1000)
            } else {
                Common.ErrorAlert(response.message);
            }
        } catch (error) {
            Common.ErrorAlert('Oops! Something went wrong');
            console.error('Error while adding data:', error);
        }

    }


    if (contactUsDetails == null) return <> </>
    return (
        <>
            <div className="content-page">
                <div className="content">

                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box">
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><Link to='/dashboard'>{process.env.REACT_APP_NAME}</Link></li>
                                            <li className="breadcrumb-item"><Link to='/contact-us'>Contact US</Link></li>
                                            <li className="breadcrumb-item active">Send Reply</li>
                                        </ol>
                                    </div>
                                    <h4 className="page-title">Send Reply</h4>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="mb-3">
                                        <button className="btn btn-custom text-capitalize custom-back" onClick={() => navigate(-1)}><i className="fe-arrow-left font-28"></i></button>
                                    </div>
                                    <div className="card-body">

                                        <div className="row">

                                            <form id='edit_user' onSubmit={handleSubmit(onSubmit)}>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <label htmlFor="name" className="form-label">Email</label>
                                                        <input type="text" id="email" className="form-control" placeholder="Email" {...register('email')} defaultValue={contactUsDetails.email} readOnly={true} />
                                                        <div className="forerror">
                                                            <span className="validation_error text-justify">{errors.email?.message}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12 mb-3">
                                                        <label htmlFor="name" className="form-label">Subject</label>
                                                        <input type="text" id="subject" className="form-control" placeholder="Subject" {...register('subject')} defaultValue={contactUsDetails.subject} readOnly={true} />
                                                        <div className="forerror">
                                                            <span className="validation_error text-justify">{errors.subject?.message}</span>
                                                        </div>
                                                    </div>


                                                </div>


                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <label htmlFor="example-textarea" className="form-label">Description</label>
                                                        <textarea className="form-control" id="example-textarea" placeholder="Description" defaultValue={contactUsDetails.description}  {...register('description')} rows="5" readOnly={true}></textarea>
                                                        <div className="forerror">
                                                            <span className="validation_error text-justify">{errors.description?.message}</span>
                                                        </div>
                                                    </div>


                                                    <div className="col-lg-12 mb-3">
                                                        <label htmlFor="example-textarea" className="form-label">Reply Text</label>
                                                        <textarea className="form-control" id="example-textarea" placeholder="Reply Text" defaultValue={contactUsDetails.reply_text}  {...register('reply_text')} rows="5" readOnly={contactUsDetails?.is_reply === 1}></textarea>
                                                        <div className="forerror">
                                                            <span className="validation_error text-justify">{errors.reply_text?.message}</span>
                                                        </div>
                                                    </div>

                                                </div>



                                                <div className="row mt-3">
                                                    <div className="col-lg-6  offset-md-5">
                                                        <button type="submit" className="btn btn-raised btn-custom  waves-effect me-2" disabled={contactUsDetails.is_reply===1}>
                                                            Send
                                                        </button>
                                                        <button type="button" className="btn btn-cancel waves-effect waves-light" onClick={() => navigate(-1)}>
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </>
    )
}
