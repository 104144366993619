import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import Footer from '../Components/footer/Footer.js';
import Topbar from '../Components/topbar/Topbar.js';
import Sidebar from '../Components/sidebar/Sidebar.js';
export default function PrivateRoute() {
    const auth = sessionStorage.getItem("AdminToken");
// console.log(auth,'auth in auth');
    return auth ?
        <>
            <div id="wrapper">
                <Topbar />
                <Sidebar />
                <Outlet />
                <Footer />
            </div>

        </> :
        <Navigate to='/' />

}
