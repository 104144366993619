import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function Sidebar() {
  const location = useLocation();
  const adminRole = sessionStorage.getItem("AdminRole");
  const adminData = JSON.parse(sessionStorage.getItem("AdminData"));
  let permission = adminData.permissions;

  if (adminRole !== "1") {
    console.log(
      sessionStorage.getItem("AdminRole"),
      "sessionStorage.getItem(AdminRole)"
    );
    console.log(permission, "sessionStorage.getItem(permission)");
    console.log(
      sessionStorage.getItem("AdminRole") === "0",
      " sessionStorage.getItem(ad)"
    );
    console.log(
      permission.includes("User"),
      "inclusede sessionStorage.getItem(ad)"
    );
  }

  return (
    <>
      <div className="left-side-menu">
        <div className="h-100" data-simplebar>
          <div id="sidebar-menu">
            <ul id="side-menu">
              <li className="menu-title">Navigation</li>

              {/* {sessionStorage.getItem('AdminRole') === '1' || (sessionStorage.getItem('AdminRole') === '0' && permission.includes('Sub Admin')) && (
                null
              )} */}

              <li
                className={`${
                  location.pathname.split("/")[1] === "dashboard"
                    ? "menuitem-active"
                    : ""
                }`}
              >
                <Link
                  to="/dashboard"
                  className={`${
                    location.pathname === "/dashboard" ? "active" : ""
                  }`}
                >
                  <i className="mdi mdi-view-dashboard-outline"></i>
                  <span className="text-uppercase"> Dashboard </span>
                </Link>
              </li>

              {sessionStorage.getItem("AdminRole") === "1" ? (
                <li
                  className={`${
                    location.pathname.split("/")[1] === "subAdmin"
                      ? "menuitem-active"
                      : ""
                  }`}
                >
                  <Link
                    to="/subAdmin"
                    className={`${
                      location.pathname === "/subAdmin" ? "active" : ""
                    }`}
                  >
                    <i className="mdi mdi-account-child-outline"></i>
                    <span className="text-uppercase"> Sub Admin </span>
                  </Link>
                </li>
              ) : null}

              {sessionStorage.getItem("AdminRole") === "1" ||
              (sessionStorage.getItem("AdminRole") === "0" &&
                permission.includes("User")) ? (
                <li
                  className={`${
                    location.pathname.split("/")[1] === "user"
                      ? "menuitem-active"
                      : ""
                  }`}
                >
                  <Link
                    to="/user"
                    className={`${
                      location.pathname === "/user" ? "active" : ""
                    }`}
                  >
                    <i className="mdi mdi-human-child"></i>
                    {/* <span className="badge bg-app-theme float-end">5</span> */}
                    <span className="text-uppercase"> User </span>
                  </Link>
                </li>
              ) : null}

              {sessionStorage.getItem("AdminRole") === "1" ||
              (sessionStorage.getItem("AdminRole") === "0" &&
                permission.includes("Cafe")) ? (
                <li
                  className={`${
                    location.pathname.split("/")[1] === "cafe"
                      ? "menuitem-active"
                      : ""
                  }`}
                >
                  <Link
                    to="/cafe"
                    className={`${
                      location.pathname === "/cafe" ? "active" : ""
                    }`}
                  >
                    <i className="mdi mdi-store"></i>
                    {/* <span className="badge bg-app-theme float-end">5</span> */}
                    <span className="text-uppercase"> Cafe </span>
                  </Link>
                </li>
              ) : null}

              {sessionStorage.getItem("AdminRole") === "1" ||
              (sessionStorage.getItem("AdminRole") === "0" &&
                permission.includes("Event")) ? (
                <li
                  className={`${
                    location.pathname.endsWith("/event/virtual") ||
                    location.pathname.endsWith("/event/realtimematch")
                      ? "menuitem-active"
                      : ""
                  }`}
                >
                  <a
                    href="#sidebarMultilevel3"
                    data-bs-toggle="collapse"
                    className="collapsed "
                    aria-expanded="false"
                  >
                    <i className="mdi mdi-format-list-bulleted font-22 "></i>
                    <span> EVENT </span>
                    <span className="menu-arrow"></span>
                  </a>
                  <div
                    className="collapse menuitem-active"
                    id="sidebarMultilevel3"
                  >
                    <ul className="nav-second-level">
                      <li
                        className={`${
                          location.pathname.endsWith("/event/virtual")
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/event/virtual"
                          className={`${
                            location.pathname.endsWith("/event/virtual")
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-store font-16"></i>
                          <span> Virtual </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          location.pathname.endsWith("/event/realtimematch")
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/event/realtimematch"
                          className={`${
                            location.pathname.endsWith("/event/realtimematch")
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-store font-16"></i>
                          <span> Real Time Matching </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : null}

              {sessionStorage.getItem("AdminRole") === "1" ||
              (sessionStorage.getItem("AdminRole") === "0" &&
                permission.includes("Reports")) ? (
                <li
                  className={`${
                    location.pathname.endsWith("/reports/virtual") ||
                    location.pathname.endsWith("/reports/realtimematch")
                      ? "menuitem-active"
                      : ""
                  }`}
                >
                  <a
                    href="#sidebarMultilevel8"
                    data-bs-toggle="collapse"
                    className="collapsed "
                    aria-expanded="false"
                  >
                    <i className="mdi mdi-format-list-bulleted font-22 "></i>
                    <span> REPORTS </span>
                    <span className="menu-arrow"></span>
                  </a>
                  <div
                    className="collapse menuitem-active"
                    id="sidebarMultilevel8"
                  >
                    <ul className="nav-second-level">
                      <li
                        className={`${
                          location.pathname.endsWith("/reports/virtual")
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/reports/virtual"
                          className={`${
                            location.pathname.endsWith("/reports/virtual")
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-store font-16"></i>
                          <span> Virtual </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          location.pathname.endsWith("/reports/realtimematch")
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/reports/realtimematch"
                          className={`${
                            location.pathname.endsWith("/reports/realtimematch")
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-store font-16"></i>
                          <span> Real Time Matching </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : null}

              {sessionStorage.getItem("AdminRole") === "1" ||
              (sessionStorage.getItem("AdminRole") === "0" &&
                permission.includes("Registered User")) ? (
                <li
                  className={`${
                    location.pathname.split("/")[1] === "registered-users"
                      ? "menuitem-active"
                      : ""
                  }`}
                >
                  <Link
                    to="/registered-users"
                    className={`${
                      location.pathname === "/registered-users" ? "active" : ""
                    }`}
                  >
                    <i className="mdi mdi-format-list-bulleted font-22 "></i>
                    <span className="text-uppercase"> Registered Users </span>
                  </Link>
                </li>
              ) : null}

              {sessionStorage.getItem("AdminRole") === "1" ||
              (sessionStorage.getItem("AdminRole") === "0" &&
                permission.includes("Earning")) ? (
                <li
                  className={`${
                    location.pathname.endsWith("/earning/virtual") ||
                    location.pathname.endsWith("/earning/realtimematch") ||
                    location.pathname.endsWith("/earning/refundearning")
                      ? "menuitem-active"
                      : ""
                  }`}
                >
                  <a
                    href="#sidebarMultilevel4"
                    data-bs-toggle="collapse"
                    className="collapsed "
                    aria-expanded="false"
                  >
                    <i className="mdi mdi-currency-usd-circle-outline font-22 "></i>
                    <span>EARNINGS</span>
                    <span className="menu-arrow"></span>
                  </a>
                  <div
                    className="collapse menuitem-active"
                    id="sidebarMultilevel4"
                  >
                    <ul className="nav-second-level">
                      <li
                        className={`${
                          location.pathname.endsWith("/earning/virtual")
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/earning/virtual"
                          className={`${
                            location.pathname.endsWith("/earning/virtual")
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-store font-16"></i>
                          <span> Virtual Event </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          location.pathname.endsWith("/earning/realtimematch")
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/earning/realtimematch"
                          className={`${
                            location.pathname.endsWith("/earning/realtimematch")
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-store font-16"></i>
                          <span> Real Time Match Event </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          location.pathname.endsWith("/earning/refundearning")
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/earning/refundearning"
                          className={`${
                            location.pathname.endsWith("/earning/refundearning")
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-store font-16"></i>
                          <span> Refund History</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : null}

              {sessionStorage.getItem("AdminRole") === "1" ||
              (sessionStorage.getItem("AdminRole") === "0" &&
                permission.includes("Notification")) ? (
                <li
                  className={`${
                    location.pathname.split("/")[1] === "notification"
                      ? "menuitem-active"
                      : ""
                  }`}
                >
                  <Link
                    to="/notification"
                    className={`${
                      location.pathname === "/notification" ? "active" : ""
                    }`}
                  >
                    <i className="mdi mdi-bell"></i>
                    {/* <span className="badge bg-app-theme float-end">5</span> */}
                    <span className="text-uppercase"> Notifications </span>
                  </Link>
                </li>
              ) : null}

              {sessionStorage.getItem("AdminRole") === "1" ||
              (sessionStorage.getItem("AdminRole") === "0" &&
                permission.includes("Contact Us")) ? (
                <li
                  className={`${
                    location.pathname.split("/")[1] === "contact-us"
                      ? "menuitem-active"
                      : ""
                  }`}
                >
                  <Link
                    to="/contact-us"
                    className={`${
                      location.pathname === "/contact-us" ? "active" : ""
                    }`}
                  >
                    <i className="mdi mdi-lifebuoy font-22 "></i>
                    <span> CONTACT US </span>
                  </Link>
                </li>
              ) : null}

              {sessionStorage.getItem("AdminRole") === "1" ||
              (sessionStorage.getItem("AdminRole") === "0" &&
                permission.includes("CMS")) ? (
                <li
                  className={`${
                    location.pathname.split("/")[1] === "about-us" ||
                    location.pathname.split("/")[1] === "terms-conditions" ||
                    location.pathname.split("/")[1] === "privacy-policy"
                      ? "menuitem-active"
                      : ""
                  }`}
                >
                  <a
                    href="#sidebarMultilevel2"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    className={`${
                      location.pathname.split("/")[1] === "about-us" ||
                      location.pathname.split("/")[1] === "terms-conditions" ||
                      location.pathname.split("/")[1] === "faq" ||
                      location.pathname.split("/")[1] === "privacy-policy"
                        ? "menuitem-active collapsed"
                        : "collapsed"
                    }`}
                  >
                    <i className="mdi mdi-format-list-bulleted font-22 "></i>
                    <span>CMS Pages</span>
                    <span className="menu-arrow"></span>
                  </a>
                  <div
                    className="collapse menuitem-active"
                    id="sidebarMultilevel2"
                  >
                    <ul className="nav-second-level">
                      <li
                        className={`${
                          location.pathname.split("/")[1] === "about-us"
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/about-us"
                          className={`${
                            location.pathname === "/about-us" ? "active" : ""
                          }`}
                        >
                          <i className="mdi mdi-briefcase-account font-16 "></i>
                          <span> ABOUT US </span>
                        </Link>
                      </li>

                      <li
                        className={`${
                          location.pathname.split("/")[1] === "terms-conditions"
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/terms-conditions"
                          className={`${
                            location.pathname === "/terms-conditions"
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-file-document-outline font-16 "></i>
                          <span> TERMS & CONDITION </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          location.pathname.split("/")[1] === "privacy-policy"
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/privacy-policy"
                          className={`${
                            location.pathname === "/privacy-policy"
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-file-lock font-16 "></i>
                          <span> PRIVACY POLICY </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          location.pathname.split("/")[1] === "faq"
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/faq"
                          className={`${
                            location.pathname === "/faq" ? "active" : ""
                          }`}
                        >
                          <i className="mdi mdi-lifebuoy font-16 "></i>
                          <span> FAQ </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          location.pathname.split("/")[1] ===
                          "online-speed-dating-guideline"
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/online-speed-dating-guideline"
                          className={`text-uppercase ${
                            location.pathname ===
                            "/online-speed-dating-guideline"
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-lifebuoy font-16 "></i>
                          <span> Online Speed Dating Guideline </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          location.pathname.split("/")[1] ===
                          "offline-dating-guideline"
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/offline-dating-guideline"
                          className={`text-uppercase ${
                            location.pathname === "/offline-dating-guideline"
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-lifebuoy font-16 "></i>
                          <span> Offline Dating Guideline </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          location.pathname.split("/")[1] ===
                          "payment-guideline"
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/payment-guideline"
                          className={`text-uppercase ${
                            location.pathname === "/payment-guideline"
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-lifebuoy font-16 "></i>
                          <span> Payment Guideline </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          location.pathname.split("/")[1] ===
                          "online_speed_dating_info"
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/online_speed_dating_info"
                          className={`text-uppercase ${
                            location.pathname === "/online_speed_dating_info"
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-lifebuoy font-16 "></i>
                          <span> Online Speed Dating Info </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          location.pathname.split("/")[1] ===
                          "offline_blind_dating_info"
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/offline_blind_dating_info"
                          className={`text-uppercase ${
                            location.pathname === "/offline_blind_dating_info"
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-lifebuoy font-16 "></i>
                          <span> Offline Blind Dating Info </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          location.pathname.split("/")[1] ===
                          "online_speed_dating_pre_registration"
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/online_speed_dating_pre_registration"
                          className={`text-uppercase ${
                            location.pathname ===
                            "/online_speed_dating_pre_registration"
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-lifebuoy font-16 "></i>
                          <span> Online Speed Dating Pre Registration </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          location.pathname.split("/")[1] ===
                          "offline_blind_dating_pre_registration"
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/offline_blind_dating_pre_registration"
                          className={`text-uppercase ${
                            location.pathname ===
                            "/offline_blind_dating_pre_registration"
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-lifebuoy font-16 "></i>
                          <span> Offline Blind Dating Pre Registration </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          location.pathname.split("/")[1] === "refund_policy"
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/refund_policy"
                          className={`text-uppercase ${
                            location.pathname === "/refund_policy"
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-lifebuoy font-16 "></i>
                          <span> Refund Policy </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          location.pathname.split("/")[1] ===
                          "info_until_event_start"
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/info_until_event_start"
                          className={`text-uppercase ${
                            location.pathname === "/info_until_event_start"
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-lifebuoy font-16 "></i>
                          <span> Info Untill Event Start </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          location.pathname.split("/")[1] ===
                          "safety_tips_and_guidelines"
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/safety_tips_and_guidelines"
                          className={`text-uppercase ${
                            location.pathname === "/safety_tips_and_guidelines"
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-lifebuoy font-16 "></i>
                          <span> Safety Tips and Guidelines </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          location.pathname.split("/")[1] === "safety_guidlines"
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/safety_guidlines"
                          className={`text-uppercase ${
                            location.pathname === "/safety_guidlines"
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-lifebuoy font-16 "></i>
                          <span> Safety Guideline </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          location.pathname.split("/")[1] ===
                          "upload_image_guideline"
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/upload_image_guideline"
                          className={`text-uppercase ${
                            location.pathname === "/upload_image_guideline"
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-lifebuoy font-16 "></i>
                          <span> Upload Image Guideline </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          location.pathname.split("/")[1] ===
                          "account_deletation"
                            ? "menuitem-active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/account_deletation"
                          className={`text-uppercase ${
                            location.pathname === "/account_deletation"
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="mdi mdi-lifebuoy font-16 "></i>
                          <span> Account Deletion </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : null}
            </ul>
          </div>

          <div className="clearfix"></div>
        </div>
      </div>
    </>
  );
}
