import React, { useState, useEffect, useCallback } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { editProfile, getDetails, getCountryList } from "../../Api/apiHandler";
import * as Yup from "yup";
import * as Common from "../../utils/Common";
export default function MyProfile() {
  const navigate = useNavigate();
  const [adminDetails, setAdminDetails] = useState(null);
  const admin_id = sessionStorage.getItem("AdminID");
  const [showPassword, setShowPassword] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  console.log("imagePreview: ", imagePreview);
  const [countryList, setCountryList] = useState(null);
  const [selectFile, setSelectFile] = useState(null);
  console.log("selectFile: ", selectFile);
  const fetchCountryList = useCallback(async (request) => {
    try {
      const response = await getCountryList(request);

      // Update state with new data and total count
      if (response.code === 1) {
        console.log("in country list", response.data);
        setCountryList(response.data);
      } else {
        setCountryList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, []);

  const getAdminDetails = useCallback(async () => {
    try {
      let params = {
        admin_id: admin_id,
      };

      const response = await getDetails(params);

      if (response.code === 1) {
        setAdminDetails(response.data);
        setImagePreview(response.data.profile_image);
      } else {
        setAdminDetails(null);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, [admin_id]);

  // Get first time details
  useEffect(() => {
    // For fetch admin Details API
    fetchCountryList({});
    getAdminDetails();
  }, [fetchCountryList, getAdminDetails]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter Name"),
    email: Yup.string().required("Please enter Email"),
    phone: Yup.string().required("Please enter Phone"),
    country_code: Yup.string().required("Please enter country code"),
    // profile_image: Yup.mixed()
    //     .required('Please select profile_image')
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = async (data) => {
    let params = {
      name: data.name,
      admin_id: admin_id,
      email: data.email,
      phone: data.phone,
      password: data.password,
      country_code: data.country_code,
    };

    // image upload in s3
    if (data.profile_image[0] != null) {
      const file = data.profile_image[0];

      // Generate a random file name
      const randomFileName = Common.generateRandomFileName(file.name);
      Common.singleImageUpload(
        randomFileName,
        selectFile,
        "/zaya/admin"
      );
      params.profile_image = randomFileName;
    }

    // For Edit Profile API
    try {
      const response = await editProfile(params);
      if (response.code === 1) {
        Common.SuccessAlert(response.message);
        console.log("response.data: ", response.data);
        Common.loginStoreData(response.data);
        setTimeout(() => {
          navigate("/dashboard");
        }, [1000]);
      } else {
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error while adding data:", error);
    }
  };

  //for image preview

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSelectFile(file);
      const reader = new FileReader();
      reader.onload = function (event) {
        setImagePreview(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  if (adminDetails == null) return <> </>;
  if (countryList === null) return <> </>;

  return (
    <>
      {/* ================================ Start Page Content ================================== */}
      <div className="content-page">
        <div className="content">
          {/* <!-- Start Content--> */}
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Edit Details</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Edit Profile</h4>
                </div>
              </div>
            </div>
            {/* <!-- end page title -->  */}

            <div className="row">
              <div className="col-12">
                <div className="card">
                  {/* Back Button */}
                  <button className="button" onClick={() => navigate(-1)}>
                    <div className="button-box">
                      <span className="button-elem">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 46 40"
                        >
                          <path d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z"></path>
                        </svg>
                      </span>
                      <span className="button-elem">
                        <svg viewBox="0 0 46 40">
                          <path d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z"></path>
                        </svg>
                      </span>
                    </div>
                  </button>

                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        {/* <!-- Image --> */}
                        <div className="col-md-12 mb-3">
                          <div className="d-flex justify-content-center">
                            {/* <!-- Image Preview & Upload --> */}
                            <div className="admin-profile">
                              <label className="-label" htmlFor="file">
                                <i className="fas fa-camera"></i>
                                <span>Change Image</span>
                              </label>
                              <input
                                className="img-input"
                                id="file"
                                {...register("profile_image")}
                                accept="image/*"
                                type="file"
                                onChange={handleImageChange}
                              />
                              <img
                                src={imagePreview}
                                id="output"
                                alt="Profile"
                                width="200"
                                className="img-thumbnail"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            id="name"
                            placeholder="Enter name"
                            defaultValue={adminDetails?.name}
                            {...register("name")}
                          />
                          <span className="validation_error">
                            {errors.name?.message}
                          </span>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Email
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            id="email"
                            placeholder="Enter email"
                            defaultValue={adminDetails?.email}
                            {...register("email")}
                          />
                          <span className="validation_error">
                            {errors.email?.message}
                          </span>
                        </div>

                        <div className="col-md-3 mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Country Code
                          </label>

                          {/* <input type="text" className="form-control" name="country_code" id="country_code" placeholder="Select country code" defaultValue={adminDetails?.country_code} {...register('country_code')} /> */}

                          <select
                            className="form-control"
                            id="country_code"
                            name="country_code"
                            defaultValue={adminDetails?.country_code}
                            {...register("country_code")}
                          >
                            <option value="">Select country code</option>
                            {countryList?.map((item, key) => {
                              return (
                                <option key={key} value={item.phone_code}>
                                  {item.phone_code} ({item.name})
                                </option>
                              );
                            })}
                          </select>

                          <span className="validation_error">
                            {errors.country_code?.message}
                          </span>
                        </div>

                        <div className="col-md-9 mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Phone
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="phone"
                            id="phone"
                            placeholder="Enter phone"
                            defaultValue={adminDetails?.phone}
                            {...register("phone")}
                          />
                          <span className="validation_error">
                            {errors.phone?.message}
                          </span>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Password
                          </label>
                          <div className="input-group input-group-merge">
                            <input
                              type={showPassword ? "text" : "password"}
                              id="password"
                              className="form-control"
                              placeholder="Enter password"
                              {...register("password")}
                            />
                            <div
                              className={`input-group-text ${
                                showPassword ? "show-password" : ""
                              }`}
                              data-password={showPassword}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <span className="password-eye"></span>
                            </div>
                          </div>
                          <span className="validation_error">
                            {errors.password?.message}
                          </span>
                        </div>

                        <div className="col-md-12 mt-3 text-center">
                          <button
                            type="submit"
                            className="btn btn-custom waves-effect waves-light mr-1"
                          >
                            Update
                          </button>

                          <button
                            type="button"
                            className="btn btn-cancel waves-effect waves-light"
                            onClick={() => navigate(-1)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                      {/* <!-- end col --> */}
                    </form>
                    {/* <!-- end form --> */}
                  </div>
                  {/* <!-- end row--> */}
                </div>
                {/* <!-- end card-body --> */}
              </div>
              {/* <!-- end card --> */}
            </div>
            {/* <!-- end col --> */}
          </div>
          {/* <!-- end row --> */}
        </div>
        {/* <!-- container --> */}
      </div>
      {/* <!-- content --> */}

      {/* ================================ End Page content ============================== */}
    </>
  );
}
