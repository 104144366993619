import React, { useState, useEffect } from "react";
import { updateCmsContent, getCmsContentDetails } from "../../Api/apiHandler";
import { Link, useNavigate } from "react-router-dom";
import * as Common from "../../utils/Common";
import "quill/dist/quill.snow.css";
import ReactQuill, { Quill } from "react-quill";
import PropTypes from "prop-types";

import ImageResize from "quill-image-resize-module-react";

Quill.register("modules/imageResize", ImageResize);
export default function CmsPage(props) {
  const navigate = useNavigate();
  const [cmsContent, setCmsContent] = useState(null);

  const fetchCMSContentDetails = async (request) => {
    try {
      const response = await getCmsContentDetails(request);

      if (response.code === 1) {
        setCmsContent(response.data.content);
      } else {
        setCmsContent(null);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setCmsContent(null);
    fetchCMSContentDetails({ page_name: props.page_name });
  }, [props.page_name]);

  const modules = {
    toolbar: [
      [{ font: [] }, { size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      // [{ script: "super" }, { script: "sub" }],
      [{ header: [!1, 1, 2, 3, 4, 5, 6] }, "code-block"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },

        {
          background: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
  };

  const formats = [
    "header",
    "height",
    "bold",
    "italic",
    "font",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "clean",
    "super",
    "sub",
    "list",
    "color",
    "background",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "align",
    "size",
  ];

  const handleProcedureContentChange = (content) => {
    setCmsContent(content);
  };

  const onSubmit = async (data) => {
    let cmsContentData = {
      content: cmsContent,
      page_name: props.page_name,
      title: props.title,
    };

    try {
      const response = await updateCmsContent(cmsContentData);

      if (response.code === 1) {
        Common.SuccessAlert(response.message);
      } else {
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      console.error("In catch :", error);
      Common.ErrorAlert("Oops! Something went wrong");
    }
  };

  if (cmsContent == null) return <> </>;
  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">
                          {process.env.REACT_APP_NAME}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">{props.title}</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Update {props.title}</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  {/* <div className="mb-3">
                                        <button className="btn btn-custom text-capitalize custom-back" onClick={() => navigate(-1)}><i className="fe-arrow-left font-28"></i></button>
                                    </div> */}
                  <div className="card-body">
                    <div className="row mt-2">
                      <section className="card ribbon-box jumbotron bg js-handle-flexible  position-relative">
                        <div className="container card-body ">
                          <div className="row justify-content-center">
                            <div className="col-md col-lg-10 col-xl-8  content-wrapper">
                              <h6
                                className="jumbotron-title display-4 "
                                style={{
                                  textAlign: "center",
                                  fontWeight: "1000",
                                  fontSize: "40px",
                                }}
                              >
                                {props.title}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </section>

                      <form id="update_cms">
                        <div className="row">
                          <div className="col-lg-12 mb-3">
                            <label
                              htmlFor={props.page_name}
                              className="form-label"
                            >
                              {props.title}
                            </label>

                            <ReactQuill
                              theme="snow"
                              modules={modules}
                              formats={formats}
                              defaultValue={cmsContent}
                              placeholder="Please Enter CMS Content..."
                              onChange={handleProcedureContentChange}
                              style={{ height: "220px" }}
                            ></ReactQuill>
                          </div>
                        </div>

                        <div className="row mt-5">
                          <div className="col-lg-6  offset-md-5">
                            <button
                              type="button"
                              onClick={onSubmit}
                              className="btn btn-raised btn-custom  waves-effect me-2"
                            >
                              Update
                            </button>
                            <button
                              type="button"
                              className="btn btn-cancel waves-effect waves-light"
                              onClick={() => navigate(-1)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
//proType For Validation
CmsPage.propTypes = {
  page_name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
