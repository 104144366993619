import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { addFaq } from '../../Api/apiHandler';
import { Link, useNavigate } from 'react-router-dom';
import * as Common from '../../utils/Common';
export default function Add() {
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        question: Yup.string().required("Please enter question"),
        answer: Yup.string().required("Please enter answer"),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = async (data) => {
        let faqData = {
            question: data.question,
            answer: data.answer,
        }

        try {
            const response = await addFaq(faqData);

            if (response.code === 1) {
                Common.SuccessAlert(response.message)
                setTimeout(() => {
                    navigate("/faq")
                }, 1000)
            } else {
                Common.ErrorAlert(response.message);
            }
        } catch (error) {
            Common.ErrorAlert('Oops! Something went wrong');
            console.error('Error while adding data:', error);
        }
    }


    return (
        <>
            <div className="content-page">
                <div className="content">

                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box">
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><Link to='/dashboard'>{process.env.REACT_APP_NAME}</Link></li>
                                            <li className="breadcrumb-item active"><Link to='/user'>FAQ</Link></li>
                                            <li className="breadcrumb-item active">Add FAQ</li>
                                        </ol>
                                    </div>
                                    <h4 className="page-title">Add FAQ</h4>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="mb-3">
                                        <button className="btn btn-custom text-capitalize custom-back" onClick={() => navigate(-1)}><i className="fe-arrow-left font-28"></i></button>
                                    </div>
                                    <div className="card-body">

                                        <div className="row">

                                            <form id='add_user' onSubmit={handleSubmit(onSubmit)}>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3 ">
                                                        <label htmlFor="name" className="form-label">Question</label>
                                                        <input type="text" id="question" className="form-control" placeholder="Question" {...register('question')} />
                                                        <div className="forerror">
                                                            <span className="validation_error text-justify">{errors.question?.message}</span>
                                                        </div>
                                                    </div>


                                                    <div className="col-lg-12 mb-3">
                                                        <label htmlFor="example-textarea" className="form-label">Answer</label>
                                                        <textarea className="form-control" id="example-textarea" placeholder="Answer"  {...register('answer')} rows="5"></textarea>
                                                        <div className="forerror">
                                                            <span className="validation_error text-justify">{errors.answer?.message}</span>
                                                        </div>
                                                    </div>


                                                </div>


                                                <div className="row mt-3">
                                                    <div className="col-lg-6  offset-md-5">
                                                        <button type="submit" className="btn btn-raised btn-custom  waves-effect me-2">
                                                            Submit
                                                        </button>
                                                        <button type="button" className="btn btn-cancel waves-effect waves-light" onClick={() => navigate(-1)}>
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>


                                            </form>



                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </>
    )
}
