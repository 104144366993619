import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  getCountryList,
  getVirtualEventById,
  editVirtualEvents,
} from "../../Api/apiHandler";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Common from "../../utils/Common";
import Select from "react-select";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment-timezone";

function EditVirtual() {
  let { id } = useParams();

  const navigate = useNavigate();

  const [countrylist, setCountryList] = useState([]);
  // console.log("countrylist: ", countrylist);
  const [eventlist, setEventList] = useState(null);
  const [isOpenTimePicker, setIsOpenTimePicker] = useState(false);
  const [minTime, setMinTime] = useState("");

  const [timezone] = useState([
    "Europe/Andorra",
    "Asia/Dubai",
    "Asia/Kabul",
    "Europe/Tirane",
    "Asia/Yerevan",
    "Antarctica/Casey",
    "Antarctica/Davis",
    "Antarctica/DumontDUrville", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    "Antarctica/Mawson",
    "Antarctica/Palmer",
    "Antarctica/Rothera",
    "Antarctica/Syowa",
    "Antarctica/Troll",
    "Antarctica/Vostok",
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Cordoba",
    "America/Argentina/Salta",
    "America/Argentina/Jujuy",
    "America/Argentina/Tucuman",
    "America/Argentina/Catamarca",
    "America/Argentina/La_Rioja",
    "America/Argentina/San_Juan",
    "America/Argentina/Mendoza",
    "America/Argentina/San_Luis",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Ushuaia",
    "Pacific/Pago_Pago",
    "Europe/Vienna",
    "Australia/Lord_Howe",
    "Antarctica/Macquarie",
    "Australia/Hobart",
    "Australia/Currie",
    "Australia/Melbourne",
    "Australia/Sydney",
    "Australia/Broken_Hill",
    "Australia/Brisbane",
    "Australia/Lindeman",
    "Australia/Adelaide",
    "Australia/Darwin",
    "Australia/Perth",
    "Australia/Eucla",
    "Asia/Baku",
    "America/Barbados",
    "Asia/Dhaka",
    "Europe/Brussels",
    "Europe/Sofia",
    "Atlantic/Bermuda",
    "Asia/Brunei",
    "America/La_Paz",
    "America/Noronha",
    "America/Belem",
    "America/Fortaleza",
    "America/Recife",
    "America/Araguaina",
    "America/Maceio",
    "America/Bahia",
    "America/Sao_Paulo",
    "America/Campo_Grande",
    "America/Cuiaba",
    "America/Santarem",
    "America/Porto_Velho",
    "America/Boa_Vista",
    "America/Manaus",
    "America/Eirunepe",
    "America/Rio_Branco",
    "America/Nassau",
    "Asia/Thimphu",
    "Europe/Minsk",
    "America/Belize",
    "America/St_Johns",
    "America/Halifax",
    "America/Glace_Bay",
    "America/Moncton",
    "America/Goose_Bay",
    "America/Blanc-Sablon",
    "America/Toronto",
    "America/Nipigon",
    "America/Thunder_Bay",
    "America/Iqaluit",
    "America/Pangnirtung",
    "America/Atikokan",
    "America/Winnipeg",
    "America/Rainy_River",
    "America/Resolute",
    "America/Rankin_Inlet",
    "America/Regina",
    "America/Swift_Current",
    "America/Edmonton",
    "America/Cambridge_Bay",
    "America/Yellowknife",
    "America/Inuvik",
    "America/Creston",
    "America/Dawson_Creek",
    "America/Fort_Nelson",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Dawson",
    "Indian/Cocos",
    "Europe/Zurich",
    "Africa/Abidjan",
    "Pacific/Rarotonga",
    "America/Santiago",
    "America/Punta_Arenas",
    "Pacific/Easter",
    "Asia/Shanghai",
    "Asia/Urumqi",
    "America/Bogota",
    "America/Costa_Rica",
    "America/Havana",
    "Atlantic/Cape_Verde",
    "America/Curacao",
    "Indian/Christmas",
    "Asia/Nicosia",
    "Asia/Famagusta",
    "Europe/Prague",
    "Europe/Berlin",
    "Europe/Copenhagen",
    "America/Santo_Domingo",
    "Africa/Algiers",
    "America/Guayaquil",
    "Pacific/Galapagos",
    "Europe/Tallinn",
    "Africa/Cairo",
    "Africa/El_Aaiun",
    "Europe/Madrid",
    "Africa/Ceuta",
    "Atlantic/Canary",
    "Europe/Helsinki",
    "Pacific/Fiji",
    "Atlantic/Stanley",
    "Pacific/Chuuk",
    "Pacific/Pohnpei",
    "Pacific/Kosrae",
    "Atlantic/Faroe",
    "Europe/Paris",
    "Europe/London",
    "Asia/Tbilisi",
    "America/Cayenne",
    "Africa/Accra",
    "Europe/Gibraltar",
    "America/Godthab",
    "America/Danmarkshavn",
    "America/Scoresbysund",
    "America/Thule",
    "Europe/Athens",
    "Atlantic/South_Georgia",
    "America/Guatemala",
    "Pacific/Guam",
    "Africa/Bissau",
    "America/Guyana",
    "Asia/Hong_Kong",
    "America/Tegucigalpa",
    "America/Port-au-Prince",
    "Europe/Budapest",
    "Asia/Jakarta",
    "Asia/Pontianak",
    "Asia/Makassar",
    "Asia/Jayapura",
    "Europe/Dublin",
    "Asia/Jerusalem",
    "Asia/Kolkata",
    "Indian/Chagos",
    "Asia/Baghdad",
    "Asia/Tehran",
    "Atlantic/Reykjavik",
    "Europe/Rome",
    "America/Jamaica",
    "Asia/Amman",
    "Asia/Tokyo",
    "Africa/Nairobi",
    "Asia/Bishkek",
    "Pacific/Tarawa",
    "Pacific/Enderbury",
    "Pacific/Kiritimati",
    "Asia/Pyongyang",
    "Asia/Seoul",
    "Asia/Almaty",
    "Asia/Qyzylorda",
    "Asia/Qostanay", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    "Asia/Aqtobe",
    "Asia/Aqtau",
    "Asia/Atyrau",
    "Asia/Oral",
    "Asia/Beirut",
    "Asia/Colombo",
    "Africa/Monrovia",
    "Europe/Vilnius",
    "Europe/Luxembourg",
    "Europe/Riga",
    "Africa/Tripoli",
    "Africa/Casablanca",
    "Europe/Monaco",
    "Europe/Chisinau",
    "Pacific/Majuro",
    "Pacific/Kwajalein",
    "Asia/Yangon",
    "Asia/Ulaanbaatar",
    "Asia/Hovd",
    "Asia/Choibalsan",
    "Asia/Macau",
    "America/Martinique",
    "Europe/Malta",
    "Indian/Mauritius",
    "Indian/Maldives",
    "America/Mexico_City",
    "America/Cancun",
    "America/Merida",
    "America/Monterrey",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Chihuahua",
    "America/Ojinaga",
    "America/Hermosillo",
    "America/Tijuana",
    "America/Bahia_Banderas",
    "Asia/Kuala_Lumpur",
    "Asia/Kuching",
    "Africa/Maputo",
    "Africa/Windhoek",
    "Pacific/Noumea",
    "Pacific/Norfolk",
    "Africa/Lagos",
    "America/Managua",
    "Europe/Amsterdam",
    "Europe/Oslo",
    "Asia/Kathmandu",
    "Pacific/Nauru",
    "Pacific/Niue",
    "Pacific/Auckland",
    "Pacific/Chatham",
    "America/Panama",
    "America/Lima",
    "Pacific/Tahiti",
    "Pacific/Marquesas",
    "Pacific/Gambier",
    "Pacific/Port_Moresby",
    "Pacific/Bougainville",
    "Asia/Manila",
    "Asia/Karachi",
    "Europe/Warsaw",
    "America/Miquelon",
    "Pacific/Pitcairn",
    "America/Puerto_Rico",
    "Asia/Gaza",
    "Asia/Hebron",
    "Europe/Lisbon",
    "Atlantic/Madeira",
    "Atlantic/Azores",
    "Pacific/Palau",
    "America/Asuncion",
    "Asia/Qatar",
    "Indian/Reunion",
    "Europe/Bucharest",
    "Europe/Belgrade",
    "Europe/Kaliningrad",
    "Europe/Moscow",
    "Europe/Simferopol",
    "Europe/Kirov",
    "Europe/Astrakhan",
    "Europe/Volgograd",
    "Europe/Saratov",
    "Europe/Ulyanovsk",
    "Europe/Samara",
    "Asia/Yekaterinburg",
    "Asia/Omsk",
    "Asia/Novosibirsk",
    "Asia/Barnaul",
    "Asia/Tomsk",
    "Asia/Novokuznetsk",
    "Asia/Krasnoyarsk",
    "Asia/Irkutsk",
    "Asia/Chita",
    "Asia/Yakutsk",
    "Asia/Khandyga",
    "Asia/Vladivostok",
    "Asia/Ust-Nera",
    "Asia/Magadan",
    "Asia/Sakhalin",
    "Asia/Srednekolymsk",
    "Asia/Kamchatka",
    "Asia/Anadyr",
    "Asia/Riyadh",
    "Pacific/Guadalcanal",
    "Indian/Mahe",
    "Africa/Khartoum",
    "Europe/Stockholm",
    "Asia/Singapore",
    "America/Paramaribo",
    "Africa/Juba",
    "Africa/Sao_Tome",
    "America/El_Salvador",
    "Asia/Damascus",
    "America/Grand_Turk",
    "Africa/Ndjamena",
    "Indian/Kerguelen",
    "Asia/Bangkok",
    "Asia/Dushanbe",
    "Pacific/Fakaofo",
    "Asia/Dili",
    "Asia/Ashgabat",
    "Africa/Tunis",
    "Pacific/Tongatapu",
    "Europe/Istanbul",
    "America/Port_of_Spain",
    "Pacific/Funafuti",
    "Asia/Taipei",
    "Europe/Kiev",
    "Europe/Uzhgorod",
    "Europe/Zaporozhye",
    "Pacific/Wake",
    "America/New_York",
    "America/Detroit",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Indiana/Indianapolis",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Vevay",
    "America/Chicago",
    "America/Indiana/Tell_City",
    "America/Indiana/Knox",
    "America/Menominee",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/North_Dakota/Beulah",
    "America/Denver",
    "America/Boise",
    "America/Phoenix",
    "America/Los_Angeles",
    "America/Anchorage",
    "America/Juneau",
    "America/Sitka",
    "America/Metlakatla",
    "America/Yakutat",
    "America/Nome",
    "America/Adak",
    "Pacific/Honolulu",
    "America/Montevideo",
    "Asia/Samarkand",
    "Asia/Tashkent",
    "America/Caracas",
    "Asia/Ho_Chi_Minh",
    "Pacific/Efate",
    "Pacific/Wallis",
    "Pacific/Apia",
    "Africa/Johannesburg",
  ]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter Event Name"),
    event_start_time: Yup.string()
      .required("Please enter Event Start Time")
      .test(
        "is-greater-than-current-time",
        "Start time must be at least a hours from now",
        function (value) {
          const minimumStartTime = moment().clone().add(1, "hours");
          // Check if the entered start time is greater than the minimum allowed time
          console.log('minTime == "": ',minTime ,minTime === "");
          return minTime !== ""
            ? moment(value).isAfter(minimumStartTime)
            : true;
        }
      ),
    country_code: Yup.string().required("Please select Country"),
    event_timezone: Yup.string().required("Please select Event TimeZone"),
  });

  const { handleSubmit, control, register, formState, getValues, setValue } =
    useForm({
      resolver: yupResolver(validationSchema),
      values: {
        event_timezone: eventlist?.event_timezone,
        country_code: eventlist?.country_id || "",
      },
    });

  const { errors } = formState;
  console.log("errors: ", errors);

  useEffect(() => {
    fetchCountryList();
    fetchVirtualEvenList(id);
  }, [id]);

  const onSubmit = async (data) => {
    console.log("data: ", data);
    let cretevnt = {
      id: atob(id),
      name: data.name,
      event_start_time: moment(data.event_start_time).format("HH:mm"),
      country_id: data.country_code,
      event_timezone: data.event_timezone,
    };

    try {
      const response = await editVirtualEvents(cretevnt);
      if (response.code === 1) {
        // console.log("response :", response);
        Common.SuccessAlert(response.message);
        setTimeout(() => {
          navigate("/event/virtual");
        }, 1000);
      } else {
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error while adding data:", error);
    }
  };

  const fetchCountryList = async (request) => {
    try {
      const response = await getCountryList(request);

      if (response.code === 1) {
        setCountryList(response.data);
      } else {
        setCountryList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
    }
  };

  const fetchVirtualEvenList = async (id) => {
    try {
      const response = await getVirtualEventById({ id: atob(id) });

      if (response.code === 1) {
        setEventList(response.data);
      } else {
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  };

  const handleCountryChange = (selectedOptions) => {
    setValue("country_code", selectedOptions?._id);
  };

  const currentTime = moment().tz(eventlist?.event_timezone);
  const eventTime = moment(eventlist?.event_start_time, "HH:mm");

  const isDisable =
    currentTime?.isAfter(eventTime?.clone().subtract(1, "hours")) &&
    currentTime?.isBefore(eventTime?.clone().add(1, "hours"));

  useEffect(() => {
    if (currentTime?.isBefore(eventTime?.clone().subtract(1, "hours"))) {
      setMinTime(moment().tz(eventlist?.event_timezone).add(1, "h"));
    }
  }, [currentTime, eventTime, eventlist?.event_timezone]);

  if (countrylist === null) return <> </>;
  if (eventlist === null) return <> </>;
  if (timezone === null) return <> </>;

  const shouldDisableTime = (time, view) => {
    const selectedTime = moment(time);
    if (minTime !== "") return false;
    // Disable times within the next 60 minutes
    if (
      view === "minutes" &&
      selectedTime.isAfter(currentTime) &&
      selectedTime.isBefore(currentTime.clone().add(60, "minutes"))
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">
                          {process.env.REACT_APP_NAME}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/cafe">Virtual Event</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Edit Virtual Event
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">Edit Virtual Event</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="card">
                  <div className="mb-3">
                    <button
                      className="btn btn-custom text-capitalize custom-back"
                      onClick={() => navigate(-1)}
                    >
                      <i className="fe-arrow-left font-28"></i>
                    </button>
                  </div>
                  <div className="card-body mt-2">
                    <div className="row">
                      <form id="add_cafe" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                          <div className="col-lg-12 mb-12">
                            <label htmlFor="name" className="form-label">
                              Event Name
                            </label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              className="form-control"
                              readOnly={isDisable}
                              placeholder="Type Event Name"
                              defaultValue={eventlist?.name}
                              {...register("name")}
                            />
                            <div className="forerror">
                              <span className="validation_error text-justify">
                                {errors.name?.message}
                              </span>
                            </div>
                            <br />
                          </div>

                          <div className="col-lg-12 mb-12">
                            <label
                              htmlFor="event_start_time"
                              className="form-label"
                            >
                              Event Start Time
                            </label>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <Controller
                                name="event_start_time"
                                control={control}
                                defaultValue={
                                  eventlist?.event_start_time
                                    ? moment(
                                        eventlist?.event_start_time,
                                        "HH:mm"
                                      )
                                    : null
                                }
                                render={({ field }) => (
                                  <div className="w-100 input-group">
                                    <TimePicker
                                      {...field}
                                      sx={{ width: "100%" }}
                                      autoFocus
                                      open={isOpenTimePicker}
                                      onClose={() => setIsOpenTimePicker(false)}
                                      disabled={isDisable}
                                      slotProps={{
                                        textField: {
                                          onClick: () =>
                                            setIsOpenTimePicker(true),
                                        },
                                      }}
                                      shouldDisableTime={shouldDisableTime}
                                      minTime={minTime}
                                      ampm={false}
                                      className="custom-time-picker "
                                    />
                                  </div>
                                )}
                              />
                            </LocalizationProvider>
                            <div className="forerror">
                              <span className="validation_error text-justify">
                                {errors.event_start_time?.message}
                              </span>
                            </div>
                            <br />
                          </div>

                          <div className="col-lg-12 mb-12">
                            <label
                              htmlFor="country_code"
                              className="form-label"
                            >
                              Country
                            </label>
                            <Select
                              defaultValue={countrylist?.find(
                                (item) => item?._id === eventlist?.country_id
                              )}
                              isDisabled
                              name="country_code"
                              className="Disabled select"
                              options={countrylist}
                              getOptionLabel={(option) =>
                                option.phone_code + " " + option.name
                              } // Specify the property for displaying the label
                              placeholder="Select Country"
                              getOptionValue={(option) => option.phone_code}
                              {...register("country_code", {
                                value:
                                  countrylist?.find(
                                    (item) => item?._id === eventlist?.country_id
                                  )?._id || "",
                              })}
                              value={countrylist?.find(
                                (item) => item._id === getValues("country_code")
                              )}
                              onChange={(e) => {
                                handleCountryChange(e);
                              }}
                            />

                            <div className="forerror">
                              <span className="validation_error text-justify">
                                {errors.country_code?.message}
                              </span>
                            </div>
                            <br />
                          </div>

                          <div className="col-lg-12 mb-12">
                            <label
                              htmlFor="event_timezone"
                              className="form-label"
                            >
                              Select TimeZone
                            </label>
                            <select
                              disabled
                              className="form-control Disabled select"
                              id="event_timezone"
                              name="event_timezone"
                              value={eventlist?.event_timezone}
                              {...register("event_timezone")}
                            >
                              <option value="">Select TimeZone </option>
                              {timezone?.map((item, key) => {
                                return (
                                  <option
                                    key={key}
                                    value={item}
                                    selected={
                                      item === eventlist?.event_timezone
                                        ? true
                                        : false
                                    }
                                  >
                                    {" "}
                                    {item}{" "}
                                  </option>
                                );
                              })}
                            </select>

                            <div className="forerror">
                              <span className="validation_error text-justify">
                                {errors.event_timezone?.message}
                              </span>
                            </div>
                            <br />
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-lg-6  offset-md-5">
                            <button
                              type="submit"
                              disabled={isDisable}
                              className="btn btn-raised btn-custom  waves-effect me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              className="btn btn-cancel waves-effect waves-light"
                              onClick={() => navigate(-1)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditVirtual;
