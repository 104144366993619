import Swal from "sweetalert2";
import ExcelJS from "exceljs";
import AWS from "aws-sdk";
import GLOBALS from "../utils/Constant";
import moment from "moment";

const s3 = new AWS.S3({
  accessKeyId: GLOBALS.S3_ACCESS_KEY,
  secretAccessKey: GLOBALS.S3_SECRET_ACCESS_KEY,
});

export function exportExcelFile(column, Data, fileName) {
  const workbook = new ExcelJS.Workbook();
  const workSheet = workbook.addWorksheet("Report-Data");
  workSheet.columns = column;

  const headerRow = workSheet.getRow(1);

  //For header
  headerRow.eachCell((cell) => {
    cell.font = { bold: true };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFC0C0C0" }, // Light gray color for the header background
    };
    cell.alignment = { horizontal: "center" };
  });

  //adding the data ro sheet
  Data.forEach((element) => {
    workSheet.addRow(element);
  });
  
  // Center align the cells
  workSheet.eachRow({ includeEmpty: true, }, function (row, rowNumber) {
    row.eachCell({ includeEmpty: true }, function (cell) {
      cell.alignment = { horizontal: "center"};
      cell.style={height:"auto"}
    });
  });

  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = fileName;
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
}

export function formatDateToTimezone(date, dateformat) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (date === "0000-00-00 00:00:00") {
    return date;
  }

  const utcDate = new Date(date);
  const options = {
    timeZone: timezone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false, // Use 24-hour format
  };

  const localDate = new Date(utcDate.toLocaleString("en-US", options));

  localDate.toLocaleString("en-US", options);

  return moment(localDate).format(dateformat);
}

export function ErrorAlert(data, msg) {
  Swal.fire({
    position: "top-end",
    icon: "error",
    title: data || msg,
    toast: true,
    showConfirmButton: false,
    timer: 1500,
  });
}

export async function singleImageUpload(randomFileName, file, folder) {
  console.log("file in s3 upload", file);

  const params = {
    Key: randomFileName,
    ACL: "private",
    Body: file,
    Bucket: GLOBALS.S3_BUCKET_NAME + folder,
    ContentType: file.type,
  };

  try {
    // Upload image to S3
    let image = s3.upload(params).promise();

    image
      .then((result) => {
        console.log("Image uploaded successfully:", result.Location);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  } catch (error) {
    console.error("Error uploading image:", error);
  }
}

export const uploadBase64Image = async (base64Data, folder, filePath) => {
  // const base64Image = base64Data
  // console.log('base64Image: ', base64Image);

  // const binaryData = new Uint8Array(atob(base64Image).split('').map(char => char.charCodeAt(0)));
  const base64WithoutPrefix = base64Data.split(",")[1];
  console.log("base64WithoutPrefix: ", base64WithoutPrefix);
  const byteCharacters = atob(base64WithoutPrefix);
  console.log("byteCharacters: ", byteCharacters);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  console.log("byteArrays: ", byteArrays);
  const params = {
    ACL: "public-read",
    Body: byteArrays,
    Bucket: GLOBALS.S3_BUCKET_NAME + folder,
    Key: filePath,
    // ContentEncoding: ContentType: 'image/jpeg',,
    ContentType: "image/jpeg",
  };

  try {
    const result = await s3.upload(params).promise();
    console.log(
      "File uploaded successfully:==============>New",
      result.Location
    );
  } catch (error) {
    // console.error('Error uploading file:', error);
  }
};

export function generateRandomFileName(originalFileName) {
  console.log(originalFileName, "originalFileName final123");
  const extension = originalFileName.split(".").pop();
  const randomString = Math.random().toString(36).substring(2, 8);
  return `${randomString}.${extension}`;
}

export function SuccessAlert(data, msg) {
  Swal.fire({
    position: "top-end",
    icon: "success",
    title: data || msg,
    toast: true,
    showConfirmButton: false,
    timer: 1500,
  });
}

export function SimpleAlert(data, msg) {
  Swal.fire({
    position: "top-end",
    icon: "alert",
    title: data || msg,
    toast: true,
    showConfirmButton: false,
    timer: 1500,
  });
}

export function loginRedirectCall() {
  console.log("in loginRedirectCall");
  let path = window.location.protocol + "//" + window.location.host;
  //    window.open(path,'mywindow').focus()
  window.location.href = path;
}

export function logOutRedirectCall() {
  console.log("in loginRedirectCall logOutRedirectCall h");

  sessionStorage.removeItem("AdminIsLogin");
  sessionStorage.removeItem("AdminToken");
  sessionStorage.removeItem("AdminName");
  sessionStorage.removeItem("AdminID");
  sessionStorage.removeItem("AdminEmail");
  sessionStorage.removeItem("AdminImage");
  sessionStorage.removeItem("AdminRole");
  sessionStorage.removeItem("AdminData");

  // window.location.reload()
}

export function loginStoreData(data) {
  sessionStorage.setItem("AdminIsLogin", true);
  sessionStorage.setItem("AdminToken", data.token);
  sessionStorage.setItem("AdminName", data.name);
  sessionStorage.setItem("AdminID", data._id);
  sessionStorage.setItem("AdminEmail", data.email);
  sessionStorage.setItem("AdminRole", data.role);
  sessionStorage.setItem("AdminImage", data.profile_image);
  // sessionStorage.setItem("AdminData", data)
  sessionStorage.setItem("AdminData", JSON.stringify(data));
}

export function processing() {
  Swal.fire({
    title: "Please wait...",
    didOpen: () => {
      Swal.showLoading();
    },
  });
}
