import React, { useEffect,useState } from 'react'
import { Link } from 'react-router-dom'
// import { logout } from '../../Api/apiHandler';
import GLOBALS from '../../utils/Constant'
// import { logOutRedirectCall } from '../../utils/Common';
// import * as Alert from '../../utils/Common';

export default function Topbar() {

  const [image, setImage] = useState(null);

  useEffect(() => {
    // Retrieve data from sessionStorage
    const storedData = sessionStorage.getItem('AdminImage');

    // console.log(storedData,'storedData');
    if (storedData) {
      // Convert the stored data to a JavaScript object
      // const parsedData = JSON.parse(storedData);

      // Update the state with the admin data
      setImage(storedData);
    }
    // eslint-disable-next-line
  }, [sessionStorage.getItem('AdminImage')]);

// console.log('in topbar===========================================');
  return (
    <>
      <div className="navbar-custom">
        <div className="container-fluid">
          <ul className="list-unstyled topnav-menu float-end mb-0">

            <li className="dropdown notification-list topbar-dropdown">
              <Link className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light"
                data-bs-toggle="dropdown" to="#" role="button" aria-haspopup="false"
                aria-expanded="false">
                <img src={image} alt="AdminImage" className="rounded-circle" />
                {/* <img src={sessionStorage.getItem("AdminImage")} alt="AdminImage" className="rounded-circle" /> */}
                <span className="pro-user-name ms-1" style={{ color: 'white' }}>
                  {sessionStorage.getItem("AdminName")} <i className="mdi mdi-chevron-down"></i>
                </span>
              </Link>
              <div className="dropdown-menu dropdown-menu-end profile-dropdown ">
                <div className="dropdown-header noti-title">
                  <h6 className="text-overflow m-0">Welcome !</h6>
                </div>

                <Link to='/update-admin' className="dropdown-item notify-item">
                  <i className="fe-user"></i>
                  <span>My Account</span>
                </Link>

                {/* <Link to='/setting' className="dropdown-item notify-item">
                  <i className="fe-settings"></i>
                  <span>Settings</span>
                </Link> */}

                {/* <a href="#" className="dropdown-item notify-item">
                  <i className="fe-lock"></i>
                  <span>Lock Screen</span>
                </a> */}

                <div className="dropdown-divider"></div>


                <Link to='/logout' className="dropdown-item notify-item">
                  <i className="fe-log-out"></i>
                  <span>Logout</span>
                </Link>

                {/* <Link className="dropdown-item" onClick={adminLogout}><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</Link> */}
              </div>
            </li>

            {/* <li className="dropdown notification-list">
              <a href="#" className="nav-link right-bar-toggle waves-effect waves-light">
                <i className="fe-settings noti-icon"></i>
              </a>
            </li> */}

          </ul>

          <div className="logo-box">
            <Link to='/dashboard' className="logo logo-light text-center">

              <span className="logo-sm">
                <img src={process.env.PUBLIC_URL+GLOBALS.LogoURL} alt="" height="22" />
              </span>
              <span className="logo-lg" style={{ marginRight: '' }}>
                <img src={process.env.PUBLIC_URL+GLOBALS.LogoURL} alt="" height="40" />
                {/* <span className="header-logo"> {process.env.REACT_APP_NAME} </span> */}
              </span>
            </Link>
          </div>

          <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
            <li>
              <button className="button-menu-mobile waves-effect waves-light">
                <i className="fe-menu"></i>
              </button>
            </li>
          </ul>
          <div className="clearfix"></div>
        </div>
      </div>
    </>
  )
}
