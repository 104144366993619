// import axios from "axios";
import { axiosClient } from "./apiClient";

export function login(data) {
  return axiosClient.post("admin_auth/login", data);
}

export function profile(data) {
  return axiosClient.get("admin_auth/admin_details", data);
}

export function logout(data) {
  return axiosClient.post("admin_auth/logout", data);
}

export function getDashboardCount(data) {
  return axiosClient.post("admin_auth/dashboard_count", data);
}

export function editProfile(data) {
  return axiosClient.post("admin_auth/edit_admin", data);
}

export function getDetails(data) {
  return axiosClient.post("admin_auth/admin_details", data);
}

export function getCountryList(data) {
  return axiosClient.post("user_module/country_list", data);
}

export function getCityList(data) {
  return axiosClient.post("user_module/city_list", data);
}

export function getNationalityList(data) {
  return axiosClient.post("user_module/nationality_list", data);
}

export function getLanguageList(data) {
  return axiosClient.post("user_module/language_list", data);
}

export function getHobbiesList(data) {
  return axiosClient.post("user_module/hobby_list", data);
}

export function getReligionList(data) {
  return axiosClient.post("user_module/religion_list", data);
}

export function getMbtiList(data) {
  return axiosClient.post("user_module/mbti_type_list", data);
}

export function getEmpList(data) {
  return axiosClient.post("user_module/emp_status_list", data);
}

export function getParenthoodList(data) {
  return axiosClient.post("user_module/parenthood_status_list", data);
}

export function getEducationList(data) {
  return axiosClient.post("user_module/education_level_list", data);
}

// ------------------- Manage customer ------------------

export function getUserList(data) {
  return axiosClient.post("user_module/user_list", data);
}

export function changeUserStatus(data) {
  return axiosClient.post("user_module/change_status", data);
}

export function blockUnblockUser(data) {
  return axiosClient.post("user_module/block_unblock", data);
}

export function removeUser(data) {
  return axiosClient.post("user_module/delete_user", data);
}

export function getUserDetails(data) {
  return axiosClient.post("user_module/user_details", data);
}

export function exportUserData(data) {
  return axiosClient.post("user_module/export_user", data);
}

// ------------------- Manage cafe ------------------

export function getCafeList(data) {
  return axiosClient.post("cafe_module/cafe_list", data);
}

export function changeCafeStatus(data) {
  return axiosClient.post("cafe_module/change_status", data);
}

export function removeCafe(data) {
  return axiosClient.post("cafe_module/delete_cafe", data);
}

export function addCafe(data) {
  return axiosClient.post("cafe_module/add_cafe", data);
}

export function editCafe(data) {
  return axiosClient.post("cafe_module/edit_cafe", data);
}

export function getCafeDetails(data) {
  return axiosClient.post("cafe_module/cafe_details", data);
}

export function exportCafeData(data) {
  return axiosClient.post("cafe_module/export_cafe_details", data);
}

// ------------------- CMS ------------------
export function updateCmsContent(data) {
  return axiosClient.post("cms/update_cms", data);
}

export function getCmsContentDetails(data) {
  return axiosClient.post("cms/cms_details", data);
}

export function addContactUS(data) {
  return axiosClient.post("cms/add_contactus", data);
}

export function getContactUsList(data) {
  return axiosClient.post("cms/contactus_list", data);
}

export function getContactUsDetails(data) {
  return axiosClient.post("cms/contactus_details", data);
}

export function contactUsSendReply(data) {
  return axiosClient.post("cms/send_reply", data);
}

export function removeContactUs(data) {
  return axiosClient.post("cms/delete_contactus", data);
}

export function addFaq(data) {
  return axiosClient.post("faq/add", data);
}

export function getFaqList(data) {
  return axiosClient.post("faq/list", data);
}

export function changeFaqStatus(data) {
  return axiosClient.post("faq/change_status", data);
}

export function removeFaq(data) {
  return axiosClient.post("faq/delete", data);
}

export function editFaq(data) {
  return axiosClient.post("faq/edit", data);
}

export function getFaqDetails(data) {
  return axiosClient.post("faq/details", data);
}

export function getAllNotifiction(data) {
  return axiosClient.post("cms/getallnotification", data);
}

export function sendPushNotification(data) {
  return axiosClient.post("cms/sendnotification", data);
}

// ------------------- event ------------------

export function getVirtualEventList(data) {
  return axiosClient.post("event/getvirtualeventlist", data);
}

export function createEventVirtual(data) {
  return axiosClient.post("event/createevent", data);
}

export function virtualEventDelete(data) {
  return axiosClient.post("event/virtualeventdelete", data);
}

export function statusVirtualEvent(data) {
  return axiosClient.post("event/virtualeventactive", data);
}

export function getVirtualEventById(data) {
  return axiosClient.post("event/getvirtualeventid", data);
}

export function editVirtualEvents(data) {
  return axiosClient.post("event/editvirtualevent", data);
}

export function getOfflineUserMatch(data) {
  return axiosClient.post("event/getofflinematch", data);
}

export function getCountryCafeList(data) {
  return axiosClient.post("event/getcountrywisecafelist", data);
}

export function AssignCafeoffMatch(data) {
  return axiosClient.post("event/assigncafetoffmatch", data);
}

// --------------------Earning------------------------

export function getVirtualEventEarningList(data) {
  return axiosClient.post("earning/getvirtualeventearninglist", data);
}

export function getOfflineMatchingEarningList(data) {
  return axiosClient.post("earning/getofflinematchearninglist", data);
}
export function getRefundEarningList(data) {
  return axiosClient.post("earning/getEarningrefundlist", data);
}
export function getRegisteredUserList(data) {
  return axiosClient.post("earning/getRegisteredUserList", data);
}

// ------------------- sub admin ------------------

export function getAdminList(data) {
  return axiosClient.post("sub_admin/list_subAdmin", data);
}

export function changeAdminStatus(data) {
  return axiosClient.post("sub_admin/change_status", data);
}

export function removeSubAdmin(data) {
  return axiosClient.post("sub_admin/delete_subAdmin", data);
}

export function addSubAdmin(data) {
  return axiosClient.post("sub_admin/add_subAdmin", data);
}

export function editSubAdmin(data) {
  return axiosClient.post("sub_admin/edit_subAdmin", data);
}

export function detailsSubAdmin(data) {
  return axiosClient.post("sub_admin/details", data);
}

export function exportAdminData(data) {
  return axiosClient.post("sub_admin/export_admin_details", data);
}

export function vitualMeetupReport(data) {
  return axiosClient.post("report/vitual_meetup_report", data);
}
export function realMeetupReport(data) {
  return axiosClient.post("report/real_meetup_report", data);
}
export function exportVitualMeetupReport(data) {
  return axiosClient.post("report/export_vitual_meetup_report", data);
}
export function exportRealMeetupReport(data) {
  return axiosClient.post("report/export_real_meetup_report", data);
}
