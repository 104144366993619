import React, { useState, useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  getOfflineUserMatch,
  getCountryCafeList,
  AssignCafeoffMatch,
} from "../../Api/apiHandler";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Common from "../../utils/Common";
import GLOBALS from "../../utils/Constant";
import moment from "moment";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
function AssignCafe() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [userone, setUserone] = useState(null);
  const [usertwo, setUsertwo] = useState(null);
  const [cafelist, setCafeList] = useState([]);
  const [timeLimit, setTimeLimit] = useState({});
  const [open, setOpen] = useState(false);
  const [matchData, setMatchData] = useState({});
  console.log("matchData: ", matchData);
  const [isOpenTimePicker, setIsOpenTimePicker] = useState(false);

  const getvirtualeventlist = useCallback(
    async (request) => {
      try {
        const response = await getOfflineUserMatch({ id: atob(id) });

        if (response.code === 1) {
          console.log("response :", response.data);
          setUserone(response.data[0]?.user1[0]);
          setUsertwo(response.data[0]?.user2[0]);
          setMatchData(response.data[0]);
          getCafeCountryList({
            country_id: response.data[0]?.user2[0].country_id,
          });
        } else {
          Common.ErrorAlert(response.message);
        }
      } catch (error) {
        Common.ErrorAlert("Oops! Something went wrong");
        console.error("Error fetching data:", error);
      }
    },
    [id]
  );

  const getCafeCountryList = async (request) => {
    try {
      const response = await getCountryCafeList({ is_active: 1, ...request });

      if (response.code === 1) {
        console.log("get cafe list by user country :", response.data);
        setCafeList(response.data);
      } else {
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  };

  const validationSchema = Yup.object().shape({
    cafe: Yup.string().required("Please Select Cafe"),
    date: Yup.date().required("Please Select Date"),
    time: Yup.string().required("Please Select Time"),
  });
  const { control, handleSubmit, register, formState, getValues } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { errors } = formState;

  function formatBirthDate(birthDate) {
    if (!birthDate) return ""; // Handle cases where birthDate is undefined or null

    const dateObject = new Date(birthDate);
    const formattedDate = dateObject.toLocaleDateString("en-US"); // Adjust the locale as needed

    return formattedDate;
  }

  const onSubmit = async (data) => {
    let cretevnt = {
      id: atob(id),
      cafe_id: data.cafe,
      schedule_time: moment(
        data.time,
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
      ).valueOf(),
      schedule_date: moment(data.date).format("YYYY-MM-DD"),
    };
    console.log("cretevnt :", cretevnt);
    try {
      const response = await AssignCafeoffMatch(cretevnt);
      if (response.code === 1) {
        Common.SuccessAlert(response.message);
        setTimeout(() => {
          navigate("/event/realtimematch");
        }, 1000);
      } else {
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
    }
  };
  const handleScheduleDate = (event) => {
    const cafe = getValues("cafe");
    const timeSlot = cafelist
      .find((item) => item._id === cafe)
      ?.time?.find((val) => val.day === event.format("dddd"));
    if (
      event.isSame(moment(), "days") &&
      moment(timeSlot.open_time, "HH:mm").isBefore(moment().add("2", "hours"))
    ) {
      timeSlot.open_time = moment().add("2", "hours").format("HH:mm");
    }
    setTimeLimit(timeSlot);
  };

  const handleCafeChange = (e) => {
    const cafe = e?.target?.value;
    const timeSlot = cafelist
      .find((item) => item._id === cafe)
      ?.time?.find((val) => val.day === moment().format("dddd"));
    // console.log("timeSlot: ", timeSlot);
    setTimeLimit(timeSlot);
  };

  const shouldDisableDate = (day) => {
    // Format the day in the same way as disabledDays
    const dayName = moment(day).format("dddd");
    const cafe = getValues("cafe");
    const timeSlot = cafelist
      ?.find((item) => item._id === cafe)
      ?.time?.filter((item) => item.open_time === "")
      ?.map((item) => item.day);
    // Check if the formatted day is in the array of disabled days
    return timeSlot.includes(dayName);
  };

  useEffect(() => {
    getvirtualeventlist();
  }, [getvirtualeventlist]);

  console.log(
    "moment.utc(matchData.schedule_time)",
    moment(matchData.schedule_time, "ms").format("dd-mm-yyyy HH:mm")
  );

  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">
                          {process.env.REACT_APP_NAME}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/cafe">Real Time Match</Link>
                      </li>
                      <li className="breadcrumb-item active">Assign Cafe</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Assign Cafe</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="mb-3">
                    <button
                      className="btn btn-custom text-capitalize custom-back"
                      onClick={() => navigate(-1)}
                    >
                      <i className="fe-arrow-left font-28"></i>
                    </button>
                  </div>
                  <div className="card-body mt-2">
                    <div className="row gx-4">
                      <div className="col-12 col-lg-6 d-flex">
                        <div className="flex-grow-1">
                          <div
                            className="d-flex align-items-center"
                            onClick={() =>
                              navigate(`/user/view/${btoa(userone?._id)}`)
                            }
                          >
                            <img
                              src={userone?.profile_image}
                              alt={userone?.profile_image}
                              className="rounded-circle avatar-xxl img-thumbnail"
                            />
                            <div className="ms-3">
                              <p className="mb-2 font-15">
                                <strong>Full Name :</strong>{" "}
                                <span className="ms-2">
                                  {userone?.first_name} {userone?.last_name}
                                </span>
                              </p>

                              <p className="mb-2 font-15">
                                <strong>Mobile :</strong>
                                <span className="ms-2">
                                  {userone?.country_code}{" "}
                                  {userone?.mobile_number}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="text-start mt-2 row">
                            <div className="col-12 col-md-6">
                              <p className="mb-2 font-15">
                                <strong>Birth Date & Time:</strong>
                                <span className="ms-2">
                                  {formatBirthDate(userone?.birth_date)}{" "}
                                  {userone?.birth_time}
                                </span>
                              </p>

                              <p className="mb-2 font-15">
                                <strong>Country :</strong>{" "}
                                <span className="ms-2">{userone?.country}</span>
                              </p>

                              <p className="mb-2 font-15">
                                <strong>Nationality :</strong>{" "}
                                <span className="ms-2">
                                  {userone?.nationality}
                                </span>
                              </p>

                              <p className="mb-2 font-15">
                                <strong>Zodiac Sign :</strong>{" "}
                                <span className="ms-2">
                                  {userone?.zodiac_sign}
                                </span>
                              </p>

                              <p className="mb-2 font-15">
                                <strong>Employment Status :</strong>{" "}
                                <span className="ms-2">
                                  {userone?.employment_status}
                                </span>
                              </p>

                              <p className="mb-2 font-15">
                                <strong>Parenthood Status :</strong>{" "}
                                <span className="ms-2">
                                  {userone?.parenthood_status}
                                </span>
                              </p>

                              <p className="mb-2 font-15">
                                <strong>Height :</strong>{" "}
                                <span className="ms-2">
                                  {userone?.height} {userone?.height_unit}
                                </span>
                              </p>

                              <p className="mb-2 font-15">
                                <strong>Status :-</strong>
                                <span className="ms-2">
                                  {userone != null ? (
                                    userone?.is_active === 1 ? (
                                      <span className="p-1 badge square-pill bg-success">
                                        {" "}
                                        Active
                                      </span>
                                    ) : (
                                      <span className="p-1 badge square-pill bg-danger">
                                        {" "}
                                        Inactive
                                      </span>
                                    )
                                  ) : null}
                                </span>
                              </p>
                              <p className="mb-2 font-15">
                                <strong>Hobbies :</strong>{" "}
                                <span className="ms-2">
                                  {" "}
                                  {userone?.hobbies
                                    ?.map((hobby) => hobby?.name)
                                    ?.join(", ")}
                                </span>
                              </p>
                            </div>

                            <div className="col-12 col-md-6 text-start">
                              <p className="mb-2 font-15">
                                <strong>Gender :</strong>{" "}
                                <span className="ms-2">
                                  {userone?.gender === "man"
                                    ? "Male"
                                    : userone?.gender === "women"
                                    ? "Female"
                                    : userone?.gender === "non-binary"
                                    ? "Non-binary"
                                    : "Unknown"}
                                </span>
                              </p>

                              <p className="mb-2 font-15">
                                <strong>Email :</strong>{" "}
                                <span className="ms-2">{userone?.email}</span>
                              </p>

                              <p className="mb-2 font-15">
                                <strong>Age :</strong>{" "}
                                <span className="ms-2">{userone?.age}</span>
                              </p>

                              <p className="mb-2 font-15">
                                <strong>City :</strong>{" "}
                                <span className="ms-2">{userone?.city}</span>
                              </p>

                              <p className="mb-2 font-15">
                                <strong>Language :</strong>{" "}
                                <span className="ms-2">
                                  {" "}
                                  {userone?.languages
                                    ?.map((language) => language.name)
                                    .join(", ")}
                                </span>
                              </p>

                              <p className="mb-2 font-15">
                                <strong>Animal Sign :</strong>{" "}
                                <span className="ms-2">
                                  {userone?.animal_sign}
                                </span>
                              </p>

                              <p className="mb-2 font-15">
                                <strong>Education Status :</strong>{" "}
                                <span className="ms-2">
                                  {userone?.education}
                                </span>
                              </p>

                              <p className="mb-2 font-15">
                                <strong>Religion :</strong>{" "}
                                <span className="ms-2">
                                  {userone?.religion}
                                </span>
                              </p>

                              <p className="mb-2 font-15">
                                <strong>Mbti :</strong>{" "}
                                <span className="ms-2">
                                  {userone?.mbti_type}
                                </span>
                              </p>

                              <p className="mb-2 font-15">
                                <strong>Register Date :-</strong>{" "}
                                <span className="ms-2">
                                  {Common.formatDateToTimezone(
                                    userone?.created_at,
                                    GLOBALS.SHORT_DATE
                                  )}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-6">
                        <div
                          className="d-flex align-items-center"
                          onClick={() =>
                            navigate(`/user/view/${btoa(usertwo?._id)}`)
                          }
                        >
                          <img
                            src={usertwo?.profile_image}
                            alt={usertwo?.profile_image}
                            className="rounded-circle avatar-xxl img-thumbnail"
                          />
                          <div className="ms-3">
                            <p className="mb-2 font-15">
                              <strong>Full Name :</strong>{" "}
                              <span className="ms-2">
                                {usertwo?.first_name} {usertwo?.last_name}
                              </span>
                            </p>

                            <p className="mb-2 font-15">
                              <strong>Mobile :</strong>
                              <span className="ms-2">
                                {usertwo?.country_code} {usertwo?.mobile_number}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="text-start mt-2 row">
                          <div className="col-12 col-md-6">
                            <p className="mb-2 font-15">
                              <strong>Birth Date & Time:</strong>
                              <span className="ms-2">
                                {formatBirthDate(usertwo?.birth_date)}{" "}
                                {usertwo?.birth_time}
                              </span>
                            </p>

                            <p className="mb-2 font-15">
                              <strong>Country :</strong>{" "}
                              <span className="ms-2">{usertwo?.country}</span>
                            </p>

                            <p className="mb-2 font-15">
                              <strong>Nationality :</strong>{" "}
                              <span className="ms-2">
                                {usertwo?.nationality}
                              </span>
                            </p>

                            <p className="mb-2 font-15">
                              <strong>Zodiac Sign :</strong>{" "}
                              <span className="ms-2">
                                {usertwo?.zodiac_sign}
                              </span>
                            </p>

                            <p className="mb-2 font-15">
                              <strong>Employment Status :</strong>{" "}
                              <span className="ms-2">
                                {usertwo?.employment_status}
                              </span>
                            </p>

                            <p className="mb-2 font-15">
                              <strong>Parenthood Status :</strong>{" "}
                              <span className="ms-2">
                                {usertwo?.parenthood_status}
                              </span>
                            </p>

                            <p className="mb-2 font-15">
                              <strong>Height :</strong>{" "}
                              <span className="ms-2">
                                {usertwo?.height} {usertwo?.height_unit}
                              </span>
                            </p>

                            <p className="mb-2 font-15">
                              <strong>Status :-</strong>
                              <span className="ms-2">
                                {usertwo != null ? (
                                  usertwo?.is_active === 1 ? (
                                    <span className="p-1 badge square-pill bg-success">
                                      {" "}
                                      Active
                                    </span>
                                  ) : (
                                    <span className="p-1 badge square-pill bg-danger">
                                      {" "}
                                      Inactive
                                    </span>
                                  )
                                ) : null}
                              </span>
                            </p>
                            <p className="mb-2 font-15">
                              <strong>Hobbies :</strong>{" "}
                              <span className="ms-2">
                                {" "}
                                {usertwo?.hobbies
                                  ?.map((hobby) => hobby?.name)
                                  ?.join(", ")}
                              </span>
                            </p>
                          </div>

                          <div className="col-12 col-md-6 text-start">
                            <p className="mb-2 font-15">
                              <strong>Gender :</strong>{" "}
                              <span className="ms-2">
                                {usertwo?.gender === "man"
                                  ? "Male"
                                  : usertwo?.gender === "women"
                                  ? "Female"
                                  : usertwo?.gender === "non-binary"
                                  ? "Non-binary"
                                  : "Unknown"}
                              </span>
                            </p>

                            <p className="mb-2 font-15">
                              <strong>Email :</strong>{" "}
                              <span className="ms-2">{usertwo?.email}</span>
                            </p>

                            <p className="mb-2 font-15">
                              <strong>Age :</strong>{" "}
                              <span className="ms-2">{usertwo?.age}</span>
                            </p>

                            <p className="mb-2 font-15">
                              <strong>City :</strong>{" "}
                              <span className="ms-2">{usertwo?.city}</span>
                            </p>

                            <p className="mb-2 font-15">
                              <strong>Language :</strong>{" "}
                              <span className="ms-2">
                                {" "}
                                {usertwo?.languages
                                  ?.map((language) => language.name)
                                  .join(", ")}
                              </span>
                            </p>

                            <p className="mb-2 font-15">
                              <strong>Animal Sign :</strong>{" "}
                              <span className="ms-2">
                                {usertwo?.animal_sign}
                              </span>
                            </p>

                            <p className="mb-2 font-15">
                              <strong>Education Status :</strong>{" "}
                              <span className="ms-2">{usertwo?.education}</span>
                            </p>

                            <p className="mb-2 font-15">
                              <strong>Religion :</strong>{" "}
                              <span className="ms-2">{usertwo?.religion}</span>
                            </p>

                            <p className="mb-2 font-15">
                              <strong>Mbti :</strong>{" "}
                              <span className="ms-2">{usertwo?.mbti_type}</span>
                            </p>

                            <p className="mb-2 font-15">
                              <strong>Register Date :-</strong>{" "}
                              <span className="ms-2">
                                {Common.formatDateToTimezone(
                                  usertwo?.created_at,
                                  GLOBALS.SHORT_DATE
                                )}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {cafelist.length > 0 ? (
                    <>
                      <div className="card-body col-12 col-md-6 mt-2 mx-auto">
                        <form
                          id="assign_cafe"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className="row">
                            <div className="col-lg-12 mb-6">
                              <label htmlFor="cafe" className="form-label">
                                Cafe List
                              </label>
                              <select
                                className="form-control"
                                disabled={matchData.cafe_id}
                                id="cafe"
                                name="cafe"
                                defaultValue={matchData?.cafe_id}
                                value={matchData?.cafe_id}
                                {...register("cafe", {
                                  onChange: (e) => {
                                    handleCafeChange(e);
                                  },
                                  value: matchData?.cafe_id,
                                })}
                              >
                                <option value="">Select Cafe </option>
                                {cafelist?.map((item, key) => {
                                  return (
                                    <option key={key} value={item._id}>
                                      {item.name} ({item.location})
                                    </option>
                                  );
                                })}
                              </select>
                              <div className="forerror">
                                <span className="validation_error text-justify">
                                  {errors.cafe?.message}
                                </span>
                              </div>
                              <br />
                            </div>
                            <div className="col-lg-12 mb-6">
                              <label htmlFor="time" className="form-label">
                                Schedule Date
                              </label>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Controller
                                  name="date"
                                  defaultValue={
                                    matchData.schedule_date
                                      ? moment(matchData.schedule_date)
                                      : null
                                  }
                                  control={control}
                                  render={({ field }) =>
                                    console.log("field: ", field) || (
                                      <div className="w-100 input-group">
                                        <DatePicker
                                          {...field}
                                          sx={{ width: "100%" }}
                                          autoFocus
                                          disabled={matchData.cafe_id}
                                          disablePast
                                          open={open}
                                          onClose={() => setOpen(false)}
                                          disableOpenPicker={!getValues("cafe")}
                                          shouldDisableDate={shouldDisableDate}
                                          onChange={(e) => {
                                            field.onChange(e);
                                            handleScheduleDate(e);
                                          }}
                                          format="DD-MM-YYYY"
                                          slotProps={{
                                            textField: {
                                              onClick: () => setOpen(true),
                                            },
                                          }}
                                          className="custom-time-picker "
                                        />
                                      </div>
                                    )
                                  }
                                />
                              </LocalizationProvider>

                              <div className="forerror">
                                <span className="validation_error text-justify">
                                  {errors.date?.message}
                                </span>
                              </div>
                              <br />
                            </div>
                            <div className="col-lg-12 mb-6">
                              <label htmlFor="time" className="form-label">
                                Schedule Time
                              </label>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Controller
                                  name="time"
                                  control={control}
                                  defaultValue={
                                    matchData.schedule_time
                                      ? moment(Number(matchData?.schedule_time)).tz(matchData?.eventData?.[0]?.event_timezone)
                                      : null
                                  }
                                  render={({ field }) => (
                                    <div className="w-100 input-group">
                                      <TimePicker
                                        {...field}
                                        sx={{ width: "100%" }}
                                        autoFocus
                                        disabled={matchData.cafe_id}
                                        readOnly={
                                          !(
                                            timeLimit?.open_time ||
                                            timeLimit?.close_time
                                          )
                                        }
                                        disableOpenPicker={
                                          !getValues("cafe") &&
                                          !getValues("date")
                                        }
                                        open={isOpenTimePicker}
                                        onClose={() =>
                                          setIsOpenTimePicker(false)
                                        }
                                        slotProps={{
                                          textField: {
                                            onClick: () =>
                                              setIsOpenTimePicker(true),
                                          },
                                        }}
                                        ampm={false}
                                        minTime={moment(
                                          timeLimit?.open_time,
                                          "HH:mm"
                                        )}
                                        maxTime={moment(
                                          timeLimit?.close_time,
                                          "HH:mm"
                                        )}
                                        className="custom-time-picker "
                                      />
                                    </div>
                                  )}
                                />
                              </LocalizationProvider>

                              <div className="forerror">
                                <span className="validation_error text-justify">
                                  {errors.time?.message}
                                </span>
                              </div>
                              <br />
                            </div>
                          </div>
                          {matchData.cafe_id ? (
                            <></>
                          ) : (
                            <div className="row mt-3">
                              <div className="col-lg-6  offset-md-5">
                                <button
                                  type="submit"
                                  className="btn btn-raised btn-custom  waves-effect me-2"
                                >
                                  Submit
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-cancel waves-effect waves-light"
                                  onClick={() => navigate(-1)}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          )}
                        </form>
                      </div>
                    </>
                  ) : (
                    <>
                      <h4 className="text-center">
                        No Cafes Found Near by User
                      </h4>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AssignCafe;
