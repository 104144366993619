import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import moment from "moment";
import * as Common from "../../utils/Common";
import GLOBALS from "../../utils/Constant";
import {
  getCafeList,
  changeCafeStatus,
  removeCafe,
  getCountryList,
  getCityList,
  exportCafeData,
} from "../../Api/apiHandler";
import Select from "react-select";
import "bootstrap-daterangepicker/daterangepicker.css";
// import '@mui/x-data-grid/dist/x-data-grid.css';

export default function Listing() {
  const [cafeList, setCafeList] = useState(null);
  const [exportCafeList, setExportCafeList] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [countryList, setCountryList] = useState(null);
  const [cityList, setCityList] = useState(null);

  // State to manage the selected value in the dropdown
  const [cafeStatus, setCafeStatus] = useState(null);
  const [cafeType, setCafeType] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  // For fetch cafe list API
  const fetchCafeList = useCallback(
    async (request) => {
      try {
        request.page = currentPage;
        request.pageSize = pageSize;
        const response = await getCafeList(request);
        console.log(request, "req in cafe");
        // Update state with new data and total count
        if (response.code === 1) {
          setCafeList(response.data.data);
          setTotalItems(response.data.totalCount);
        } else {
          setCafeList([]);
          Common.ErrorAlert(response.message);
        }
      } catch (error) {
        Common.ErrorAlert("Oops! Something went wrong");
        console.error("Error fetching data:", error);
      }
    },
    [currentPage, pageSize]
  );

  // For fetch user list API
  const fetchExportCafeList = useCallback(async (request) => {
    try {
      const response = await exportCafeData(request);

      // Update state with new data and total count
      if (response.code === 1) {
        setExportCafeList(response.data);
      } else {
        setExportCafeList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, []);

  const fetchCountryList = useCallback(async (request) => {
    try {
      console.log("in country list");
      const response = await getCountryList(request);

      // Update state with new data and total count
      if (response.code === 1) {
        setCountryList(response.data);
      } else {
        setCountryList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, []);

  const fetchCityList = useCallback(async (request) => {
    try {
      const response = await getCityList(request);

      // Update state with new data and total count
      if (response.code === 1) {
        setCityList(response.data);
      } else {
        setCityList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, []);

  //Columns for datagrid table
  const columns = [
    { field: "_id", headerName: "ID", flex: 1, minWidth: 225 },
    { field: "name", headerName: "Name", flex: 1, minWidth: 200 },
    { field: "location", headerName: "Location", flex: 1, minWidth: 150 },
    { field: "city", headerName: "City", flex: 1, minWidth: 100 },
    { field: "country", headerName: "Country", flex: 1, minWidth: 100 },
    {
      field: "phone",
      headerName: "Contact Number",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => `${params.row.country_code} ${params.row.phone}`,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    { field: "description", headerName: "Description", flex: 1, minWidth: 150 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      renderCell: (params) =>
        params.row.is_active === 0 ? (
          <span
            className="badge bg-danger new-badge"
            onClick={() => changeState(params.row._id, "1")}
          >
            Inactive
          </span>
        ) : (
          <span
            className="badge bg-success new-badge"
            onClick={() => changeState(params.row._id, "0")}
          >
            Active
          </span>
        ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <>
          <Link
            to={`/cafe/view/${btoa(params.row._id)}`}
            title="View"
            className="action-icon"
          >
            {" "}
            <i className="mdi mdi-eye mdi-19px text-info"></i>
          </Link>

          <Link
            to={`/cafe/edit/${btoa(params.row._id)}`}
            title="Edit"
            className="action-icon"
          >
            {" "}
            <i className="mdi mdi-lead-pencil mdi-19px text-warning"></i>
          </Link>

          <span
            onClick={() => deleteCafe(params.row._id)}
            className="action-icon action-button"
          >
            {" "}
            <i className="mdi mdi-delete text-danger mdi-19px"></i>
          </span>
        </>
      ),
    },
  ];

  //Rows for datagrid table
  const rows = cafeList?.map((item, index) => ({
    ...item,
    id: item._id,
  }));

  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 10, // Adjust this based on your requirement
  });

  const handlePaginationModelChange = (newModel) => {
    // console.log(newModel,'newModel');
    setPaginationModel(newModel);
    // Access the current page number
    const currentPage = newModel.page + 1;
    // console.log(currentPage,'currentPage');

    setCurrentPage(currentPage);
    // setCurrentPage(newModel.page)
    setPageSize(newModel.pageSize);

    fetchCafeList({});
  };

  // Function to change status
  const changeState = (cafe_id, state) => {
    Swal.fire({
      title: "Change Status",
      text: "Are you sure you want to change status?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let params = {
          cafe_id: cafe_id,
          is_active: state,
        };

        try {
          const response = await changeCafeStatus(params);

          if (response.code === 1) {
            Common.SuccessAlert(response.message);
            fetchCafeList({});
          } else {
            Common.ErrorAlert(response.message);
          }
        } catch (error) {
          console.error("Error in changeCafeStatus:", error);
          Common.ErrorAlert("Failed to change status");
        }
      }
    });
  };

  // Function to remove
  const deleteCafe = (cafe_id) => {
    Swal.fire({
      title: "Delete",
      text: "Are you sure you want to delete?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let params = {
          cafe_id: cafe_id,
        };

        try {
          const response = await removeCafe(params);

          if (response.code === 1) {
            Common.SuccessAlert(response.message);
            fetchCafeList({});
          } else {
            Common.ErrorAlert(response.message);
          }
        } catch (error) {
          console.error("Error in remove cafe:", error);
          Common.ErrorAlert("Failed to change status");
        }
      }
    });
  };

  // Get Listing
  useEffect(() => {
    fetchCafeList({});
    fetchCountryList({});
    fetchExportCafeList({});
  }, [fetchCafeList, fetchCountryList, fetchExportCafeList]);

  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  //Function for export data in excel
  const exportExcel = () => {
    let columns = [
      {
        header: "ID",
        key: "id",
        width: 25,
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      },
      { header: "Type", key: "type", width: 25, horizontal: "center" },
      { header: "Name", key: "name", width: 25, horizontal: "center" },
      { header: "Phone", key: "phone", width: 25, horizontal: "center" },
      { header: "Location", key: "location", width: 20, horizontal: "center" },
      { header: "City", key: "city", width: 20, horizontal: "center" },
      { header: "Country", key: "country", width: 20, horizontal: "center" },
      {
        header: "Description",
        key: "description",
        width: 20,
        horizontal: "center",
      },
    ];

    const ExportData = exportCafeList?.map((cafe) => {
      return {
        id: cafe._id,
        phone: "+" + cafe.country_code + " " + cafe.phone,
        ...cafe,
      };
    });

    const fileName = `Cafe_Report_` + moment().format("DDMMYYYYhmmss");

    Common.exportExcelFile(columns, ExportData, fileName);
  };

  // ----------------------------------- Filter -----------------------------------

  //UseEffect For Filter
  useEffect(() => {
    let filter_params = {
      selectedCountry: selectedCountry?._id,
      selectedCity: selectedCity?._id,
      is_active: cafeStatus?.value,
      type: cafeType?.value,
    };

    // For fetch cafe list
    fetchCafeList(filter_params);
    fetchExportCafeList(filter_params);

    // }, [cafeStatus, loginStatus, dates]);
  }, [
    cafeStatus?.value,
    cafeType?.value,
    fetchCafeList,
    fetchExportCafeList,
    selectedCity?._id,
    selectedCountry?._id,
  ]);

  function clearFilter() {
    // Common.processing();
    setTimeout(() => {
      //     Swal.close()
      setCafeStatus(null);
      setCafeType(null);
      setSelectedCity(null);
      setSelectedCountry(null);
      // setDatesState({
      //     startDate: "",
      //     endDate: ""
      // })
    }, 1000);
  }

  // Handler function to update the selected value when the dropdown changes
  const handleStatusChange = (selectedOption) => {
    setCafeStatus(selectedOption);
  };

  const handleTypeChange = (selectedOption) => {
    setCafeType(selectedOption);
  };

  const handleCountryChange = (selectedOption) => {
    // console.log(selectedOption, 'selectedOption');
    setSelectedCountry(selectedOption);
    fetchCityList(selectedOption);
  };

  // const setDates = (e, { startDate, endDate }) => {
  //     setDatesState({
  //         startDate: startDate.format("YYYY-MM-DD"),
  //         endDate: endDate.format("YYYY-MM-DD")
  //     });
  // };

  const handleCityChange = (selectedOption) => {
    // console.log(selectedOption, 'selectedOption');
    setSelectedCity(selectedOption);
  };

  return (
    <>
      <div className="content-page">
        <div className="content">
          {/* <!-- Start Content--> */}
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Cafe</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Cafe</h4>
                </div>
              </div>
            </div>
            {/* <!-- end page title -->  */}

            <div className="row">
              <div className="col-12">
                {/* <!-- Portlet card --> */}
                <div className="card">
                  <Link
                    to="#cardCollpase4"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="true"
                    aria-controls="cardCollpase2"
                    className=""
                  >
                    <div className="card-header bg-app text-white">
                      <div className="card-widgets">
                        {/* <Link to="/#" data-toggle="reload" onClick={() => clearFilter()}><i className="mdi mdi-refresh"></i></Link>

                                                <Link to="#cardCollpase4" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="cardCollpase2" className="" >
                                                    <i className="mdi mdi-minus"></i>
                                                </Link> */}
                      </div>
                      <h5 className="card-title mb-0 text-white">Filter</h5>
                    </div>
                  </Link>

                  <div id="cardCollpase4" className="collapse show">
                    <div className="card-body">
                      <div className="row">
                        {/* country */}
                        <div className="col-md-2 mb-2">
                          <label className="form-label">Country</label>

                          <Select
                            value={selectedCountry}
                            onChange={handleCountryChange}
                            options={countryList}
                            placeholder="Select Country"
                            isSearchable={true}
                            getOptionLabel={(option) => option.name} // Specify the property for displaying the label
                            getOptionValue={(option) => option.name} // Specify the property for the option value
                            isClearable={true} // Enables the "Clear" option
                          />
                        </div>

                        {/* city */}
                        {selectedCountry && (
                          <div className="col-md-2 mb-2">
                            <label className="form-label">City</label>
                            <Select
                              value={selectedCity}
                              onChange={handleCityChange}
                              options={cityList}
                              placeholder="Select City"
                              isSearchable={true}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id.toString()}
                              isClearable={true} // Enables the "Clear" option
                            />
                          </div>
                        )}

                        {/* cafe type */}
                        <div className="col-md-3 mb-2">
                          <label className="form-label">Cafe Type</label>
                          <Select
                            value={cafeType}
                            onChange={handleTypeChange}
                            options={GLOBALS.CAFE_TYPE}
                            placeholder="Select Cafe Type"
                            isSearchable={true}
                            isClearable={true} // Enables the "Clear" option
                          />
                        </div>

                        {/* is active status */}
                        <div className="col-md-2 mb-2">
                          <label className="form-label">Status</label>

                          <Select
                            value={cafeStatus}
                            onChange={handleStatusChange}
                            options={GLOBALS.statusOption}
                            placeholder="Select Status"
                            isSearchable={true} // Enables search functionality
                          />
                        </div>

                        {/* date and time */}
                        {/* <div className="col-md-3 mb-2">

                                                    <label className="form-label">Register Date</label>

                                                    <DateRangePicker
                                                        onApply={setDates}
                                                        initialSettings={{ startDate: moment().subtract(6, "days").format('MM/DD/YYYY'), endDate: moment().format('MM/DD/YYYY'), ranges: GLOBALS.datePickerRanges }} >
                                                        <input
                                                            type="text"
                                                            value={dates.startDate !== '' ? moment(dates.startDate).format('MMMM DD, YYYY') + " - " + moment(dates.endDate).format('MMMM DD, YYYY') : 'Select Period'}
                                                            className="form-control"
                                                            onChange={() => { console.log(`changes`); }}
                                                        />
                                                    </DateRangePicker>

                                                </div> */}
                      </div>

                      <div className="row">
                        <div className="col-auto">
                          <div className="text-lg-end my-1 my-lg-0">
                            <button
                              type="button"
                              onClick={() => clearFilter()}
                              className="btn btn-warning waves-effect waves-light me-1"
                            >
                              Clear
                            </button>

                            <button
                              type="button"
                              onClick={() => exportExcel()}
                              className="btn btn-success waves-effect waves-light me-1"
                            >
                              Export
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- end card--> */}
              </div>

              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="col-md-12 mb-3">
                      <Link to={`/cafe/add`} title="Add">
                        <button
                          type="button"
                          className="btn btn-custom waves-effect waves-light mr-1"
                        >
                          Add
                        </button>
                      </Link>
                    </div>

                    {cafeList && (
                      <div className="overflow-x-auto w-100">
                        <DataGrid
                          columns={columns}
                          rows={rows}
                          rowHeight={80}
                          disableColumnFilter
                          disableDensitySelector
                          disableExport
                          slots={{ toolbar: GridToolbar }}
                          filterModel={filterModel}
                          onFilterModelChange={(newModel) =>
                            setFilterModel(newModel)
                          }
                          slotProps={{
                            toolbar: {
                              showQuickFilter: true,
                              csvOptions: { disableToolbarButton: true },
                              printOptions: { disableToolbarButton: true },
                            },
                          }}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 10 },
                            },
                            sorting: {
                              sortModel: [{ field: "id", sort: "desc" }],
                            },
                          }}
                          pageSizeOptions={[10, 25, 50, 100]}
                          pageSize={paginationModel.pageSize}
                          onPaginationModelChange={handlePaginationModelChange}
                          paginationMode="server" // Make sure to set paginationMode to "server"
                          rowCount={totalItems} // Pass the total count of items to rowCount
                          pagination={true} // Enable pagination
                          autoHeight
                          autoSize
                        />
                      </div>
                    )}
                  </div>
                  {/* <!-- end card body--> */}
                </div>
                {/* <!-- end card --> */}
              </div>
              {/* <!-- end col--> */}
            </div>
            {/* <!-- end row--> */}
          </div>
        </div>
      </div>
    </>
  );
}
