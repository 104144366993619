import "./App.css";
import React from "react";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import EditAdmin from "./pages/auth/EditAdmin";
import Logout from "./pages/auth/Logout";
import UserList from "./pages/user/UserList";
import UserView from "./pages/user/View";
import CafeList from "./pages/cafe/Listing";
import CafeView from "./pages/cafe/View";
import CafeAdd from "./pages/cafe/Add";
import CafeEdit from "./pages/cafe/Edit";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import CmsPage from "./pages/cms_content/CmsPage";
import ContactUs from "./pages/contactus/ContactUs";
import NotifiView from "./pages/notification/Listing";
import NotifySend from "./pages/notification/Add";
import VirtualEventView from "./pages/event/VirtualListing";
import VirtualReportsView from "./pages/reports/VirtualListing";
import RealReportsView from "./pages/reports/RealTimeMatchList";
import VirtualEarningView from "./pages/earning/VirtualListing";
import CreateVirtual from "./pages/event/CreateVirtual";
import EditVirtual from "./pages/event/EditVirtual";
import RealTimeMatchList from "./pages/event/RealTimeMatchList";
import RealTimeMatchEarningList from "./pages/earning/RealTimeMatchList";
import RefundEarnignList from "./pages/earning/RefundListing";
import AssignCafe from "./pages/event/AssignCafe";
import ContactUsReply from "./pages/contactus/ContactUsReply";
import AddFaq from "./pages/faq/add";
import EditFaq from "./pages/faq/edit";
import FaqList from "./pages/faq/listing";
import SubAdmin from "./pages/subAdmin/Listing";
import SubAdminAdd from "./pages/subAdmin/Add";
import SubAdminEdit from "./pages/subAdmin/Edit";
import RegisteredUsers from "./pages/registered-users/RegisteredUsersListing";
import { Helmet } from "react-helmet";
import Preview from "./pages/cms-view/preview";
import PageNotFound from "./pages/404";


function App() {
  return (
    <>
      <Helmet>
        <script
          src={process.env.PUBLIC_URL + "/assets/js/app.js"}
          async
        ></script>
      </Helmet>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/update-admin" element={<EditAdmin />} />

          <Route path="/subAdmin" element={<SubAdmin />} />
          <Route path="/subAdmin/add" element={<SubAdminAdd />} />
          <Route path="/subAdmin/edit/:id" element={<SubAdminEdit />} />

          <Route path="/user" element={<UserList />} />
          <Route path="/user/view/:id" element={<UserView />} />

          <Route path="/cafe" element={<CafeList />} />
          <Route path="/cafe/view/:id" element={<CafeView />} />
          <Route path="/cafe/add" element={<CafeAdd />} />
          <Route path="/cafe/edit/:id" element={<CafeEdit />} />

          <Route path="/event/virtual" element={<VirtualEventView />} />
          <Route path="/reports/virtual" element={<VirtualReportsView />} />
          <Route path="/reports/realtimematch" element={<RealReportsView />} />
          <Route path="/registered-users" element={<RegisteredUsers />} />

          <Route path="/event/createvirtual" element={<CreateVirtual />} />
          <Route path="/event/virtual/edit/:id" element={<EditVirtual />} />
          <Route path="/event/realtimematch" element={<RealTimeMatchList />} />

          <Route path="/earning/virtual" element={<VirtualEarningView />} />
          <Route
            path="/earning/realtimematch"
            element={<RealTimeMatchEarningList />}
          />
          <Route
            path="/earning/refundearning"
            element={<RefundEarnignList />}
          />

          <Route path="/assign/:id" element={<AssignCafe />} />

          <Route path="/notification" element={<NotifiView />} />
          <Route path="/notification/send" element={<NotifySend />} />

          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/contact-us/reply/:id" element={<ContactUsReply />} />

          <Route path="/faq" element={<FaqList />} />
          <Route path="/faq/add" element={<AddFaq />} />
          <Route path="/faq/edit/:id" element={<EditFaq />} />

          <Route
            path="/about-us"
            element={<CmsPage title="About us" page_name="about_us" />}
          />
          <Route
            path="/terms-conditions"
            element={
              <CmsPage title="Terms & Condition" page_name="term&condition" />
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <CmsPage title="Privacy Policy" page_name="privacypolicy" />
            }
          />
          <Route
            path="/online-speed-dating-guideline"
            element={
              <CmsPage
                title="Online Speed Dating Guideline"
                page_name="online_speed_dating_guideline"
              />
            }
          />
          <Route
            path="/offline-dating-guideline"
            element={
              <CmsPage
                title="Offline Dating Guideline"
                page_name="offline_dating_guideline"
              />
            }
          />
          <Route
            path="/payment-guideline"
            element={
              <CmsPage
                title="Payment Guideline"
                page_name="payment_guideline"
              />
            }
          />
          <Route
            path="/online_speed_dating_info"
            element={
              <CmsPage
                title="Online Speed Dating Info"
                page_name="online_speed_dating_info"
              />
            }
          />
          <Route
            path="/offline_blind_dating_info"
            element={
              <CmsPage
                title="Offline Blind Dating Info"
                page_name="offline_blind_dating_info"
              />
            }
          />
          <Route
            path="online_speed_dating_pre_registration"
            element={
              <CmsPage
                title="Online Speed Dating Pre Registration"
                page_name="online_speed_dating_pre_registration"
              />
            }
          />
          <Route
            path="offline_blind_dating_pre_registration"
            element={
              <CmsPage
                title="Offline Blind Dating Pre Registration"
                page_name="offline_blind_dating_pre_registration"
              />
            }
          />
          <Route
            path="refund_policy"
            element={
              <CmsPage title="Refund Policy" page_name="refund_policy" />
            }
          />
          <Route
            path="info_until_event_start"
            element={
              <CmsPage
                title="Info Untill Event Start"
                page_name="info_until_event_start"
              />
            }
          />
          <Route
            path="safety_tips_and_guidelines"
            element={
              <CmsPage
                title="Safety Tips and Guidelines"
                page_name="safety_tips_and_guidelines"
              />
            }
          />
          <Route
            path="safety_guidlines"
            element={
              <CmsPage title="Safety Guideline" page_name="safety_guidlines" />
            }
          />
          <Route
            path="upload_image_guideline"
            element={
              <CmsPage
                title="Upload Image Guideline"
                page_name="upload_image_guideline"
              />
            }
          />
           <Route
            path="/account_deletation"
            element={
              <CmsPage
                title="Account Deletation Page"
                page_name="deletation_page"
              />
            }
          />

          <Route path="/logout" element={<Logout />} />
        </Route>
        <Route path="/" element={<Login />} />
        <Route path="/pages/:page" element={<Preview />} />
        <Route path="*" element={<PageNotFound />} />
        {/* <Redirect from="*" to="/404" /> */}
      </Routes>
    </>
  );
}

export default App;
