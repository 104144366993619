import React, { useState, useEffect, useCallback } from 'react'
import {
    DataGrid,
    GridToolbar
} from '@mui/x-data-grid';

import * as Common from '../../utils/Common';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';
import { getFaqList, changeFaqStatus, removeFaq } from '../../Api/apiHandler';
import "bootstrap-daterangepicker/daterangepicker.css";

export default function Listing() {

    const [faqList, setFaqList] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    // For fetch user list API
    const fetchFaqList = useCallback(async (request) => {
        try {
            request.page = currentPage
            request.pageSize = pageSize
            const response = await getFaqList(request);

            if (response.code === 1) {
                console.log(response.data);
                setFaqList(response.data.data);
                setTotalItems(response.data.totalCount);
            } else {
                setFaqList([]);
                Common.ErrorAlert(response.message);
            }
        } catch (error) {
            Common.ErrorAlert('Oops! Something went wrong');
            console.error('Error fetching data:', error);
        }
    },[currentPage, pageSize]);

    // Get Listing 
    useEffect(() => {
        fetchFaqList({});
    }, [fetchFaqList]);

    function deleteFaq(faq_id) {

        Swal.fire({
            title: 'Delete Faq',
            text: "Are you sure you want to Delete Faq?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {

            if (result.isConfirmed) {

                let response = await removeFaq({ "faq_id": faq_id })

                if (response.code === 1) {
                    // $('#contactus_table').DataTable().row("#contactUsId" + contactUsId).remove().draw(false);
                    fetchFaqList({});
                    Common.SuccessAlert(response.message)
                }

            }
        })

    }

    // Function to change status
    const changeState = (faq_id, state) => {

        Swal.fire({
            title: 'Change Status',
            text: 'Are you sure you want to change status?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it!'
        }).then(async (result) => {

            if (result.isCoZzsznfirmed) {

                let params =
                {
                    "faq_id": faq_id,
                    "is_active": state
                }

                try {

                    const response = await changeFaqStatus(params);

                    if (response.code === 1) {
                        Common.SuccessAlert(response.message);
                        fetchFaqList({});
                    } else {
                        Common.ErrorAlert(response.message);
                    }
                } catch (error) {
                    console.error('Error in changeCafeStatus:', error);
                    Common.ErrorAlert('Failed to change status');
                }
            }
        });
    };


    const [filterModel, setFilterModel] = useState({
        items: [],
        quickFilterExcludeHiddenColumns: true,
        quickFilterValues: [''],
    });

    //Columns for datagrid table
    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'question', headerName: 'Question', flex: 1.5 },
        { field: 'answer', headerName: 'Answer', flex: 1.5 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                (params.row.is_active === 0) ?
                    <span className="badge bg-danger new-badge" onClick={() => changeState(params.row._id, '1')}>Inactive</span> :
                    <span className="badge bg-success new-badge" onClick={() => changeState(params.row._id, '0')} >Active</span>
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <>
                    <Link to={`/faq/edit/${btoa(params.row._id)}`} title="Edit" className="action-icon"> <i className="mdi mdi-lead-pencil mdi-19px text-warning"></i></Link>

                    <span onClick={() => deleteFaq(params.row._id)} className="action-icon action-button"> <i className="mdi mdi-delete text-danger mdi-19px"></i></span>
                </>
            ),
        },
    ];

    //Rows for datagrid table
    const rows = faqList?.map((item, index) => ({
        ...item,
        id: item._id,
    }));

    const [paginationModel, setPaginationModel] = useState({
        page: 1,
        pageSize: 10, // Adjust this based on your requirement
    });

    const handlePaginationModelChange = (newModel) => {

        console.log(newModel, 'newModel');
        setPaginationModel(newModel);
        // Access the current page number
        const currentPage = newModel.page + 1;
        // console.log(currentPage,'currentPage');

        setCurrentPage(currentPage)
        // setCurrentPage(newModel)
        setPageSize(newModel.pageSize)

        fetchFaqList({});
    };


    return (
        <>
            {/* ================================ Start Page Content ================================== */}
            <div className="content-page">
                <div className="content">

                    {/* <!-- Start Content--> */}
                    <div className="container-fluid" >

                        {/* <!-- start page title --> */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box">
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                            <li className="breadcrumb-item active">FAQ</li>
                                        </ol>
                                    </div>
                                    <h4 className="page-title">FAQ List</h4>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end page title -->  */}

                        <div className="row">

                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className='row '>

                                            <div className="col-md-6 mb-3">

                                                <Link to={`/faq/add`} title="Add">
                                                    <button type="button" className="btn btn-custom waves-effect waves-light mr-1">Add</button>
                                                </Link>
                                            </div>
                                        </div>

                                        {faqList && (

                                            <DataGrid
                                                columns={columns}
                                                rows={rows}
                                                rowHeight={80}
                                                disableColumnFilter
                                                disableDensitySelector
                                                disableExport
                                                slots={{ toolbar: GridToolbar }}
                                                filterModel={filterModel}
                                                onFilterModelChange={(newModel) => setFilterModel(newModel)}
                                                // slotProps={{ toolbar: { showQuickFilter: true, }, }}
                                                slotProps={{ toolbar: { showQuickFilter: true, csvOptions: { disableToolbarButton: true }, printOptions: { disableToolbarButton: true } }, }}
                                                initialState={{
                                                    pagination: {
                                                        paginationModel: { page: 0, pageSize: 10 },
                                                    },
                                                }}
                                                pageSizeOptions={[10, 25, 50, 100]}
                                                pageSize={paginationModel.pageSize}
                                                onPaginationModelChange={handlePaginationModelChange}
                                                paginationMode="server" // Make sure to set paginationMode to "server"
                                                rowCount={totalItems} // Pass the total count of items to rowCount
                                                pagination={true} // Enable pagination
                                            />
                                        )}

                                    </div>
                                    {/* <!-- end card body--> */}
                                </div>
                                {/* <!-- end card --> */}
                            </div>
                            {/* <!-- end col--> */}
                        </div>
                        {/* <!-- end row--> */}
                    </div>
                </div>
            </div>
        </>
    )
}
