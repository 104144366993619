import moment from "moment";

const Globals = {
  APP_NAME: "Splinder",

  statusOption: [
    { value: null, label: "Select Status" },
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ],

  kycStatus: [
    { value: null, label: "Select Status" },
    { value: 1, label: "Verified" },
    { value: 0, label: "Not Verified" },
  ],

  assignOption: [
    { value: null, label: "Select" },
    { value: 1, label: "Assigned" },
    { value: 0, label: "Not Assigned" },
  ],

  blockOption: [
    { value: null, label: "Select Block Status" },
    { value: 1, label: "Block" },
    { value: 0, label: "Unblock" },
  ],

  datingOption: [
    { value: null, label: "Select Dating Type" },
    { value: "online", label: "Online Dating" },
    { value: "offline", label: "Offline Dating" },
  ],

  paymentOption: [
    { value: null, label: "Select Payment Status" },
    { value: "succeeded", label: "Succeeded" },
    { value: "pending", label: "Pending" },
    { value: "failed", label: "Failed" },
  ],

  genderList: [
    { value: null, label: "Select Gender" },
    { value: "man", label: "Male" },
    { value: "woman", label: "Female" },
    { value: "non-binary", label: "Non-Binary" },
  ],

  CAFE_TYPE: [
    { value: null, label: "Select Type" },
    { value: "Cafe", label: "Cafe" },
    { value: "Restaurant", label: "Restaurant" },
  ],

  BIRTH_YEAR_ANIMAL: [
    { value: null, label: "Select Animal" },
    { value: "Rat", label: "Rat" },
    { value: "Ox", label: "Ox" },
    { value: "Pig", label: "Pig" },
    { value: "Goat", label: "Goat" },
    { value: "Dog", label: "Dog" },
    { value: "Tiger", label: "Tiger" },
    { value: "Monkey", label: "Monkey" },
    { value: "Dragon", label: "Dragon" },
    { value: "Rabbit", label: "Rabbit" },
    { value: "Rooster", label: "Rooster" },
    { value: "Snake", label: "Snake" },
    { value: "Horse", label: "Horse" },
  ],

  ZODIAC_SIGN: [
    { value: null, label: "Select Zodiac" },
    { value: "Aries", label: "Aries" },
    { value: "Taurus", label: "Taurus" },
    { value: "Gemini", label: "Gemini" },
    { value: "Cancer", label: "Cancer" },
    { value: "Leo", label: "Leo" },
    { value: "Virgo", label: "Virgo" },
    { value: "Libra", label: "Libra" },
    { value: "Scorpio", label: "Scorpio" },
    { value: "Sagittarius", label: "Sagittarius" },
    { value: "Capricorn", label: "Capricorn" },
    { value: "Aquarius", label: "Aquarius" },
    { value: "Pisces", label: "Pisces" },
  ],

  PERMISSIONS: [
    { value: null, label: "Select Permission" },
    { value: "Sub Admin", label: "Sub Admin" },
    { value: "Cafe", label: "Cafe/Restaurant" },
    { value: "Event", label: "Event" },
    { value: "Reports", label: "Reports" },
    { value: "CMS", label: "CMS" },
    { value: "User", label: "User" },
    { value: "Notification", label: "Notification" },
    { value: "Contact Us", label: "Contact Us" },
    { value: "Earning", label: "Earning" },
    { value: "Registered User", label: "Registered User" },
  ],
  // PERMISSIONS: [{ value: null, label: 'Select Permission' },{ value: 'sub_admin', label: 'Sub Admin' },{ value: 'cafe', label: 'Cafe/Restaurant' },{ value: 'event', label: 'Event' },{ value: 'cms', label: 'CMS' },{ value: 'user', label: 'User' },{ value: 'notification', label: 'Notification' },{ value: 'contact_us', label: 'Contact Us' }],

  loginOption: [
    { value: null, label: "Select Login Status" },
    { value: "Online", label: "Online" },
    { value: "Offline", label: "Offline" },
  ],

  datePickerRanges: {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment()],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "This Year": [moment().startOf("year"), moment().endOf("year")],
    "Last Year": [
      moment().subtract(1, "year").startOf("year"),
      moment().subtract(1, "year").endOf("year"),
    ],
  },

  LogoURL: "/assets/images/logo.png",

  DefaultImage: "/assets/images/default.png",

  S3_BUCKET_NAME: process.env.REACT_APP_S3_BUCKET_NAME,

  S3_REGION: process.env.REACT_APP_S3_REGION,

  S3_URL: process.env.REACT_APP_S3_URL,

  S3_ACCESS_KEY: process.env.REACT_APP_ACCESS_KEY,

  S3_SECRET_ACCESS_KEY: process.env.REACT_APP_SECRET_ACCESS_KEY,

  perPage: 10,

  LONG_DATE: "YYYY-MM-DD HH:mm A",

  SHORT_DATE: "YYYY-MM-DD",

  LONG_TIME: "HH:mm A",
};

export default Globals;
