import React, { useEffect, useState } from 'react';
import { getCafeDetails } from '../../Api/apiHandler';
import { useParams, Link, useNavigate } from 'react-router-dom';
import GLOBALS from '../../utils/Constant';
import * as Common from '../../utils/Common';


export default function View() {

    let { id } = useParams();
    const [cafeDetails, setCafeDetails] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {

        const fetchCafeDetails = async (request) => {

            try {
                if (id !== undefined) {

                    const response = await getCafeDetails(request);

                    if (response.code === 1) {
                        setCafeDetails(response.data)
                    }
                }
            } catch (error) {
                Common.ErrorAlert('Oops! Something went wrong');
                console.error('Error fetching data:', error);
            }
        }

        fetchCafeDetails({ cafe_id: atob(id) })
    }, [id]);

    if (cafeDetails == null) return <> </>
    return (
        <>
            <div className="content-page">
                <div className="content">

                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box">
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><Link to='/dashboard'>{process.env.REACT_APP_NAME}</Link></li>
                                            <li className="breadcrumb-item active"><Link to='/cafe'>Cafe</Link></li>
                                            <li className="breadcrumb-item active">View Cafe</li>
                                        </ol>
                                    </div>
                                    <h4 className="page-title">View Cafe</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="mb-3">
                                        <button className="btn btn-custom text-capitalize custom-back" onClick={() => navigate(-1)}><i className="fe-arrow-left font-28"></i></button>
                                    </div>
                                    <div className="card-body">

                                        <div className="row mb-3 text-center">
                                            <div className='text-center'>
                                                <img src={cafeDetails?.files[0].file} alt={cafeDetails?.files[0].name} className='rounded-circle avatar-xxl img-thumbnail' />
                                            </div>
                                            <div className='text-center mt-2'>
                                                <span className="mb-0"> {cafeDetails?.bio} </span>
                                            </div>
                                        </div>

                                        <div className="row">

                                            <div className="tab-content">
                                                <div className="tab-pane active" id="cafe_details">
                                                    <div className="row mx-3">
                                                        <div className="col-lg-6 ">
                                                            <div className="text-start">

                                                                <p className="mb-2 font-15"><strong>Type :</strong> <span className="ms-2">{cafeDetails?.type}</span></p>

                                                                <p className="mb-2 font-15"><strong>Name :</strong> <span className="ms-2">{cafeDetails?.name}</span></p>

                                                                <p className="mb-2 font-15"><strong>Mobile :</strong><span className="ms-2">{cafeDetails?.country_code} {cafeDetails?.phone}</span></p>

                                                                <p className="mb-2 font-15"><strong>Location :</strong> <span className="ms-2">{cafeDetails?.location}</span></p>

                                                                <p className="mb-2 font-15"><strong>City :</strong> <span className="ms-2">{cafeDetails?.city}</span></p>

                                                                <p className="mb-2 font-15"><strong>Country :</strong> <span className="ms-2">{cafeDetails?.country}</span></p>

                                                                <p className="mb-2 font-15"><strong>Description :</strong> <span className="ms-2">{cafeDetails?.description}</span></p>

                                                                <p className="mb-2 font-15"><strong>Status :-</strong>
                                                                    <span className="ms-2">
                                                                        {
                                                                            (cafeDetails.is_active === 1) ?
                                                                                <span className='p-1 badge square-pill bg-success' > Active</span> :
                                                                                <span className='p-1 badge square-pill bg-danger' > Inactive</span>
                                                                        }
                                                                    </span>
                                                                </p>

                                                                <p className="mb-2 font-15"><strong>Register Date :-</strong> <span className="ms-2">{Common.formatDateToTimezone(cafeDetails?.created_at, GLOBALS.SHORT_DATE)}</span></p>

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 ">
                                                            <div className="text-start">


                                                                <div className="col-lg-12 ">
                                                                    <p className="mb-2 font-15"><strong>Time Slots :</strong></p>

                                                                    <div className="row">
                                                                        <div className="col-lg-3 ">
                                                                            <p className="mb-2 font-15"><strong>Day</strong></p>
                                                                        </div>
                                                                        <div className="col-lg-3 ">
                                                                            <p className="mb-2 font-15"><strong>Open Time</strong></p>
                                                                        </div>
                                                                        <div className="col-lg-3 ">
                                                                            <p className="mb-2 font-15"><strong>Close Time</strong></p>
                                                                        </div>
                                                                    </div>
                                                                    {cafeDetails?.time.map((time) => (
                                                                        <>
                                                                            <div className="row">
                                                                                <div className="col-lg-3 ">

                                                                                    <p className="mb-2 font-15"><span className="ms-2">
                                                                                        <span key={time.day}>{time.day}</span>
                                                                                    </span></p>
                                                                                </div>
                                                                                <div className="col-lg-3 ">

                                                                                    <p className="mb-2 font-15"><span className="ms-2">
                                                                                        <span >{time.open_time} </span>
                                                                                    </span></p>
                                                                                </div>
                                                                                <div className="col-lg-3 ">

                                                                                    <p className="mb-2 font-15"><span className="ms-2">
                                                                                        <span >{time.close_time} </span>
                                                                                    </span></p>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ))}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mx-3 mt-3">
                                                        <hr />
                                                        <p className="font-15 d-flex justify-content-center"><strong>Images</strong></p>
                                                        <hr />
                                                    </div >

                                                    <div className="row mx-3">
                                                        <div className="col-lg-12">
                                                            <div className="row">
                                                                {cafeDetails?.files.map((images) => (
                                                                    images.type === 'image' ? (
                                                                        <div className="col-lg-2 mb-2" key={images.name}>
                                                                            <img
                                                                                className="img-fluid view-image"
                                                                                src={images.file}
                                                                                alt={images.name}
                                                                                style={{ objectFit: 'cover' }}
                                                                            />
                                                                        </div>
                                                                    ) : null
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mx-3 mt-3">
                                                        <hr />
                                                        <p className="font-15 d-flex justify-content-center"><strong>Videos</strong></p>
                                                        <hr />
                                                    </div >

                                                    <div className="row mx-3">
                                                        {cafeDetails?.files.map((videos) => (
                                                            videos.type === 'video' ? (
                                                                <div className="col-lg-3" key={videos.name}>
                                                                    <video
                                                                        controls
                                                                        width="250"
                                                                        height="250"
                                                                        className="form-control view-image"
                                                                        style={{ objectFit: 'cover' }}
                                                                    >
                                                                        <source src={videos.file} type="video/mp4" />
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                </div>
                                                            ) : null
                                                        ))}
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
