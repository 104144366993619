import React, { useState, useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  editCafe,
  getCafeDetails,
  getCountryList,
  getCityList,
} from "../../Api/apiHandler";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Common from "../../utils/Common";
import Select from "react-select";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import GoogleMapComponent from "../../Components/GoogleMap/MapContainer";

export default function Edit() {
  let { id } = useParams();
  const navigate = useNavigate();

  const [cafeDetails, setCafeDetails] = useState(null);
  console.log("cafeDetails: ", cafeDetails);
  const [countryList, setCountryList] = useState(null);
  const [cityList, setCityList] = useState(null);
  const [isOpenTimePicker, setIsOpenTimePicker] = useState();
  const [isCloseTimePicker, setIsCloseTimePicker] = useState();
  const [selectedVideos, setSelectedVideos] = useState([]);
  console.log("image: deleltee +++++++", selectedVideos);

  const [selectedImages, setSelectedImages] = useState([]);
  const [location, setLocation] = useState(null);
  const [map, setMap] = useState(null);

  const validationSchema = Yup.object().shape({
    // files , time
    name: Yup.string().required("Please enter name"),
    type: Yup.string().required("Please select type"),
    country: Yup.string().required("Please select country"),
    city: Yup.string().required("Please select city"),
    location: Yup.string().required("Please enter location"),
    description: Yup.string().required("Please enter description"),
    country_code: Yup.string().required("Please select country code"),
    phone: Yup.string()
      .required("Please enter phone number")
      .matches("^[0-9]", "Only numbers are allowed for this field")
      .min(8, "Phone must be at least 8 numbers")
      .max(14, "Phone must be at most 14 numbers"),

    timeSlots: Yup.array().of(
      Yup.object().shape({
        day: Yup.string().required(),
        open_time: Yup.string().nullable(),
        close_time: Yup.string().when("open_time", {
          is: (openTime) => openTime !== null,
          then: (time) => {
            return Yup.string()
              .test(
                "is-at-least-one-hour-difference",
                "Difference between open time and close time should be at least 1 hour",
                function (closeTime) {
                  const { open_time } = this.parent;

                  if (open_time && closeTime) {
                    const openMoment = moment(open_time);
                    const closeMoment = moment(closeTime);

                    const differenceInHours = closeMoment.diff(
                      openMoment,
                      "hours"
                    );
                    return differenceInHours >= 1;
                  }

                  return true; // If either open_time or close_time is not provided, skip the validation.
                }
              )
              .required("Please enter close time");
          },
          otherwise: () => Yup.string().nullable(),
        }),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    register,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      images: [{ file: null }],
      videos: [{ file: null }],
      // location: cafeDetails?.location,
    },
    resolver: yupResolver(validationSchema),
    context: { userChanged: false }, // Pass isDirty as userChanged to yup context
  });

  console.log("errors: ", errors);
  console.log("watch: ", watch());

  const onSelectFile = useCallback(async (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = await Promise.all(
      selectedFilesArray.map(async (file) => {
        const image = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });
        return { file, image };
      })
    );

    setSelectedImages((previousImages) => [...previousImages, ...imagesArray]);
    event.target.value = "";
  }, []);

  function deleteHandler(image) {
    setSelectedImages((prevImages) => {
      const updatedImages = prevImages.filter((e) => e !== image);
      URL.revokeObjectURL(image.image);
      return updatedImages;
    });
  }

  console.log(selectedVideos, "data final123 ");

  function deleteHandlerVideo(image, index) {
    console.log("image: deleltee", image);
    setSelectedVideos((prevImages) => {
      const updatedImages = prevImages.filter((e, pre) => pre !== index);
      URL.revokeObjectURL(image.video);
      return updatedImages;
    });
  }

  const handleCountryChange = (selectedOptions) => {
    setValue("country_code", selectedOptions?.phone_code);
  };
  const generateThumbnail = (videoFile) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      video.preload = "metadata";

      // Add an error handler
      video.onerror = function () {
        console.error("Error loading video");
        reject("Error loading video");
      };

      // Set autoplay to trigger loading metadata
      video.currentTime = 0.5;
      video.autoplay = true;
      video.addEventListener("loadeddata", () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert canvas content to Blob
        canvas.toBlob((blob) => {
          resolve(
            new File(
              [blob],
              `${[...Array(10)]
                .map(() =>
                  String.fromCharCode(
                    Math.floor(Math.random() * 26) +
                      (Math.random() > 0.5 ? 65 : 97)
                  )
                )
                .join("")}.jpeg`,
              { type: "image/jpeg" }
            )
          );
        }, "image/jpeg");
      });

      video.src = URL.createObjectURL(videoFile);
    });
  };

  const onSelectVideoFile = async (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const videosArray = await Promise.all(
      selectedFilesArray.map(async (file) => {
        const video = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });

        // Generate video thumbnail
        const thumbnail = await generateThumbnail(file);

        return { file, video, thumbnail };
      })
    );

    setValue("videos", videosArray); // Update the form value

    setSelectedVideos((previousVideos) => [...previousVideos, ...videosArray]);

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  const onSubmit = useCallback(
    async (data) => {
      let files = [];
      if (selectedImages && selectedImages.length > 0) {
        selectedImages.forEach((selectedImages) => {
          if (selectedImages.file.size) {
            const randomFileName = Common.generateRandomFileName(
              selectedImages.file.name
            );

            // Upload the image to S3
            Common.singleImageUpload(
              randomFileName,
              selectedImages.file,
              "/zaya/cafes"
            );

            // Add the file information to the 'files' array
            files.push({ name: randomFileName, type: "image" });
          } else {
            console.log("else");
            files.push({ name: selectedImages.name, type: "image" });
          }
        });
      }

      if (selectedVideos && selectedVideos.length > 0) {
        selectedVideos.forEach((selectedVideo) => {
          console.log(selectedVideo, "out");
          if (selectedVideo.file.size) {
            console.log(selectedVideo, "if");

            // Generate a random file name for the image
            const randomFileName = Common.generateRandomFileName(
              selectedVideo.file.name
            );

            // Upload the image to S3
            const uploadedVideo = Common.singleImageUpload(
              randomFileName,
              selectedVideo.file,
              "/zaya/cafes"
            );
            console.log("uploadedVideo: ", uploadedVideo);

            const thumbnail = Common.generateRandomFileName(
              selectedVideo.thumbnail.name
            );
            const uploadedThumbnail = Common.singleImageUpload(
              thumbnail,
              selectedVideo.thumbnail,
              "/zaya/cafes"
            );

            console.log("uploadedThumbnail: ", uploadedThumbnail);

            // Add the file information to the 'files' array
            files.push({
              name: randomFileName,
              type: "video",
              thumbnail: thumbnail,
            });
          } else {
            console.log(selectedVideo, "else");
            files.push({ name: selectedVideo.name, type: "video" });
          }
        });
      }
      let cafeData = {
        name: data.name.trimStart().trimEnd(),
        type: data.type,
        cafe_id: atob(id),
        description: data.description,
        country_code: data.country_code,
        phone: data.phone,
        city_id: data.city,
        files: files,
        time: data.timeSlots?.map((item) => {
          return {
            ...item,
            open_time: item.open_time
              ? moment(item.open_time).format("HH:mm")
              : "",
            close_time: item.close_time
              ? moment(item.close_time).format("HH:mm")
              : "",
          };
        }),
        country_id: data.country,
        location: data.location,
        latitude: location?.lat,
        longitude: location?.lng,
      };
      console.log("cafeData:+++++ ", cafeData);
      try {
        const response = await editCafe(cafeData);
        if (response.code === 1) {
          Common.SuccessAlert(response.message);
          setTimeout(() => {
            navigate("/cafe");
          }, 1000);
        } else {
          Common.ErrorAlert(response.message);
        }
      } catch (error) {
        Common.ErrorAlert("Oops! Something went wrong");
        console.error("Error while adding data:", error);
      }
    },
    [id, location?.lat, location?.lng, navigate, selectedImages, selectedVideos]
  );

  const fetchCountryList = useCallback(async (request) => {
    try {
      const response = await getCountryList(request);

      // Update state with new data and total count
      if (response.code === 1) {
        console.log("in country list", response.data);
        setCountryList(response.data);
      } else {
        setCountryList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, []);

  const handleCityChange = useCallback(
    (e) => {
      setValue("city", e?._id);
      const city = cityList?.find((item) => item._id === e._id);
      setLocation({
        lat: parseFloat(city?.latitude),
        lng: parseFloat(city?.longitude),
      });
      // setPosition(latLng);
      map.setZoom(10);
      map.panTo({
        lat: parseFloat(city?.latitude),
        lng: parseFloat(city?.longitude),
      });
    },
    [cityList, map, setValue]
  );

  const fetchCityList = useCallback(async (request) => {
    try {
      console.log(JSON.parse(request), "rquest in city");
      let req = {
        id: request,
      };
      const response = await getCityList(req);

      // Update state with new data and total count
      if (response.code === 1) {
        console.log(response.data, "rquest in city");
        setCityList(response.data);
      } else {
        setCityList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, []);

  const fetchCafeDetails = useCallback(
    async (request) => {
      try {
        if (id !== undefined) {
          const response = await getCafeDetails(request);

          if (response.code === 1) {
            setCafeDetails(response.data);
            // Filter files of type 'image'
            const imageFiles = response.data.files.filter(
              (file) => file.type === "image"
            );
            const modifiedImageFiles = imageFiles.map((file) => {
              return {
                ...file,
                image: file.file,
              };
            });
            const videoFiles = response.data.files.filter(
              (file) => file.type === "video"
            );
            const modifiedVideoFiles = videoFiles.map((file) => {
              return {
                ...file,
                video: file.file,
              };
            });

            // Pass filtered files to initializeFields
            setSelectedImages(modifiedImageFiles);
            setSelectedVideos(modifiedVideoFiles);

            // initializeFields(response.data.files);
            fetchCityList(response.data.countryId);
            if (
              response?.data?.latitude !== "" ||
              response?.data?.longitude !== ""
            ) {
              map?.setZoom(10);
              map?.panTo({
                lat: parseFloat(response?.data?.latitude),
                lng: parseFloat(response?.data?.longitude),
              });
              // setMap(map);

              if (map) {
                setLocation({
                  lat: parseFloat(response?.data?.latitude),
                  lng: parseFloat(response?.data?.longitude),
                });
              }
            }
            setValue("location", response?.data?.location);
          }
        }
      } catch (error) {
        Common.ErrorAlert("Oops! Something went wrong");
        console.error("Error fetching data:", error);
      }
    },
    [fetchCityList, id, map, setValue]
  );

  // Get Listing
  useEffect(() => {
    fetchCountryList({});
  }, [fetchCountryList]);

  useEffect(() => {
    fetchCafeDetails({ cafe_id: atob(id) });
  }, [fetchCafeDetails, id]);

  if (cafeDetails === null) return <> </>;
  if (cityList === null) return <> </>;
  if (countryList === null) return <> </>;

  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">
                          {process.env.REACT_APP_NAME}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/cafe">Cafe</Link>
                      </li>
                      <li className="breadcrumb-item active">Edit Cafe</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Edit Cafe</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="mb-3">
                    <button
                      className="btn btn-custom text-capitalize custom-back"
                      onClick={() => navigate(-1)}
                    >
                      <i className="fe-arrow-left font-28"></i>
                    </button>
                  </div>
                  <div className="card-body mt-2">
                    <div className="row">
                      <form id="edit_cafe" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                          <div className="col-lg-3 mb-3">
                            <label htmlFor="type" className="form-label">
                              Type
                            </label>
                            <select
                              className="form-control"
                              id="type"
                              name="type"
                              defaultValue={cafeDetails?.type}
                              {...register("type")}
                            >
                              <option value="">Select Type</option>
                              <option value="Cafe">Cafe</option>
                              <option value="Restaurant">Restaurant</option>
                            </select>

                            <div className="forerror">
                              <span className="validation_error text-justify">
                                {errors.type?.message}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-3">
                            <label htmlFor="name" className="form-label">
                              Name
                            </label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              className="form-control"
                              placeholder="Enter Name"
                              defaultValue={cafeDetails?.name}
                              {...register("name")}
                            />
                            <div className="forerror">
                              <span className="validation_error text-justify">
                                {errors.name?.message}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6">
                            <div className="row col-lg-12">
                              <div className="col-lg-4 mb-3">
                                <label
                                  htmlFor="country_code"
                                  className="form-label"
                                >
                                  Country Code
                                </label>
                                <Select
                                  defaultValue={countryList?.find(
                                    (item) =>
                                      item.phone_code ===
                                      cafeDetails?.country_code
                                  )}
                                  name="country_code"
                                  options={countryList}
                                  getOptionLabel={(option) =>
                                    option.phone_code + " " + option.name
                                  }
                                  getOptionValue={(option) => option.phone_code}
                                  placeholder="Select Country"
                                  {...register("country_code", {
                                    value: countryList?.find(
                                      (item) =>
                                        item.phone_code ===
                                        cafeDetails?.country_code
                                    )?.phone_code,
                                  })}
                                  onChange={(e) => {
                                    handleCountryChange(e);
                                  }}
                                />
                                <div className="forerror">
                                  <span className="validation_error text-justify">
                                    {errors.country_code?.message}
                                  </span>
                                </div>
                              </div>

                              <div className="col-lg-8 mb-3">
                                <label htmlFor="phone" className="form-label">
                                  Phone
                                </label>
                                <input
                                  type="text"
                                  id="phone"
                                  name="phone"
                                  {...register("phone")}
                                  defaultValue={cafeDetails?.phone}
                                  className="form-control"
                                  placeholder="Phone Number"
                                />
                                <div className="forerror">
                                  <span className="validation_error text-justify">
                                    {errors.phone?.message}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="row col-lg-12">
                              <div className="col-lg-7 mb-2">
                                <label htmlFor="country" className="form-label">
                                  Country
                                </label>
                                <select
                                  className="form-control"
                                  id="country"
                                  name="country"
                                  defaultValue={cafeDetails?.countryId}
                                  {...register("country")}
                                  onChange={(event) =>
                                    fetchCityList(event.target.value)
                                  }
                                >
                                  <option value="">Select country </option>
                                  {countryList?.map((item, key) => {
                                    // console.log(item,'item itemitem');
                                    return (
                                      <option key={key} value={item.id}>
                                        {" "}
                                        {item.name}{" "}
                                      </option>
                                    );
                                  })}
                                </select>

                                <div className="forerror">
                                  <span className="validation_error text-justify">
                                    {errors.country?.message}
                                  </span>
                                </div>
                              </div>

                              <div className="col-lg-4 mb-3">
                                <label htmlFor="city" className="form-label">
                                  City
                                </label>
                                <Select
                                  defaultValue={cityList?.find(
                                    (item) => item._id === cafeDetails?.city_id
                                  )}
                                  name="city"
                                  options={cityList}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option._id}
                                  placeholder="Select City"
                                  {...register("city", {
                                    value: cityList?.find(
                                      (item) => item._id === cafeDetails?.city_id
                                    )?._id,
                                  })}
                                  onChange={handleCityChange}
                                />
                                <div className="forerror">
                                  <span className="validation_error text-justify">
                                    {errors.city?.message}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row col-lg-12">
                              <div className="col-lg-12 mb-3">
                                <label
                                  htmlFor="location"
                                  className="form-label"
                                >
                                  Location
                                </label>
                                <input
                                  type="text"
                                  id="location"
                                  disabled={true}
                                  className="form-control"
                                  placeholder="Cafe location"
                                  {...register("location")}
                                  defaultValue={cafeDetails?.location}
                                />
                                <div className="forerror">
                                  <span className="validation_error text-justify">
                                    {errors.location?.message}
                                  </span>
                                </div>
                              </div>
                              <GoogleMapComponent
                                setValue={setValue}
                                location={location}
                                setLocation={setLocation}
                                map={map}
                                setMap={setMap}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="row col-lg-12">
                              <div className="col-lg-12 mb-3">
                                <label
                                  htmlFor="description"
                                  className="form-label"
                                >
                                  Description
                                </label>
                                <textarea
                                  type="description"
                                  id="description"
                                  name="description"
                                  rows="5"
                                  className="form-control"
                                  placeholder="description"
                                  {...register("description")}
                                  defaultValue={cafeDetails?.description}
                                />
                                <div className="forerror">
                                  <span className="validation_error text-justify">
                                    {errors.description?.message}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row col-lg-12 mb-1">
                              <div className="col-lg-3 mb-1">
                                <label>Days</label>
                              </div>
                              <div className="col-lg-4 mb-1">
                                <label>Open Time</label>
                              </div>
                              <div className="col-lg-4 mb-1">
                                <label>Close Time</label>
                              </div>
                            </div>
                            {[
                              "Monday",
                              "Tuesday",
                              "Wednesday",
                              "Thursday",
                              "Friday",
                              "Saturday",
                              "Sunday",
                            ].map((day, index) => {
                              const dayData = cafeDetails?.time?.find(
                                (timeSlot) =>
                                  timeSlot.day.toLowerCase() ===
                                  day.toLowerCase()
                              );
                              // console.log(dayData?.day,'dayData timeslots');
                              // console.log(day.toString(),'dayData timeslots');
                              return (
                                <div key={day} className="row mb-1 col-lg-12">
                                  <label className="col-lg-3 mb-1">
                                    {day.charAt(0).toUpperCase() + day.slice(1)}
                                  </label>
                                  <input
                                    {...register(`timeSlots[${index}].day`)}
                                    type="hidden"
                                    className="form-control"
                                    defaultValue={day}
                                  />
                                  <div className="col-lg-4 mb-1">
                                    <LocalizationProvider
                                      dateAdapter={AdapterMoment}
                                    >
                                      <Controller
                                        name={
                                          "timeSlots[" + index + "].open_time"
                                        }
                                        control={control}
                                        defaultValue={
                                          dayData?.open_time
                                            ? moment(
                                                dayData?.open_time,
                                                "HH:mm"
                                              )
                                            : null
                                        }
                                        render={({ field }) => (
                                          <div className="w-100 input-group">
                                            <TimePicker
                                              {...field}
                                              sx={{ width: "100%" }}
                                              autoFocus
                                              open={isOpenTimePicker === index}
                                              onClose={() =>
                                                setIsOpenTimePicker()
                                              }
                                              slotProps={{
                                                textField: {
                                                  onClick: () =>
                                                    setIsOpenTimePicker(index),
                                                },
                                              }}
                                              ampm={false}
                                              className="custom-time-picker "
                                            />
                                          </div>
                                        )}
                                      />
                                    </LocalizationProvider>
                                    <div className="forerror">
                                      <span className="validation_error text-justify">
                                        {
                                          errors.timeSlots?.[index]?.open_time
                                            ?.message
                                        }
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 mb-1">
                                    <LocalizationProvider
                                      dateAdapter={AdapterMoment}
                                    >
                                      <Controller
                                        name={
                                          "timeSlots[" + index + "].close_time"
                                        }
                                        control={control}
                                        defaultValue={
                                          dayData?.close_time
                                            ? moment(
                                                dayData?.close_time,
                                                "HH:mm"
                                              )
                                            : null
                                        }
                                        render={({ field }) => (
                                          <div className="w-100 input-group">
                                            <TimePicker
                                              {...field}
                                              sx={{ width: "100%" }}
                                              autoFocus
                                              open={isCloseTimePicker === index}
                                              onClose={() =>
                                                setIsCloseTimePicker()
                                              }
                                              slotProps={{
                                                textField: {
                                                  onClick: () =>
                                                    setIsCloseTimePicker(index),
                                                },
                                              }}
                                              ampm={false}
                                              minTime={getValues(
                                                "timeSlots[" +
                                                  index +
                                                  "].open_time"
                                              )}
                                              // maxTime={moment(
                                              //   timeLimit?.close_time,
                                              //   "HH:mm"
                                              // )}
                                              className="custom-time-picker "
                                            />
                                          </div>
                                        )}
                                      />
                                    </LocalizationProvider>
                                    <div className="forerror">
                                      <span className="validation_error text-justify">
                                        {
                                          errors.timeSlots?.[index]?.close_time
                                            ?.message
                                        }
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="row">
                          {/* Image Upload Section */}
                          <div className="col-lg-6 mb-3">
                            {/* <label htmlFor="image" className="form-label">
                              Images&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <button type="button" className="btn btn-success" onClick={() => appendImage({ file: null })}>
                                Add
                              </button>
                            </label>

                            {imageFields.map((field, index) => {
                              console.log(field, 'fieldfieldfieldfieldfieldfield');
                              console.log(imagePreviews[index], 'imagePreviews[index]');
                              return (

                                <div className="row mb-2" key={field.id}>
                                  <div className="row">
                                    <div className="col-lg-7">
                                      <input
                                        type="file"
                                        id={`image-${index}`}
                                        className="form-control"
                                        onChange={(e) => handleImageChange(e, index)}
                                      />
                                    </div>
                                    <div className="col-lg-3">
                                      {imagePreviews[index] && (
                                        <img
                                          className="form-control"
                                          src={imagePreviews[index]}
                                          alt={`Preview ${index + 1}`}
                                          style={{ width: '125px', height: '125px', objectFit: 'cover' }}
                                        />
                                      )}
                                    </div>
                                    <div className="col-lg-2">
                                      {imagePreviews[index] && (
                                        <button className="btn btn-danger " onClick={() => removeImage(index)}>
                                          Remove
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                  <div className="forerror">
                                    <span className="validation_error ">
                                      {errors?.images?.[index]?.file && <p>{errors?.images?.[index]?.file.message}</p>}
                                    </span>
                                    <span className="validation_error ">
                                      {errors?.images?.[index]?.type && <p>{errors?.images?.[index]?.message}</p>}
                                    </span>
                                  </div>
                                </div>
                              );
                            })} */}

                            <label for="example-fileinput" class="form-label">
                              Image
                            </label>
                            <input
                              type="file"
                              id="example-fileinput"
                              accept="image/*"
                              class="form-control"
                              multiple
                              {...register("images")}
                              onChange={onSelectFile}
                            />

                            <div
                              className="col-md-12 mb-3 mt-2"
                              style={{ overflow: "auto" }}
                            >
                              <div className="preview-container">
                                {selectedImages.map((item, index) => (
                                  <div
                                    key={index}
                                    className={
                                      index === 0
                                        ? "first-d-preview"
                                        : "other-d-preview"
                                    }
                                  >
                                    <img
                                      src={item.image}
                                      alt={`Preview ${index}`}
                                      className="preview-image"
                                    />
                                    <button
                                      type="button"
                                      className="delete-img-button"
                                      onClick={() => deleteHandler(item)}
                                    >
                                      <i className="mdi mdi-delete font-22 avatar-title new-text-danger"></i>
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>

                          {/* Video Upload Section */}
                          <div className="col-lg-6 mb-3">
                            {/* <label htmlFor="video" className="form-label">Videos&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button type="button" className="btn btn-success" onClick={() => appendVideo({ file: null })}> Add </button></label>

                            {videoFields.map((field, index) => (
                              <div className="row mb-2" key={field.id}>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <input
                                      type="file"
                                      id="video"
                                      className="form-control"
                                      onChange={(e) => {
                                        setValue(`videos.${index}.file`, e.target.files[0]);
                                        handleVideoChange(e, index);
                                        // Handle video preview or other logic if needed
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-4">
                                    {videoPreviews[index] && (
                                      <video
                                        controls
                                        width="150"
                                        height="150"
                                        className="form-control"
                                        style={{ objectFit: 'cover' }}
                                      >
                                        <source src={videoPreviews[index]} type="video/mp4" />
                                        Your browser does not support the video tag.
                                      </video>
                                    )}

                                  </div>
                                  <div className="col-lg-2">
                                    {videoPreviews[index] && (
                                      <button className="btn btn-danger " onClick={() => removeVideo(index)}>
                                        Remove
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="forerror">
                                  <span className="validation_error ">  {errors?.videos?.[index]?.file && <p>{errors?.videos?.[index]?.file.message}</p>}</span>
                                  <span className="validation_error ">  {errors?.videos?.type && <p>{errors?.videos?.message}</p>}</span>
                                </div>
                              </div>

                            ))} */}

                            <label for="example-fileinput" class="form-label">
                              Videos
                            </label>
                            <input
                              type="file"
                              id="example-fileinput"
                              class="form-control"
                              multiple
                              accept="video/*"
                              {...register("videos")}
                              onChange={onSelectVideoFile}
                            />

                            <div
                              className="col-md-12 mb-3 mt-2"
                              style={{ overflow: "auto" }}
                            >
                              <div className="preview-container">
                                {selectedVideos.map((item, index) => (
                                  <div
                                    key={index}
                                    className={
                                      index === 0
                                        ? "first-d-preview"
                                        : "other-d-preview"
                                    }
                                  >
                                    {/* <img src={item.video} alt={`Preview ${index}`} className='preview-video' /> */}
                                    <video
                                      controls
                                      width="150"
                                      height="150"
                                      className="preview-video"
                                      style={{ objectFit: "cover" }}
                                      src={item?.video}
                                    >
                                      {/* <source type="video/mp4" />
                                      Your browser does not support the video
                                      tag. */}
                                    </video>
                                    <button
                                      type="button"
                                      className="delete-img-button"
                                      onClick={() =>
                                        deleteHandlerVideo(item, index)
                                      }
                                    >
                                      <i className="mdi mdi-delete font-22 avatar-title new-text-danger"></i>
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-lg-6  offset-md-5">
                            <button
                              type="submit"
                              className="btn btn-raised btn-custom  waves-effect me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              className="btn btn-cancel waves-effect waves-light"
                              onClick={() => navigate(-1)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
