import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getOfflineUserMatch } from "../../Api/apiHandler";
import Select from "react-select";
import * as Common from "../../utils/Common";
import "bootstrap-daterangepicker/daterangepicker.css";
import Globals from "../../utils/Constant";
import moment from "moment";

function RealTimeMatchList() {
  const navigate = useNavigate();
  const [virtuamatch, setvirtuaMatch] = useState([]);
  console.log("virtuamatch: ", virtuamatch);
  const [eventStatus, setEventStatus] = useState(null);

  function clearFilter() {
    setTimeout(() => {
      //     Swal.close()
      setEventStatus(null);
    }, 1000);
  }
  const handleStatusChange = (selectedOption) => {
    setEventStatus(selectedOption);
  };

  const getvirtualeventlist = useCallback(async (request = {}) => {
    try {
      const response = await getOfflineUserMatch({ id: 0, ...request });

      if (response.code === 1) {
        setvirtuaMatch(response.data);
        console.log("response.data: ", response.data);
        Common.SuccessAlert(response.message);
      } else {
        Common.ErrorAlert(response.message);
        setvirtuaMatch([]);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, []);

  //Columns for datagrid table
  const columns = [
    { field: "index", headerName: "Sr No", minWidth: 50 },
    { field: "user", headerName: "User", flex: 1, minWidth: 150 },
    { field: "matcher", headerName: "Macher", flex: 1, minWidth: 150 },
    {
      field: "created_at",
      headerName: "Matched Date",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "schedule_date",
      headerName: "Scheduled Time",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "cafe_name",
      headerName: "Cafe Details",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "Assign",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) =>
        params.row.cafe_id != null ? (
          <span
            className="badge bg-primary new-badge "
            onClick={() => navigate(`/assign/${btoa(params.row._id)}`)}
            title="view"
          >
            {" "}
            Assigned
          </span>
        ) : (
          <span
            className="badge bg-success new-badge"
            onClick={() => navigate(`/assign/${btoa(params.row._id)}`)}
          >
            Assign
          </span>
        ),
    },
    // { field: 'message', headerName: 'Message', flex: 2 },
  ];

  const exportExcel = () => {
    let columns = [
      { header: "Sr No", key: "index", width: 25, horizontal: "center" },
      { header: "User", key: "user", width: 25, horizontal: "center" },
      { header: "Macher", key: "matcher", width: 25, horizontal: "center" },
      {
        header: "Matched Date",
        key: "created_at",
        width: 25,
        horizontal: "center",
      },
      {
        header: "Scheduled Time",
        key: "schedule_date",
        width: 25,
        horizontal: "center",
      },
      {
        header: "Cafe Details",
        key: "cafe_name",
        width: 25,
        horizontal: "center",
      },
      {
        header: "Event",
        key: "event",
        width: 20,
        horizontal: "center",
      },
      {
        header: "Assign Status",
        key: "status",
        width: 20,
        horizontal: "center",
      },
    ];

    const ExportData = rows?.map((row, index) => {
      return {
        id: row._id,
        phone: "+" + row.country_code + " " + row.phone,
        status: row.cafe_id != null ? "Assigned" : "Not Assigned",
        ...row,
      };
    });

    const fileName = `Real_Event_Report_` + moment().format("DDMMYYYYhmmss");

    Common.exportExcelFile(columns, ExportData, fileName);
  };

  //Rows for datagrid table
  const rows = virtuamatch?.map((item, index) => ({
    ...item,
    index: index + 1,
    id: item._id,
    user: item.user1[0]?.first_name + " " + item.user1[0]?.last_name,
    matcher: item.user2[0]?.first_name + " " + item.user2[0]?.last_name,
    event: item.eventData[0]?.name,
    cafe_name: item?.cafe_details?.[0]?.name,
    created_at: moment(item.created_at).format("DD-MM-YYYY"),
    schedule_date:
      item.schedule_date && item.schedule_time
        ? moment(item.schedule_date).format("DD-MM-YYYY") +
          " " +
          moment(Number(item?.schedule_time)).tz(item?.eventData?.[0]?.event_timezone).format("HH:mm")
        : "",
  }));

  useEffect(() => {
    getvirtualeventlist({
      is_assigned: eventStatus?.value?.toString(),
    });
  }, [eventStatus?.value, getvirtualeventlist]);

  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Real Time Matching
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">Real Time Matching</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                {/* <!-- Portlet card --> */}
                <div className="card">
                  <Link
                    to="#cardCollpase4"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="true"
                    aria-controls="cardCollpase2"
                    className=""
                  >
                    <div className="card-header bg-app text-white">
                      <div className="card-widgets">
                        {/* <Link to="/#" data-toggle="reload" onClick={() => clearFilter()}><i className="mdi mdi-refresh"></i></Link>

                                                <Link to="#cardCollpase4" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="cardCollpase2" className="" >
                                                    <i className="mdi mdi-minus"></i>
                                                </Link> */}
                      </div>
                      <h5 className="card-title mb-0 text-white">Filter</h5>
                    </div>
                  </Link>

                  <div id="cardCollpase4" className="collapse show">
                    <div className="card-body">
                      <div className="row">
                        {/* is active status */}
                        <div className="col-md-2 mb-2">
                          <label className="form-label">Status</label>

                          <Select
                            value={eventStatus}
                            onChange={handleStatusChange}
                            options={Globals.assignOption}
                            placeholder="Select Assign"
                            isSearchable={true} // Enables search functionality
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-auto">
                          <div className="text-lg-end my-1 my-lg-0">
                            <button
                              type="button"
                              onClick={() => clearFilter()}
                              className="btn btn-warning waves-effect waves-light me-1"
                            >
                              Clear
                            </button>
                            <button
                              type="button"
                              onClick={() => exportExcel()}
                              className="btn btn-success waves-effect waves-light me-1"
                            >
                              Export
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- end card--> */}
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    {/* <div className="col-md-12 mb-3">
                                            <Link to={`/`} title="Add">
                                                <button type="button" className="btn btn-custom waves-effect waves-light mr-1">Real Time Match</button>
                                            </Link>
                                        </div> */}
                    {virtuamatch && (
                      <DataGrid
                        columns={columns}
                        rows={rows}
                        rowHeight={80}
                        disableColumnFilter
                        disableDensitySelector
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true },
                          },
                        }}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                          },
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RealTimeMatchList;
