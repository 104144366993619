import React, { useState, useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { addCafe, getCountryList, getCityList } from "../../Api/apiHandler";
import { Link, useNavigate } from "react-router-dom";
import * as Common from "../../utils/Common";
import Select from "react-select";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import GoogleMapComponent from "../../Components/GoogleMap/MapContainer";

export default function Add() {
  const [countryList, setCountryList] = useState(null);
  const [cityList, setCityList] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [isOpenTimePicker, setIsOpenTimePicker] = useState();
  const [isCloseTimePicker, setIsCloseTimePicker] = useState();
  const [location, setLocation] = useState(null);
  console.log("location:============> ", location);
  const [map, setMap] = useState(null);

  const onSelectFile = async (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = await Promise.all(
      selectedFilesArray.map(async (file) => {
        const image = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });
        return { file, image };
      })
    );
    // console.log(imagesArray, "imagesArray");
    setValue("images", imagesArray); // Update the form value

    setSelectedImages((previousImages) => [...previousImages, ...imagesArray]);

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  // console.log(selectedImages, "data selectedImages final123 ");

  function deleteHandler(image) {
    setSelectedImages((prevImages) => {
      const updatedImages = prevImages.filter((e) => e !== image);
      URL.revokeObjectURL(image.image);
      setValue("images", updatedImages); // Update the form value

      return updatedImages;
    });
  }

  const onSelectVideoFile = async (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const videosArray = await Promise.all(
      selectedFilesArray.map(async (file) => {
        const video = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });

        // Generate video thumbnail
        const thumbnail = await generateThumbnail(file);

        return { file, video, thumbnail };
      })
    );

    setValue("videos", videosArray); // Update the form value

    setSelectedVideos((previousVideos) => [...previousVideos, ...videosArray]);

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  // const generateThumbnail = (videoFile) => {
  //   return new Promise((resolve) => {
  //     const video = document.createElement('video');
  //     video.src = URL.createObjectURL(videoFile);
  //     video.addEventListener('loadeddata', () => {
  //       const canvas = document.createElement('canvas');
  //       canvas.width = video.videoWidth;
  //       canvas.height = video.videoHeight;
  //       const ctx = canvas.getContext('2d');
  //       ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

  //       // Convert canvas content to Blob
  //       canvas.toBlob((blob) => {
  //         resolve(new File([blob], `${Array.from({ length: 6 }, () => String.fromCharCode(Math.floor(Math.random() * 62) + 48)).join('')}.jpeg`, { type: 'image/jpeg' }));
  //       }, 'image/jpeg');
  //     });
  //   });
  // };

  const generateThumbnail = (videoFile) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      video.preload = "metadata";

      // Add an error handler
      video.onerror = function () {
        console.error("Error loading video");
        reject("Error loading video");
      };

      // Set autoplay to trigger loading metadata
      video.currentTime = 0.5;
      video.autoplay = true;
      video.addEventListener("loadeddata", () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert canvas content to Blob
        canvas.toBlob((blob) => {
          resolve(
            new File(
              [blob],
              `${[...Array(10)]
                .map(() =>
                  String.fromCharCode(
                    Math.floor(Math.random() * 26) +
                      (Math.random() > 0.5 ? 65 : 97)
                  )
                )
                .join("")}.jpeg`,
              { type: "image/jpeg" }
            )
          );
        }, "image/jpeg");
      });

      video.src = URL.createObjectURL(videoFile);
    });
  };

  // console.log(selectedVideos, "data selectedVideos final123 ");

  function deleteHandlerVideo(image) {
    setSelectedVideos((prevImages) => {
      const updatedImages = prevImages.filter((e) => e !== image);
      URL.revokeObjectURL(image.video);
      setValue("videos", updatedImages); // Update the form value
      return updatedImages;
    });
  }

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    // files , time
    name: Yup.string().required("Please enter name"),
    type: Yup.string().required("Please select type"),
    country: Yup.string().required("Please select country"),
    city: Yup.string().required("Please select city"),
    location: Yup.string().required("Please enter location"),
    description: Yup.string().required("Please enter description"),
    country_code: Yup.string().required("Please select country code"),
    phone: Yup.string()
      .required("Please enter phone number")
      .matches("^[0-9]", "Only numbers are allowed for this field")
      .min(8, "Phone must be at least 8 numbers")
      .max(14, "Phone must be at most 14 numbers"),
    images: Yup.array()
      .min(1, "Select at least 1 image")
      .max(10, "Select at most 10 images")
      .test(
        "fileType",
        "Invalid file type. Only images are allowed.",
        (value) => {
          console.log("No files selected");
          if (!value) {
            console.log("No files selected");
            return true; // Skip validation if no files are selected
          }

          const isValid = value.every((file) => {
            console.log("File Object:", file); // Log the file object

            // Check if file.type is defined and starts with 'image/'
            const isImage =
              file.file.type && file.file.type.startsWith("image/");
            console.log("Is Image:", isImage);

            return isImage;
          });

          console.log("Validation Result:", isValid);
          return isValid;
        }
      ),

    videos: Yup.array()
      .min(1, "Select at least 1 video")
      .max(3, "Select at most 3 videos")
      .test(
        "fileType",
        "Invalid file type. Only videos are allowed.",
        (value) => {
          console.log("Videos Validation - No files selected");
          if (!value) {
            console.log("Videos Validation - No files selected");
            return true; // Skip validation if no files are selected
          }

          const isValid = value.every((file) => {
            console.log("Videos Validation - File Object:", file); // Log the file object

            // Check if file.type is defined and starts with 'video/'
            const isVideo =
              file.file.type && file.file.type.startsWith("video/");
            console.log("Videos Validation - Is Video:", isVideo);

            return isVideo;
          });

          console.log("Videos Validation - Result:", isValid);
          return isValid;
        }
      ),

    timeSlots: Yup.array().of(
      Yup.object().shape({
        day: Yup.string().required(),
        open_time: Yup.string().nullable(),
        close_time: Yup.string().when("open_time", {
          is: (openTime) => openTime !== null,
          then: (time) => {
            return Yup.string()
              .test(
                "is-at-least-one-hour-difference",
                "Difference between open time and close time should be at least 1 hour",
                function (closeTime) {
                  const { open_time } = this.parent;

                  if (open_time && closeTime) {
                    const openMoment = moment(open_time);
                    const closeMoment = moment(closeTime);

                    const differenceInHours = closeMoment.diff(
                      openMoment,
                      "hours"
                    );
                    return differenceInHours >= 1;
                  }

                  return true; // If either open_time or close_time is not provided, skip the validation.
                }
              )
              .required("Please enter close time");
          },
          otherwise: () => Yup.string().nullable(),
        }),
      })
    ),
  });
  // const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    control,
    handleSubmit,
    register,
    getValues,
    watch,
    setValue,
    formState,
  } = useForm({
    defaultValues: {
      images: [],
      videos: [],
      country_code: "",
      timeSlots: [
        { day: "Monday", open_time: null, close_time: null },
        { day: "Tuesday", open_time: null, close_time: null },
        { day: "Wednesday", open_time: null, close_time: null },
        { day: "Thursday", open_time: null, close_time: null },
        { day: "Friday", open_time: null, close_time: null },
        { day: "Saturday", open_time: null, close_time: null },
        { day: "Sunday", open_time: null, close_time: null },
      ],
    },
    resolver: yupResolver(validationSchema),
  });

  const { errors } = formState;

  const onSubmit = async (data) => {
    // return;
    let files = [];

    if (selectedImages && selectedImages.length > 0) {
      const imageFile = selectedImages;

      // if (data.images && data.images.length > 0) {
      //     const imageFile = data.images;

      // Generate random file names for each image
      const randomImageNames = imageFile.map((file) => {
        const randomFileName = Common.generateRandomFileName(file.file.name);
        return randomFileName;
      });

      // Upload each image
      randomImageNames.forEach((randomFileName, index) => {
        const finalImageFile = imageFile[index];

        files.push({ name: randomFileName, type: "image" });
        // Call the singleImageUpload function for each image
        Common.singleImageUpload(
          randomFileName,
          finalImageFile.file,
          "/zaya/cafes"
        );
      });
    }

    if (selectedVideos && selectedVideos.length > 0) {
      const videoFile = selectedVideos;

      // if (data.videos && data.videos.length > 0) {
      //     const videoFile = data.videos;

      // Generate random file names for each image
      const randomVideoNames = videoFile.map((file) =>
        Common.generateRandomFileName(file.file.name)
      );

      // Upload each video
      randomVideoNames.forEach(async (randomFileName, index) => {
        const finalVideoFile = videoFile[index];
        Common.singleImageUpload(
          randomFileName,
          finalVideoFile.file,
          "/zaya/cafes"
        );
        console.log(
          "finalVideoFile.thumbnail.name: ",
          finalVideoFile,
          finalVideoFile.thumbnail.name
        );
        const thumbnail = Common.generateRandomFileName(
          finalVideoFile.thumbnail.name
        );
        console.log("thumbnail: ", thumbnail);

        Common.singleImageUpload(
          thumbnail,
          finalVideoFile.thumbnail,
          "/zaya/cafes"
        );

        files.push({
          name: randomFileName,
          type: "video",
          thumbnail: thumbnail,
        });
        // Call the singleImageUpload function for each image
      });
    }

    // console.log("data.location: ", data.location);
    // const latLng = await geocodeByAddress(data.location)
    //   .then((results) => getLatLng(results[0]))
    //   .then(({ lat, lng }) => {
    //     console.log("Successfully got latitude and longitude", { lat, lng });
    //     return { latitude: lat, longitude: lng };
    //   })
    //   .catch((error) => {
    //     console.log("error: ", error);
    //     Common.ErrorAlert(error);
    //   });

    // console.log("latLng: ", latLng);
    try {
      // if (latLng && latLng.latitude != "" && latLng.longitude != "") {
      let cafeData = {
        name: data.name.trimStart().trimEnd(),
        type: data.type,
        description: data.description,
        country_code: data.country_code,
        phone: data.phone,
        city_id: data.city,
        files: files,
        time: data.timeSlots?.map((item) => {
          return {
            ...item,
            open_time: item.open_time
              ? moment(item.open_time).format("HH:mm")
              : "",
            close_time: item.close_time
              ? moment(item.close_time).format("HH:mm")
              : "",
          };
        }),
        country_id: data.country,
        location: data.location,
        latitude: location?.lat,
        longitude: location?.lng,
      };
      const response = await addCafe(cafeData);
      if (response.code === 1) {
        Common.SuccessAlert(response.message);
        setTimeout(() => {
          navigate("/cafe");
        }, 1000);
      } else {
        Common.ErrorAlert(response.message);
      }
      // }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error while adding data:", error);
    }
  };

  const handleCityChange = useCallback(
    (e) => {
      setValue("city", e?._id);
      const city = cityList?.find((item) => item._id === e._id);
      setLocation({
        lat: parseFloat(city?.latitude),
        lng: parseFloat(city?.longitude),
      });
      // setPosition(latLng);
      map.setZoom(10);
      map.panTo({
        lat: parseFloat(city?.latitude),
        lng: parseFloat(city?.longitude),
      });
      setMap(map);
    },
    [cityList, map, setValue]
  );

  const fetchCountryList = async (request) => {
    try {
      const response = await getCountryList(request);

      // Update state with new data and total count
      if (response.code === 1) {
        // console.log("in country list", response.data);
        setCountryList(response.data);
      } else {
        setCountryList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  };

  // Get Listing
  useEffect(() => {
    fetchCountryList({});
  }, []);

  const fetchCityList = async (request) => {
    try {
      // console.log(JSON.parse(request), "rquest in city");
      let req = {
        id: request,
      };
      const response = await getCityList(req);

      // Update state with new data and total count
      if (response.code === 1) {
        // console.log(response.data, "rquest in city");
        setCityList(response.data);
      } else {
        setCityList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  };

  const handleCountryChange = (selectedOptions) => {
    console.log("selectedOptions: ", selectedOptions);
    setValue("country_code", selectedOptions?.phone_code);
  };

  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">
                          {process.env.REACT_APP_NAME}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/cafe">Cafe</Link>
                      </li>
                      <li className="breadcrumb-item active">Add Cafe</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Add Cafe</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="mb-3">
                    <button
                      className="btn btn-custom text-capitalize custom-back"
                      onClick={() => navigate(-1)}
                    >
                      <i className="fe-arrow-left font-28"></i>
                    </button>
                  </div>
                  <div className="card-body mt-2">
                    <div className="row">
                      <form id="add_cafe" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                          <div className="col-lg-3 mb-3">
                            <label htmlFor="type" className="form-label">
                              Type
                            </label>
                            <select
                              className="form-control"
                              id="type"
                              name="type"
                              defaultValue=""
                              {...register("type")}
                            >
                              <option value="">Select Type</option>
                              <option value="Cafe">Cafe</option>
                              <option value="Restaurant">Restaurant</option>
                            </select>

                            <div className="forerror">
                              <span className="validation_error text-justify">
                                {errors.type?.message}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-3">
                            <label htmlFor="name" className="form-label">
                              Name
                            </label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              className="form-control"
                              placeholder="Enter Name"
                              {...register("name")}
                            />
                            <div className="forerror">
                              <span className="validation_error text-justify">
                                {errors.name?.message}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6">
                            <div className="row col-lg-12">
                              <div className="col-lg-4 mb-3">
                                <label
                                  htmlFor="country_code"
                                  className="form-label"
                                >
                                  Country Code
                                </label>
                                <Select
                                  {...register("country_code")}
                                  onChange={handleCountryChange}
                                  options={countryList}
                                  getOptionLabel={(option) =>
                                    option.phone_code + " " + option.name
                                  }
                                  getOptionValue={(option) => option.phone_code}
                                  placeholder="Select Country"
                                />

                                <div className="forerror">
                                  <span className="validation_error text-justify">
                                    {watch("country_code") === "" &&
                                      errors?.country_code?.message}
                                  </span>
                                </div>
                              </div>

                              <div className="col-lg-8 mb-3">
                                <label htmlFor="phone" className="form-label">
                                  Phone
                                </label>
                                <input
                                  type="text"
                                  id="phone"
                                  {...register("phone")}
                                  className="form-control"
                                  placeholder="Phone Number"
                                />
                                <div className="forerror">
                                  <span className="validation_error text-justify">
                                    {errors.phone?.message}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="row col-lg-12">
                              <div className="col-lg-7 mb-2">
                                <label htmlFor="country" className="form-label">
                                  Country
                                </label>
                                <select
                                  className="form-control"
                                  id="country"
                                  name="country"
                                  defaultValue=""
                                  {...register("country")}
                                  onChange={(event) =>
                                    fetchCityList(event.target.value)
                                  }
                                >
                                  <option value="">Select country </option>
                                  {countryList?.map((item, key) => {
                                    return (
                                      <option key={key} value={item.id}>
                                        {" "}
                                        {item.name}{" "}
                                      </option>
                                    );
                                  })}
                                </select>

                                <div className="forerror">
                                  <span className="validation_error text-justify">
                                    {watch("country") === "" &&
                                      errors.country?.message}
                                  </span>
                                </div>
                              </div>

                              {/* <div className="col-lg-5 mb-2">
                                <label htmlFor="city" className="form-label">
                                  City
                                </label>
                                <select
                                  className="form-control"
                                  id="city"
                                  name="city"
                                  defaultValue=""
                                  {...register("city")}
                                  onChange={handleCityChange}
                                >
                                  <option value="">Select city</option>
                                  {cityList?.map((item, key) => {
                                    return (
                                      <option key={key} value={item._id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>

                                <div className="forerror">
                                  <span className="validation_error text-justify">
                                    {errors.city?.message}
                                  </span>
                                </div>
                              </div> */}
                              <div className="col-lg-4 mb-3">
                                <label htmlFor="city" className="form-label">
                                  City
                                </label>
                                <Select
                                  {...register("city")}
                                  onChange={handleCityChange}
                                  options={cityList}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option._id}
                                  placeholder="Select City"
                                />

                                <div className="forerror">
                                  <span className="validation_error text-justify">
                                    {watch("city") === "" &&
                                      errors?.city?.message}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row col-lg-12">
                              <div className="col-lg-12 mb-3">
                                <label
                                  htmlFor="location"
                                  className="form-label"
                                >
                                  Location
                                </label>
                                <input
                                  type="text"
                                  id="location"
                                  disabled={true}
                                  className="form-control"
                                  placeholder="Please select Location into Map"
                                  {...register("location")}
                                />
                                <div className="forerror">
                                  <span className="validation_error text-justify">
                                    {watch("location") === "" &&
                                      errors.location?.message}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <GoogleMapComponent
                              setValue={setValue}
                              location={location}
                              setLocation={setLocation}
                              map={map}
                              setMap={setMap}
                            />
                          </div>
                          <div className="col-lg-6">
                            <div className="row col-lg-12">
                              <div className="col-lg-12 mb-3">
                                <label
                                  htmlFor="description"
                                  className="form-label"
                                >
                                  Description
                                </label>
                                <textarea
                                  type="description"
                                  id="description"
                                  name="description"
                                  rows="5"
                                  className="form-control"
                                  placeholder="description"
                                  {...register("description")}
                                />
                                <div className="forerror">
                                  <span className="validation_error text-justify">
                                    {errors.description?.message}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row col-lg-12 mb-1">
                              <div className="col-lg-3 mb-1">
                                <label>Days</label>
                              </div>
                              <div className="col-lg-4 mb-1">
                                <label>Open Time</label>
                              </div>
                              <div className="col-lg-4 mb-1">
                                <label>Close Time</label>
                              </div>
                            </div>
                            {[
                              "Monday",
                              "Tuesday",
                              "Wednesday",
                              "Thursday",
                              "Friday",
                              "Saturday",
                              "Sunday",
                            ].map((day, index) => (
                              <div key={day} className="row mb-1 col-lg-12">
                                <label className="col-lg-3 mb-1">
                                  {day.charAt(0).toUpperCase() + day.slice(1)}
                                </label>
                                <div className="col-lg-4 mb-1">
                                  {/* <input
                                    {...register(
                                      "timeSlots[" + index + "].open_time"
                                    )}
                                    type="time"
                                    className="form-control"
                                  /> */}

                                  <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                  >
                                    <Controller
                                      name={
                                        "timeSlots[" + index + "].open_time"
                                      }
                                      control={control}
                                      defaultValue={null}
                                      render={({ field }) => (
                                        <div className="w-100 input-group">
                                          <TimePicker
                                            {...field}
                                            sx={{ width: "100%" }}
                                            autoFocus
                                            open={isOpenTimePicker === index}
                                            onClose={() =>
                                              setIsOpenTimePicker()
                                            }
                                            slotProps={{
                                              textField: {
                                                onClick: () =>
                                                  setIsOpenTimePicker(index),
                                              },
                                            }}
                                            ampm={false}
                                            className="custom-time-picker "
                                          />
                                        </div>
                                      )}
                                    />
                                  </LocalizationProvider>
                                  <div className="forerror">
                                    <span className="validation_error text-justify">
                                      {
                                        errors.timeSlots?.[index]?.open_time
                                          ?.message
                                      }
                                    </span>
                                  </div>
                                </div>
                                <div className="col-lg-4 mb-1">
                                  {/* <input
                                    {...register(
                                      "timeSlots[" + index + "].close_time"
                                    )}
                                    type="time"
                                    className="form-control"
                                  /> */}
                                  <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                  >
                                    <Controller
                                      name={
                                        "timeSlots[" + index + "].close_time"
                                      }
                                      control={control}
                                      defaultValue={null}
                                      render={({ field }) => (
                                        <div className="w-100 input-group">
                                          <TimePicker
                                            {...field}
                                            sx={{ width: "100%" }}
                                            autoFocus
                                            open={isCloseTimePicker === index}
                                            onClose={() =>
                                              setIsCloseTimePicker()
                                            }
                                            slotProps={{
                                              textField: {
                                                onClick: () =>
                                                  setIsCloseTimePicker(index),
                                              },
                                            }}
                                            ampm={false}
                                            minTime={getValues(
                                              "timeSlots[" +
                                                index +
                                                "].open_time"
                                            )}
                                            // maxTime={moment(
                                            //   timeLimit?.close_time,
                                            //   "HH:mm"
                                            // )}
                                            className="custom-time-picker "
                                          />
                                        </div>
                                      )}
                                    />
                                  </LocalizationProvider>
                                  <div className="forerror">
                                    <span className="validation_error text-justify">
                                      {
                                        errors.timeSlots?.[index]?.close_time
                                          ?.message
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <label for="example-fileinput" class="form-label">
                              Image
                            </label>
                            <input
                              type="file"
                              id="example-fileinput"
                              class="form-control"
                              accept="image/*"
                              multiple
                              onChange={onSelectFile}
                            />

                            <div className="forerror">
                              <span className="validation_error ">
                                {" "}
                                {errors.images && (
                                  <div className="validation_error">
                                    {errors.images.message}
                                  </div>
                                )}
                              </span>
                            </div>
                            <div
                              className="col-md-12 mb-3 mt-2"
                              style={{ overflow: "auto" }}
                            >
                              <div className="preview-container">
                                {selectedImages.map((item, index) => (
                                  <div
                                    key={index}
                                    className={
                                      index === 0
                                        ? "first-d-preview"
                                        : "other-d-preview"
                                    }
                                  >
                                    <img
                                      src={item.image}
                                      alt={`Preview ${index}`}
                                      className="preview-image"
                                    />
                                    <button
                                      type="button"
                                      className="delete-img-button"
                                      onClick={() => deleteHandler(item)}
                                    >
                                      <i className="mdi mdi-delete font-22 avatar-title new-text-danger"></i>
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>

                          {/* Video Upload Section */}
                          <div className="col-lg-6 mb-3">
                            <label for="example-fileinput" class="form-label">
                              Videos
                            </label>
                            <input
                              type="file"
                              id="example-fileinput"
                              class="form-control"
                              accept="video/*"
                              multiple
                              onChange={onSelectVideoFile}
                            />
                            <div className="forerror">
                              <span className="validation_error ">
                                {" "}
                                {errors.videos && (
                                  <div className="validation_error">
                                    {errors.videos.message}
                                  </div>
                                )}
                              </span>
                            </div>
                            <div
                              className="col-md-12 mb-3 mt-2"
                              style={{ overflow: "auto" }}
                            >
                              <div className="preview-container">
                                {selectedVideos.map((item, index) => (
                                  <div
                                    key={index}
                                    className={
                                      index === 0
                                        ? "first-d-preview"
                                        : "other-d-preview"
                                    }
                                  >
                                    <video
                                      controls
                                      width="150"
                                      height="150"
                                      className="preview-video"
                                      style={{ objectFit: "cover" }}
                                    >
                                      <source
                                        src={item.video}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>

                                    <button
                                      type="button"
                                      className="delete-img-button"
                                      onClick={() => deleteHandlerVideo(item)}
                                    >
                                      <i className="mdi mdi-delete font-22 avatar-title new-text-danger"></i>
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-lg-6  offset-md-5">
                            <button
                              type="submit"
                              className="btn btn-raised btn-custom  waves-effect me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              className="btn btn-cancel waves-effect waves-light"
                              onClick={() => navigate(-1)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
