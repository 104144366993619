import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import * as Common from "../../utils/Common";
import GLOBALS from "../../utils/Constant";
import {
  getVirtualEventList,
  virtualEventDelete,
  statusVirtualEvent,
} from "../../Api/apiHandler";
import Select from "react-select";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment-timezone";

function VirtualListing() {
  const [vevent, setVevent] = useState([]);
  const [eventStatus, setEventStatus] = useState(null);
  function clearFilter() {
    // Common.processing();
    setTimeout(() => {
      //     Swal.close()
      setEventStatus(null);
    }, 1000);
  }
  const handleStatusChange = (selectedOption) => {
    console.log("selectedOption: ", selectedOption);
    setEventStatus(selectedOption);
  };
  const getvirtualeventlist = async (request) => {
    try {
      const response = await getVirtualEventList(request);

      if (response.code === 1) {
        console.log("response :", response.data);
        Common.SuccessAlert(response.message);
        setVevent(response.data);
      } else {
        console.log("response notification code zero :", response);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  };

  const deletevirtuEvent = (id) => {
    Swal.fire({
      title: "Delete",
      text: "Are you sure you want to delete?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await virtualEventDelete({ id: id });

          if (response.code === 1) {
            Common.SuccessAlert(response.message);
            getvirtualeventlist({});
          } else {
            Common.ErrorAlert(response.message);
          }
        } catch (error) {
          console.error("Error in remove cafe:", error);
          Common.ErrorAlert("Failed to change status");
        }
      }
    });
  };

  const changeState = (id, state) => {
    Swal.fire({
      title: "Change Status",
      text: "Are you sure you want to change status?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let params = {
          id: id,
          is_active: state,
        };
        try {
          const response = await statusVirtualEvent(params);
          if (response.code === 1) {
            Common.SuccessAlert(response.message);
            getvirtualeventlist({});
          } else {
            Common.ErrorAlert(response.message);
          }
        } catch (error) {
          console.error("Error in changeCafeStatus:", error);
          Common.ErrorAlert("Failed to change status");
        }
      }
    });
  };

  //Columns for datagrid table
  const columns = [
    { field: "id", headerName: "ID", flex: 1, minWidth: 225 },
    { field: "name", headerName: "Event Name", flex: 1, minWidth: 150 },
    { field: "country_id", headerName: "Country", flex: 1, minWidth: 100 },
    {
      field: "event_start_time",
      headerName: "Start Time",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "event_timezone",
      headerName: "Time Zone",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      sortable: false,
      renderCell: (params) =>
        params.row.is_active === 0 ? (
          <span
            className={
              params.row.status_not_editable
                ? "badge bg-danger new-badge disabled"
                : "badge bg-danger new-badge"
            }
            onClick={() =>
              !params.row.status_not_editable &&
              changeState(params.row._id, "1")
            }
          >
            Inactive
          </span>
        ) : (
          <span
            className={
              params.row.status_not_editable
                ? "badge bg-success new-badge disabled"
                : "badge bg-success new-badge"
            }
            onClick={() =>
              !params.row.status_not_editable &&
              changeState(params.row._id, "0")
            }
          >
            Active
          </span>
        ),
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 125,
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <>
          <Link
            to={`/event/virtual/edit/${btoa(params.row._id)}`}
            title="Edit"
            className="action-icon"
          >
            {" "}
            <i className="mdi mdi-lead-pencil mdi-19px text-warning"></i>
          </Link>

          <span
            onClick={() => deletevirtuEvent(params.row._id)}
            className="action-icon action-button"
          >
            {" "}
            <i className="mdi mdi-delete text-danger mdi-19px"></i>
          </span>
        </>
      ),
    },
  ];

  //Rows for datagrid table
  const rows = vevent?.map((item, index) => ({
    ...item,
    id: item._id,
    country_id: item.country[0].name,
    status_not_editable:
      moment()
        .tz(item.event_timezone)
        .isAfter(
          moment(item.event_start_time, "HH:mm").clone().subtract(1, "hours")
        ) &&
      moment()
        .tz(item.event_timezone)
        .isBefore(
          moment(item.event_start_time, "HH:mm").clone().add(1, "hours")
        ),
  }));

  const exportExcel = () => {
    let columns = [
      {
        header: "ID",
        key: "id",
        width: 25,
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      },
      { header: "Event Name", key: "name", width: 25, horizontal: "center" },
      { header: "Country", key: "country_id", width: 25, horizontal: "center" },
      {
        header: "Start Time",
        key: "event_start_time",
        width: 20,
        horizontal: "center",
      },
      {
        header: "Time Zone",
        key: "event_timezone",
        width: 20,
        horizontal: "center",
      },
      {
        header: "Status",
        key: "status",
        width: 20,
        horizontal: "center",
      },
    ];

    const ExportData = rows?.map((row) => {
      return {
        id: row._id,
        phone: "+" + row.country_code + " " + row.phone,
        status: row.is_active === 0 ? "Inactive" : "Active",
        ...row,
      };
    });

    const fileName = `Virtual_Event_Report_` + moment().format("DDMMYYYYhmmss");

    Common.exportExcelFile(columns, ExportData, fileName);
  };

  useEffect(() => {
    let filter_params = {
      is_active: eventStatus?.value?.toString(),
    };
    getvirtualeventlist(filter_params);
  }, [eventStatus?.value]);

  return (
    <>
      <div className="content-page">
        <div className="content">
          {/* <!-- Start Content--> */}
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Virtual Event</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Virtual Event</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                {/* <!-- Portlet card --> */}
                <div className="card">
                  <Link
                    to="#cardCollpase4"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="true"
                    aria-controls="cardCollpase2"
                    className=""
                  >
                    <div className="card-header bg-app text-white">
                      <div className="card-widgets">
                        {/* <Link to="/#" data-toggle="reload" onClick={() => clearFilter()}><i className="mdi mdi-refresh"></i></Link>

                                                <Link to="#cardCollpase4" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="cardCollpase2" className="" >
                                                    <i className="mdi mdi-minus"></i>
                                                </Link> */}
                      </div>
                      <h5 className="card-title mb-0 text-white">Filter</h5>
                    </div>
                  </Link>

                  <div id="cardCollpase4" className="collapse show">
                    <div className="card-body">
                      <div className="row">
                        {/* is active status */}
                        <div className="col-md-2 mb-2">
                          <label className="form-label">Status</label>

                          <Select
                            value={eventStatus}
                            onChange={handleStatusChange}
                            options={GLOBALS.statusOption}
                            placeholder="Select Status"
                            isSearchable={true} // Enables search functionality
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-auto">
                          <div className="text-lg-end my-1 my-lg-0">
                            <button
                              type="button"
                              onClick={() => clearFilter()}
                              className="btn btn-warning waves-effect waves-light me-1"
                            >
                              Clear
                            </button>
                            <button
                              type="button"
                              onClick={() => exportExcel()}
                              className="btn btn-success waves-effect waves-light me-1"
                            >
                              Export
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- end card--> */}
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    {/* <div className="col-md-12 mb-3">
                      <Link to={`/event/createvirtual`} title="Add">
                        <button
                          type="button"
                          className="btn btn-custom waves-effect waves-light mr-1"
                        >
                          Create Virtual Event
                        </button>
                      </Link>
                    </div> */}

                    {vevent && (
                      <DataGrid
                        columns={columns}
                        rows={rows}
                        rowHeight={80}
                        disableColumnFilter
                        disableDensitySelector
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true },
                          },
                        }}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                          },
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                      />
                    )}
                  </div>
                  {/* <!-- end card body--> */}
                </div>
                {/* <!-- end card --> */}
              </div>
              {/* <!-- end col--> */}
            </div>
            {/* <!-- end row--> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default VirtualListing;
