import { useEffect, React, useState } from "react";
import { login } from "../../Api/apiHandler";
import { useNavigate, Link } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import * as Common from "../../utils/Common";
import GLOBALS from "../../utils/Constant";

export default function Login() {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [isChecked, setIsChecked] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies([
    "remember_email",
    "remember_pwd",
    "remember_me",
  ]);

  //For checked in remember me
  useEffect(() => {
    if (cookies.remember_me !== undefined) {
      setIsChecked(true);
    }
  }, [cookies.remember_me]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (data) => {
    let params = {
      email: data.email,
      password: data.password,
    };

    // For check login API
    const checkLogin = async () => {
      try {
        const response = await login(params);

        if (response.code === 1) {
          if (data.rememberme || cookies.remember_me !== undefined) {
            // Set cookies
            setCookie("remember_email", data.email, { maxAge: 86400 });
            setCookie("remember_pwd", data.password, { maxAge: 86400 });
            setCookie("remember_me", data.rememberme, { maxAge: 86400 });
          } else {
            // Remove cookies
            removeCookie("remember_email");
            removeCookie("remember_pwd");
            removeCookie("remember_me");
          }

          Common.SuccessAlert(response.message);
          Common.loginStoreData(response.data);
          window.location.href = "/dashboard";
        } else {
          Common.ErrorAlert(response.message);
        }
      } catch (error) {
        Common.ErrorAlert("Oops! Something went wrong");
        console.error("Error fetching data:", error);
      }
    };

    checkLogin();
  };

  //To handle checkbox check and unchecked event
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(()=>{
    const auth = sessionStorage.getItem("AdminToken");
    auth && navigate('/dashboard')
  },[navigate])

  return (
    <>
      <div className="loading authentication-bg authentication-bg-pattern">
        <div className="account-pages mb-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-4 mt-7">
                <div className="card bg-pattern">
                  <div className="card-body p-4">
                    <div className="text-center w-75 m-auto">
                      <div className="auth-logo">
                        {/* <a href="index.html" className="logo logo-dark text-center">
                                                    <span className="logo-lg">
                                                        <img src="../assets/images/logo-dark.png" alt="" height="22" />
                                                    </span>
                                                </a>

                                                <a href="index.html" className="logo logo-light text-center">
                                                    <span className="logo-lg">
                                                        <img src="../assets/images/logo-light.png" alt="" height="22" />
                                                    </span>
                                                </a> */}

                        <Link className="logo logo-dark text-center">
                          <span className="logo-lg">
                            <img
                              src={process.env.PUBLIC_URL + GLOBALS.LogoURL}
                              alt={GLOBALS.APP_NAME}
                              className="logo-img"
                            />
                          </span>
                          <h1>{GLOBALS.APP_NAME}</h1>
                        </Link>
                      </div>
                      <p className="text-muted mb-4 mt-3">
                        Enter your email address and password to access admin
                        panel.
                      </p>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="mb-3">
                        <label htmlFor="emailaddress" className="form-label">
                          Email address
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          id="emailaddress"
                          required=""
                          placeholder="Enter your email"
                          defaultValue={cookies.remember_email}
                          {...register("email")}
                        />
                        <div className="forerror">
                          <span className="validation_error text-justify">
                            {errors.email?.message}
                          </span>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            className="form-control"
                            defaultValue={cookies.remember_pwd}
                            placeholder="Enter your password"
                            {...register("password")}
                          />
                          <div
                            className={`input-group-text ${
                              showPassword ? "show-password" : ""
                            }`}
                            data-password={showPassword}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <span className="password-eye"></span>
                          </div>
                        </div>
                        <div className="forerror">
                          <span className="validation_error text-justify">
                            {errors.password?.message}
                          </span>
                        </div>
                      </div>

                      <input
                        type="hidden"
                        name="timezone"
                        id="timezone"
                        defaultValue={userTimezone}
                        {...register("timezone")}
                      />

                      <div className="mb-3">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="rememberme"
                            id="rememberme"
                            checked={isChecked}
                            onClick={handleCheckboxChange}
                            {...register("rememberme")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkbox-signin"
                          >
                            Remember me
                          </label>
                        </div>
                      </div>

                      <div className="text-center d-grid">
                        <button className="btn btn-primary" type="submit">
                          {" "}
                          Log In{" "}
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* <!-- end card-body --> */}
                </div>
                {/* <!-- end card --> */}
                {/* <div className="row mt-3">
                            <div className="col-12 text-center">
                                <p> <a href="auth-recoverpw.html" className="text-white-50 ms-1">Forgot your password?</a></p>
                                <p className="text-white-50">Don't have an account? <a href="auth-register.html" className="text-white ms-1"><b>Sign Up</b></a></p>
                            </div>  */}
                {/* <!-- end col --> */}
                {/* </div> */}
                {/* <!-- end row --> */}
              </div>
              {/* <!-- end col --> */}
            </div>
            {/* <!-- end row --> */}
          </div>
          {/* <!-- end container --> */}
        </div>
      </div>
    </>
  );
}
