import React from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { sendPushNotification } from '../../Api/apiHandler';
import { Link, useNavigate } from 'react-router-dom';
import * as Common from '../../utils/Common';

function Add() {

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        // files , time
        tag: Yup.string().required("Please enter Tag"),
        message: Yup.string().required("Please enter Message"),

    });
    const { handleSubmit, register, formState } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const { errors } = formState;

    const onSubmit = async (data) => {

        let notifyData = {
            tag: data.tag,
            message: data.message
        }
        console.log('notifyData :', notifyData);

        try {
            const response = await sendPushNotification(notifyData);
            if (response.code === 1) {
                console.log('response :', response);
                Common.SuccessAlert(response.message)
                setTimeout(() => {
                    navigate("/notification")
                }, 1000)
            } else {
                Common.ErrorAlert(response.message);
            }
        } catch (error) {
            Common.ErrorAlert('Oops! Something went wrong');
            console.error('Error while adding data:', error);
        }
    }


    return (
        <>
            <div className="content-page">
                <div className="content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box">
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><Link to='/dashboard'>{process.env.REACT_APP_NAME}</Link></li>
                                            <li className="breadcrumb-item"><Link to='/cafe'>Notification</Link></li>
                                            <li className="breadcrumb-item active">Send Notification</li>
                                        </ol>
                                    </div>
                                    <h4 className="page-title">Send Notification</h4>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <div className="card">
                                    <div className="mb-3">
                                        <button className="btn btn-custom text-capitalize custom-back" onClick={() => navigate(-1)}><i className="fe-arrow-left font-28"></i></button>
                                    </div>
                                    <div className="card-body mt-2">

                                        <div className="row">

                                            <form id='add_cafe' onSubmit={handleSubmit(onSubmit)}>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-12">
                                                        <label htmlFor="tag" className="form-label">Tag</label>
                                                        <input type="text" id="tag" name='tag' className="form-control" placeholder="Type Tag" {...register('tag')} />
                                                        <div className="forerror">
                                                            <span className="validation_error text-justify">{errors.tag?.message}</span>
                                                        </div>
                                                        <br />
                                                    </div>
                                                    <div className="col-lg-12 mb-12">
                                                        <label htmlFor="message" className="form-label">Message</label>
                                                        <input type="text" id="message" name='message' className="form-control" placeholder="Type Message" {...register('message')} />
                                                        <div className="forerror">
                                                            <span className="validation_error text-justify">{errors.message?.message}</span>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="row mt-3">
                                                    <div className="col-lg-6  offset-md-5">
                                                        <button type="submit" className="btn btn-raised btn-custom  waves-effect me-2">
                                                            Submit
                                                        </button>
                                                        <button type="button" className="btn btn-cancel waves-effect waves-light" onClick={() => navigate(-1)}>
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default Add
