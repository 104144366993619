import React, { useEffect, useState } from "react";
import { getUserDetails } from "../../Api/apiHandler";
import { useParams, Link, useNavigate } from "react-router-dom";
import GLOBALS from "../../utils/Constant";
import * as Common from "../../utils/Common";

export default function View() {
  let { id } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [preferenceInfo, setPreferenceInfo] = useState(null);
  const [reportUsers, setReportUsers] = useState(null);
  const [usersFeedback, setUsersFeedback] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async (request) => {
      try {
        if (id !== undefined) {
          const response = await getUserDetails(request);

          if (response.code === 1) {
            console.log(response.data, "response.data user details");
            setUserDetails(response.data);
            setPreferenceInfo(response.data.preference_info);
            setReportUsers(response.data.reportUserList);
            setUsersFeedback(response.data.feedbackUserList);
          }
        }
      } catch (error) {
        Common.ErrorAlert("Oops! Something went wrong");
        console.error("Error fetching data:", error);
      }
    };

    fetchUserDetails({ userid: atob(id) });
  }, [id]);

  function formatBirthDate(birthDate) {
    if (!birthDate) return ""; // Handle cases where birthDate is undefined or null

    const dateObject = new Date(birthDate);
    const formattedDate = dateObject.toLocaleDateString("en-US"); // Adjust the locale as needed

    return formattedDate;
  }

  if (userDetails == null) return <> </>;
  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">
                          {process.env.REACT_APP_NAME}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link to="/user">User</Link>
                      </li>
                      <li className="breadcrumb-item active">View User</li>
                    </ol>
                  </div>
                  <h4 className="page-title">View User</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="mb-3">
                    <button
                      className="btn btn-custom text-capitalize custom-back"
                      onClick={() => navigate(-1)}
                    >
                      <i className="fe-arrow-left font-28"></i>
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="row mb-3 text-center">
                      <div className="text-center">
                        <img
                          src={userDetails?.profile_image}
                          className="rounded-circle avatar-xxl img-thumbnail"
                          alt="profileImage"
                        />
                      </div>
                      <div className="text-center mt-2">
                        <span className="mb-0"> {userDetails?.bio} </span>
                      </div>
                    </div>

                    <div className="row">
                      <ul className=" nav nav-tabs nav-bordered nav-justified">
                        <li className="nav-item">
                          <a
                            href="#user_details"
                            data-bs-toggle="tab"
                            aria-expanded="false"
                            className="nav-link active"
                          >
                            User Details
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#preference_info"
                            data-bs-toggle="tab"
                            aria-expanded="true"
                            className="nav-link"
                          >
                            Preference Info
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#reported_users"
                            data-bs-toggle="tab"
                            aria-expanded="true"
                            className="nav-link"
                          >
                            Reported Users
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#user_feedback"
                            data-bs-toggle="tab"
                            aria-expanded="true"
                            className="nav-link"
                          >
                            User's Feedback
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="user_details">
                          <div className="row mx-3">
                            <div className="col-lg-6 ">
                              <div className="text-start">
                                <p className="mb-2 font-15">
                                  <strong>Full Name :</strong>{" "}
                                  <span className="ms-2">
                                    {userDetails?.first_name}{" "}
                                    {userDetails?.last_name}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Mobile :</strong>
                                  <span className="ms-2">
                                    {userDetails?.country_code}{" "}
                                    {userDetails?.mobile_number}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Birth Date & Time:</strong>
                                  <span className="ms-2">
                                    {formatBirthDate(userDetails?.birth_date)}{" "}
                                    {userDetails?.birth_time}
                                  </span>
                                </p>

                                {/* <p className="mb-2 font-15"><strong>Login Status :</strong> <span className="ms-2">{userDetails?.login_status}</span></p> */}
                                <p className="mb-2 font-15">
                                  <strong>Country :</strong>{" "}
                                  <span className="ms-2">
                                    {userDetails?.country}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Nationality :</strong>{" "}
                                  <span className="ms-2">
                                    {userDetails?.nationality}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Zodiac Sign :</strong>{" "}
                                  <span className="ms-2">
                                    {userDetails?.zodiac_sign}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Employment Status :</strong>{" "}
                                  <span className="ms-2">
                                    {userDetails?.employment_status}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Parenthood Status :</strong>{" "}
                                  <span className="ms-2">
                                    {userDetails?.parenthood_status}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Height :</strong>{" "}
                                  <span className="ms-2">
                                    {userDetails?.height}{" "}
                                    {userDetails?.height_unit}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Status :-</strong>
                                  <span className="ms-2">
                                    {userDetails.is_active === 1 ? (
                                      <span className="p-1 badge square-pill bg-success">
                                        {" "}
                                        Active
                                      </span>
                                    ) : (
                                      <span className="p-1 badge square-pill bg-danger">
                                        {" "}
                                        Inactive
                                      </span>
                                    )}
                                  </span>
                                </p>
                                <p className="mb-2 font-15">
                                  <strong>Hobbies :</strong>{" "}
                                  <span className="ms-2">
                                    {" "}
                                    {userDetails?.hobbies.map((hobby) => (
                                      <span key={hobby._id}>
                                        {hobby.name},{" "}
                                      </span>
                                    ))}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-6 ">
                              <div className="text-start">
                                <p className="mb-2 font-15">
                                  <strong>Gender :</strong>{" "}
                                  <span className="ms-2">
                                    {userDetails?.gender === "man"
                                      ? "Male"
                                      : userDetails?.gender === "women"
                                      ? "Female"
                                      : userDetails?.gender === "non-binary"
                                      ? "Non-binary"
                                      : "Unknown"}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Email :</strong>{" "}
                                  <span className="ms-2">
                                    {userDetails?.email}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Age :</strong>{" "}
                                  <span className="ms-2">
                                    {userDetails?.age}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>City :</strong>{" "}
                                  <span className="ms-2">
                                    {userDetails?.city}
                                  </span>
                                </p>

                                {/* <p className="mb-2 font-15"><strong>Login Status :</strong> <span className="ms-2">{userDetails?.login_status}</span></p> */}

                                {/* <p className="mb-2 font-15"><strong>Register Date :-</strong> <span className="ms-2">{moment(userDetails?.created_at).format(GLOBALS.FullDateTimeFormat)}</span></p> */}
                                <p className="mb-2 font-15">
                                  <strong>Language :</strong>{" "}
                                  <span className="ms-2">
                                    {" "}
                                    {userDetails?.languages.map((language) => (
                                      <span key={language._id}>
                                        {language.name},{" "}
                                      </span>
                                    ))}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Animal Sign :</strong>{" "}
                                  <span className="ms-2">
                                    {userDetails?.animal_sign}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Education Status :</strong>{" "}
                                  <span className="ms-2">
                                    {userDetails?.education}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Religion :</strong>{" "}
                                  <span className="ms-2">
                                    {userDetails?.religion}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Mbti :</strong>{" "}
                                  <span className="ms-2">
                                    {userDetails?.mbti_type}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Register Date :-</strong>{" "}
                                  <span className="ms-2">
                                    {Common.formatDateToTimezone(
                                      userDetails?.created_at,
                                      GLOBALS.SHORT_DATE
                                    )}
                                  </span>
                                </p>

                                {/* <p className="mb-2 font-15"><strong>Status :-</strong>
                                  <span className="ms-2">
                                    {
                                      (userDetails.is_active == 1) ?
                                        <span className='p-1 badge square-pill bg-success' > Active</span> :
                                        <span className='p-1 badge square-pill bg-danger' > Inactive</span>
                                    }
                                  </span>
                                </p> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane" id="preference_info">
                          <div className="row mx-3">
                            <div className="col-lg-6 ">
                              <div className="text-start">
                                <p className="mb-2 font-15">
                                  <strong>Min Age :</strong>{" "}
                                  <span className="ms-2">
                                    {preferenceInfo?.preferred_min_age}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Min Height :</strong>
                                  <span className="ms-2">
                                    {preferenceInfo?.preferred_min_height}{" "}
                                    {preferenceInfo?.preferred_height_unit}{" "}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Interested In :</strong>
                                  <span className="ms-2">
                                    {" "}
                                    {preferenceInfo?.interested_in?.join(", ")}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Employment Status :</strong>{" "}
                                  <span className="ms-2">
                                    {" "}
                                    {preferenceInfo?.preference_employment
                                      ?.filter(
                                        (employment) => employment !== null
                                      )
                                      .map((employment, index) => (
                                        <span key={employment?._id}>
                                          {employment?.type}
                                          {index !==
                                            preferenceInfo.preference_employment
                                              .length -
                                              1 && ", "}
                                        </span>
                                      ))}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Religion :</strong>{" "}
                                  <span className="ms-2">
                                    {" "}
                                    {preferenceInfo?.preference_religion
                                      ?.filter((religion) => religion !== null)
                                      .map((religion, index) => (
                                        <span key={religion._id}>
                                          {religion.name}
                                          {index !==
                                            preferenceInfo.preference_religion
                                              .length -
                                              1 && ", "}
                                        </span>
                                      ))}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-6 ">
                              <div className="text-start">
                                <p className="mb-2 font-15">
                                  <strong>Min Age :</strong>{" "}
                                  <span className="ms-2">
                                    {preferenceInfo?.preferred_max_age}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Max Height :</strong>
                                  <span className="ms-2">
                                    {preferenceInfo?.preferred_max_height}{" "}
                                    {preferenceInfo?.preferred_height_unit}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Nationality :</strong>{" "}
                                  <span className="ms-2">
                                    {" "}
                                    {preferenceInfo?.preference_nationality
                                      ?.filter(
                                        (nationality) => nationality !== null
                                      )
                                      .map((nationality, index) => (
                                        <span key={nationality._id}>
                                          {nationality.name}
                                          {index !==
                                            preferenceInfo
                                              .preference_nationality.length -
                                              1 && ", "}
                                        </span>
                                      ))}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Parenthood Status :</strong>{" "}
                                  <span className="ms-2">
                                    {" "}
                                    {preferenceInfo?.preferred_parenthood_status
                                      ?.filter(
                                        (parenthood) => parenthood !== null
                                      )
                                      .map((parenthood, index) => (
                                        <span key={parenthood._id}>
                                          {parenthood.name}
                                          {index !==
                                            preferenceInfo
                                              .preferred_parenthood_status
                                              .length -
                                              1 && ", "}
                                        </span>
                                      ))}
                                  </span>
                                </p>

                                <p className="mb-2 font-15">
                                  <strong>Mbti Type :</strong>{" "}
                                  <span className="ms-2">
                                    {" "}
                                    {preferenceInfo?.preferred_mbti_type
                                      ?.filter((mbt) => mbt !== null)
                                      .map((mbt, index) => (
                                        <span key={mbt._id}>
                                          {mbt.type}
                                          {index !==
                                            preferenceInfo.preferred_mbti_type
                                              .length -
                                              1 && ", "}
                                        </span>
                                      ))}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane" id="reported_users">
                          <div className="row mx-3">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Reported User</th>
                                  <th>Reason</th>
                                  <th>Description</th>
                                  <th>Dating Type</th>
                                  <th>Created At</th>
                                </tr>
                              </thead>
                              <tbody>
                                {reportUsers.map((user) => (
                                  <tr key={user._id}>
                                    <td>{user.reported_user}</td>
                                    <td>{user.reason}</td>
                                    <td>{user.description}</td>
                                    <td>{user.dating_type}</td>
                                    <td>
                                      {Common.formatDateToTimezone(
                                        user.created_at,
                                        GLOBALS.SHORT_DATE
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="tab-pane" id="user_feedback">
                          <div className="row mx-3">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>User</th>
                                  <th>Feedback</th>
                                  <th>Description</th>
                                  <th>Dating Type</th>
                                  <th>Created At</th>
                                </tr>
                              </thead>
                              <tbody>
                                {usersFeedback.map((user) => (
                                  <tr key={user._id}>
                                    <td>{user.feedback_user}</td>
                                    <td>{user.reason}</td>
                                    <td>{user.description}</td>
                                    <td>{user.dating_type}</td>
                                    <td>
                                      {Common.formatDateToTimezone(
                                        user.created_at,
                                        GLOBALS.SHORT_DATE
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
