import React, { useState, useEffect } from "react";
import { getDashboardCount } from "../../Api/apiHandler";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { Link } from "react-router-dom";
import GLOBALS from "../../utils/Constant";

export default function Dashboard() {
  const [dashboardCount, setDashboardCount] = useState(null);
  const [dates, setDatesState] = useState({
    startDate: moment("2023-10-22"),
    endDate: moment(),
  });

  const adminData = JSON.parse(sessionStorage.getItem("AdminData"));
  let permission = adminData.permissions;

  const setDates = (e, { startDate, endDate }) => {
    setDatesState({
      startDate,
      endDate,
    });
  };

  //UserEffect For Filter
  useEffect(() => {
    let filter_params = {
      start_date: moment(dates.startDate).format("YYYY-MM-DD"),
      end_date: moment(dates.endDate).format("YYYY-MM-DD"),
    };

    // For fetch country list API
    const fetchUserList = async (request) => {
      // console.log('request: ', request);
      try {
        const response = await getDashboardCount(request);

        if (response.code === 1) {
          // console.log('response.data: ', response.data);
          setDashboardCount(response.data);
        }
      } catch (error) {
        // Common.ErrorAlert('Oops! Something went wrong');
        console.error("Error fetching data:", error);
      }
    };

    fetchUserList(filter_params);
  }, [dates]);

  function clearFilter() {
    setDatesState({
      startDate: moment().subtract(6, "days"),
      endDate: moment(),
    });
  }

  return (
    <>
      {/* ================================ Start Page Content ================================== */}
      <div className="content-page">
        <div className="content">
          {/* <!-- Start Content--> */}
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div class="page-title-right">
                    <div class="d-flex align-items-center mb-3">
                      <div class="input-group input-group-sm">
                        <DateRangePicker
                          // onEvent={(e, { startDate, endDate }) => {
                          //   dates.startDate === "" && setDates
                          // }}

                          onApply={setDates}
                          initialSettings={{
                            startDate: dates?.startDate?.format("MM/DD/YYYY"),
                            endDate: dates?.endDate?.format("MM/DD/YYYY"),
                            ranges: GLOBALS.datePickerRanges,
                          }}
                        >
                          <input
                            type="text"
                            value={
                              dates.startDate !== ""
                                ? moment(dates.startDate).format(
                                    "MMMM DD, YYYY"
                                  ) +
                                  " - " +
                                  moment(dates.endDate).format("MMMM DD, YYYY")
                                : "Select Period"
                            }
                            className="form-control w-15vw"
                            onChange={() => {
                              console.log(`changes`);
                            }}
                          />
                        </DateRangePicker>
                        <span class="input-group-text bg-blue border-blue text-white">
                          <i class="mdi mdi-calendar-range"></i>
                        </span>
                      </div>
                      <button
                        onClick={() => clearFilter()}
                        class="btn btn-blue btn-sm ms-2"
                      >
                        <i class="mdi mdi-autorenew"></i>
                      </button>
                    </div>
                  </div>
                  <div>
                    <h4 className="page-title">Dashboard</h4>
                    {/* <small className="text-muted">Welcome to {process.env.REACT_APP_NAME} Application</small> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}

            <div className="row">
              {sessionStorage.getItem("AdminRole") === "1" ||
              (sessionStorage.getItem("AdminRole") === "0" &&
                permission.includes("User")) ? (
                <div className="col-md-6 col-xl-3">
                  <Link to="/user">
                    <div className="widget-rounded-circle card new-cards zoomInRight animated">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6">
                            <div className="avatar-lg rounded-circle bg-soft-info border-info border">
                              <i className="fe-user font-22 avatar-title text-info"></i>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="text-end">
                              <h3 className="text-dark mt-1">
                                <span data-plugin="counterup">
                                  {dashboardCount?.user_count}
                                </span>
                              </h3>
                              <p className="text-muted mb-1 text-truncate">
                                Total User
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <!-- end row--> */}
                      </div>
                    </div>
                    {/* <!-- end widget-rounded-circle--> */}
                  </Link>
                </div>
              ) : null}

              {sessionStorage.getItem("AdminRole") === "1" ||
              (sessionStorage.getItem("AdminRole") === "0" &&
                permission.includes("Cafe")) ? (
                <div className="col-md-6 col-xl-3">
                  <Link to="/cafe">
                    <div className="widget-rounded-circle card new-cards zoomInRight animated">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6">
                            <div className="avatar-lg rounded-circle bg-soft-warning border-warning border">
                              <i className="fe-map font-22 avatar-title text-warning"></i>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="text-end">
                              <h3 className="text-dark mt-1">
                                <span data-plugin="counterup">
                                  {dashboardCount?.cafe_count}
                                </span>
                              </h3>
                              <p className="text-muted mb-1 text-truncate">
                                Total Cafe/Restaurant
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ) : null}

              {sessionStorage.getItem("AdminRole") === "1" ||
              (sessionStorage.getItem("AdminRole") === "0" &&
                permission.includes("Contact Us")) ? (
                <div className="col-md-6 col-xl-3">
                  <Link to="/contact-us">
                    <div className="widget-rounded-circle card new-cards zoomInRight animated">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6">
                            <div className="avatar-lg rounded-circle bg-soft-success border-success border">
                              <i className="fe-box font-22 avatar-title text-success"></i>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="text-end">
                              <h3 className="text-dark mt-1">
                                <span data-plugin="counterup">
                                  {dashboardCount?.contactus_count}
                                </span>
                              </h3>
                              <p className="text-muted mb-1 text-truncate">
                                Total Contact Us
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ) : null}

              {sessionStorage.getItem("AdminRole") === "1" ||
              (sessionStorage.getItem("AdminRole") === "0" &&
                permission.includes("Sub Admin")) ? (
                <div className="col-md-6 col-xl-3">
                  <Link to="/subAdmin">
                    <div className="widget-rounded-circle card new-cards zoomInRight animated">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6">
                            <div className="avatar-lg rounded-circle bg-soft-danger border-danger border">
                              <i className="fe-edit font-22 avatar-title text-danger"></i>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="text-end">
                              <h3 className="text-dark mt-1">
                                <span data-plugin="counterup">
                                  {dashboardCount?.admin_count}
                                </span>
                              </h3>
                              <p className="text-muted mb-1 text-truncate">
                                Total Sub Admin
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ) : null}

              {sessionStorage.getItem("AdminRole") === "1" ||
              (sessionStorage.getItem("AdminRole") === "0" &&
                permission.includes("CMS")) ? (
                <div className="col-md-6 col-xl-3 d-flex flex-column">
                  <Link to="/faq" className="flex-grow-1 d-flex flex-column">
                    <div className="widget-rounded-circle card new-cards zoomInRight animated flex-grow-1 ">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6">
                            <div className="avatar-lg rounded-circle bg-soft-primary border-primary border">
                              <i className="fe-menu font-22 avatar-title text-primary"></i>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="text-end">
                              <h3 className="text-dark mt-1">
                                <span data-plugin="counterup">
                                  {dashboardCount?.faq_count}
                                </span>
                              </h3>
                              <p className="text-muted mb-1 text-truncate">
                                Total FAQ
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ) : null}
              {sessionStorage.getItem("AdminRole") === "1" ||
              (sessionStorage.getItem("AdminRole") === "0" &&
                permission.includes("Earning")) ? (
                <div className="col-md-6 col-xl-3">
                  <Link to="/earning/virtual">
                    <div className="widget-rounded-circle card new-cards zoomInRight animated">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6">
                            <div className="avatar-lg rounded-circle bg-soft-pink border-pink border">
                              <i className="fe-dollar-sign font-22 avatar-title text-pink"></i>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="text-end">
                              <h3 className="text-dark mt-1">
                                <span>&#36;</span>
                                <span data-plugin="counterup">
                                  {dashboardCount?.virtual_speed_dating_earning}
                                </span>
                              </h3>
                              <p className="text-muted mb-1 line-clamp-2">
                                {" "}
                                Total Earning from Virtual Speed Dating{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ) : null}
              {sessionStorage.getItem("AdminRole") === "1" ||
              (sessionStorage.getItem("AdminRole") === "0" &&
                permission.includes("Earning")) ? (
                <div className="col-md-6 col-xl-3">
                  <Link to="/earning/realtimematch">
                    <div className="widget-rounded-circle card new-cards zoomInRight animated">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6">
                            <div className="avatar-lg rounded-circle bg-soft-pink border-pink border">
                              <i className="fe-dollar-sign font-22 avatar-title text-pink"></i>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="text-end">
                              <h3 className="text-dark mt-1">
                                <span>&#36;</span>
                                <span data-plugin="counterup">
                                  {dashboardCount?.real_world_meetup_earning}
                                </span>
                              </h3>
                              <p className="text-muted mb-1 line-clamp-2">
                                Total Earning from Real World Meetup
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ) : null}
              {sessionStorage.getItem("AdminRole") === "1" ||
              (sessionStorage.getItem("AdminRole") === "0" &&
                permission.includes("Earning")) ? (
                <div className="col-md-6 col-xl-3 d-flex flex-column">
                  <Link
                    to="/earning/refundearning"
                    className="flex-grow-1 d-flex flex-column"
                  >
                    <div className="widget-rounded-circle card new-cards zoomInRight animated flex-grow-1 ">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6">
                            <div className="avatar-lg rounded-circle bg-soft-secondary border-secondary  border">
                              <i className="fe-rewind font-22 avatar-title text-secondary"></i>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="text-end">
                              <h3 className="text-dark mt-1">
                                <span>&#36;</span>
                                <span data-plugin="counterup">
                                  {dashboardCount?.total_refunded_amount}
                                </span>
                              </h3>
                              <p className="text-muted mb-1 line-clamp-2">
                                Total Refunded Amount
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ) : null}

              {sessionStorage.getItem("AdminRole") === "1" ||
              (sessionStorage.getItem("AdminRole") === "0" &&
                permission.includes("User")) ? (
                <div className="col-md-6 col-xl-3">
                  <div className="widget-rounded-circle card new-cards zoomInRight animated">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6">
                          <div className="avatar-lg rounded-circle bg-soft-info border-info border">
                            <i className="fe-users font-22 avatar-title text-info"></i>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="text-end">
                            <h3 className="text-dark mt-1">
                              <span data-plugin="counterup">
                                {
                                  dashboardCount?.virtual_event_joined_participant
                                }
                              </span>
                            </h3>
                            <p className="text-muted mb-1 line-clamp-2">
                              Total Joined Participants in Virtual Event
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <!-- end row--> */}
                    </div>
                  </div>
                </div>
              ) : null}

              {sessionStorage.getItem("AdminRole") === "1" ||
              (sessionStorage.getItem("AdminRole") === "0" &&
                permission.includes("Cafe")) ? (
                <div className="col-md-6 col-xl-3">
                  <div className="widget-rounded-circle card new-cards zoomInRight animated">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6">
                          <div className="avatar-lg rounded-circle bg-soft-warning border-warning border">
                            <i className="fe-users font-22 avatar-title text-warning"></i>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="text-end">
                            <h3 className="text-dark mt-1">
                              <span data-plugin="counterup">
                                {dashboardCount?.real_event_joined_participant}
                              </span>
                            </h3>
                            <p
                              title="Total Joined Participants in Real Meetup Event"
                              className="text-muted mb-1 line-clamp-2"
                            >
                              Total Joined Participants in Real Meetup Event
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* ================================ End Page content ============================== */}
    </>
  );
}
