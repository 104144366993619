import React, { useState, useEffect, useCallback, useMemo } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Common from "../../utils/Common";
import Swal from "sweetalert2";
import moment from "moment";
import GLOBALS from "../../utils/Constant";

import { Link } from "react-router-dom";
import {
  getUserList,
  changeUserStatus,
  removeUser,
  getCountryList,
  getCityList,
  getNationalityList,
  getLanguageList,
  getHobbiesList,
  getReligionList,
  getMbtiList,
  getEducationList,
  getEmpList,
  getParenthoodList,
  exportUserData,
  blockUnblockUser,
} from "../../Api/apiHandler";
import Select from "react-select";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import TimePicker from "react-bootstrap-time-picker";
import Slider from "rc-slider";
import "rc-slider/assets/index.css"; // Import the default styles

export default function UserList() {
  const [userList, setUserList] = useState(null);
  const [exportUserList, setExportUserList] = useState(null);
  console.log("exportUserList:====> ", exportUserList);
  const [countryList, setCountryList] = useState(null);
  const [cityList, setCityList] = useState([]);
  const [nationalityList, setNationalityList] = useState(null);
  const [languageList, setLanguageList] = useState(null);
  const [hobbiesList, setHobbiesList] = useState(null);
  const [parenthoodList, setParenthoodList] = useState(null);
  const [empList, setEmpList] = useState(null);
  const [educationList, setEducationList] = useState(null);
  const [religionList, setReligionList] = useState(null);
  const [mbtiList, setMbtiList] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const initialFilters = useMemo(() => {
    return {
      selectedCountry: null,
      selectedNationality: null,
      selectedCity: null,
      selectedLanguage: null,
      selectedGender: null,
      selectedZodiac: null,
      selectedAnimal: null,
      selectedDate: "",
      selectedTime: null,
      selectedHobbies: null,
      selectedParenthood: null,
      selectedReligion: null,
      selectedMbti: null,
      selectedEmployment: null,
      selectedEducation: null,
      sliderValue: [],
      sliderValueFT: [],
      is_active: null,
      is_block: null,
      start_date: "",
      end_date: "",
    };
  }, []);
  // For fetch user list API
  const fetchUserList = useCallback(
    async (request) => {
      try {
        request.page = currentPage;
        request.pageSize = pageSize;
        const response = await getUserList(request);

        // Update state with new data and total count
        if (response.code === 1) {
          console.log("85response.data.data: ", response.data.data);
          setUserList(response.data.data);
          setTotalItems(response.data.totalCount);
          Common.SuccessAlert(response.message);
        } else {
          setUserList([]);
          Common.ErrorAlert(response.message);
        }
      } catch (error) {
        Common.ErrorAlert("Oops! Something went wrong");
        console.error("Error fetching data:", error);
      }
    },
    [currentPage, pageSize]
  );

  // For fetch user list API
  const fetchExportUserList = useCallback(
    async (request) => {
      try {
        request.page = currentPage;
        request.pageSize = pageSize;
        const response = await exportUserData(request);

        // Update state with new data and total count
        if (response.code === 1) {
          setExportUserList(response.data.data);
        } else {
          setExportUserList([]);
          Common.ErrorAlert(response.message);
        }
      } catch (error) {
        Common.ErrorAlert("Oops! Something went wrong");
        console.error("Error fetching data:", error);
      }
    },
    [currentPage, pageSize]
  );

  const fetchCountryList = useCallback(async (request) => {
    try {
      const response = await getCountryList(request);

      // Update state with new data and total count
      if (response.code === 1) {
        setCountryList(response.data);
      } else {
        setCountryList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, []);

  const fetchCityList = useCallback(async (request) => {
    try {
      const response = await getCityList(request);

      // Update state with new data and total count
      if (response.code === 1) {
        setCityList(response.data);
      } else {
        setCityList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, []);

  const fetchNationalityList = useCallback(async (request) => {
    try {
      const response = await getNationalityList(request);

      // Update state with new data and total count
      if (response.code === 1) {
        setNationalityList(response.data);
      } else {
        setNationalityList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, []);

  const fetchLanguageList = useCallback(async (request) => {
    try {
      const response = await getLanguageList(request);

      // Update state with new data and total count
      if (response.code === 1) {
        setLanguageList(response.data);
      } else {
        setLanguageList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, []);

  const fetchHobbiesList = useCallback(async (request) => {
    try {
      const response = await getHobbiesList(request);

      // Update state with new data and total count
      if (response.code === 1) {
        setHobbiesList(response.data);
      } else {
        setHobbiesList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, []);

  const fetchMbtiList = useCallback(async (request) => {
    try {
      const response = await getMbtiList(request);

      // Update state with new data and total count
      if (response.code === 1) {
        setMbtiList(response.data);
      } else {
        setMbtiList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, []);

  const fetchEducationList = useCallback(async (request) => {
    try {
      const response = await getEducationList(request);

      // Update state with new data and total count
      if (response.code === 1) {
        setEducationList(response.data);
      } else {
        setEducationList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, []);

  const fetchEmpList = useCallback(async (request) => {
    try {
      const response = await getEmpList(request);

      // Update state with new data and total count
      if (response.code === 1) {
        setEmpList(response.data);
      } else {
        setEmpList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, []);

  const fetchReligionList = useCallback(async (request) => {
    try {
      const response = await getReligionList(request);

      // Update state with new data and total count
      if (response.code === 1) {
        setReligionList(response.data);
      } else {
        setReligionList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, []);

  const fetchParenthoodList = useCallback(async (request) => {
    try {
      const response = await getParenthoodList(request);

      // Update state with new data and total count
      if (response.code === 1) {
        setParenthoodList(response.data);
      } else {
        setParenthoodList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, []);

  //   Get Listing
  useEffect(() => {
    fetchCountryList({});
    fetchNationalityList({});
    fetchLanguageList({});
    fetchHobbiesList({});
    fetchEducationList({});
    fetchEmpList({});
    fetchMbtiList({});
    fetchReligionList({});
    fetchParenthoodList({});
  }, [
    fetchCountryList,
    fetchEducationList,
    fetchEmpList,
    fetchHobbiesList,
    fetchLanguageList,
    fetchMbtiList,
    fetchNationalityList,
    fetchParenthoodList,
    fetchReligionList,
  ]);

  // Function to change status
  const changeState = (user_id, state) => {
    Swal.fire({
      title: "Change Status",
      text: "Are you sure you want to change status?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let params = {
          userid: user_id,
          is_active: state,
        };

        try {
          const response = await changeUserStatus(params);

          if (response.code === 1) {
            Common.SuccessAlert(response.message);
            fetchUserList(initialFilters);
          } else {
            Common.ErrorAlert(response.message);
          }
        } catch (error) {
          console.error("Error in changeUserStatus:", error);
          Common.ErrorAlert("Failed to change status");
        }
      }
    });
  };

  const handleBlockUnblock = (user_id, state) => {
    Swal.fire({
      title: state === "0" ? "Unblock User" : "Block User",
      text:
        state === "0"
          ? "Are you sure you want to Unblock User?"
          : "Are you sure you want to Block User?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let params = {
          userid: user_id,
          is_block: state,
        };

        try {
          const response = await blockUnblockUser(params);

          if (response.code === 1) {
            Common.SuccessAlert(response.message);
            fetchUserList(initialFilters);
          } else {
            Common.ErrorAlert(response.message);
          }
        } catch (error) {
          console.error("Error in changeUserStatus:", error);
          Common.ErrorAlert("Failed to change status");
        }
      }
    });
  };

  // Function to remove
  const deleteUser = (user_id) => {
    Swal.fire({
      title: "Delete User",
      text: "Are you sure you want to delete user?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let params = {
          userid: user_id,
        };

        try {
          const response = await removeUser(params);

          if (response.code === 1) {
            Common.SuccessAlert(response.message);
            fetchUserList(initialFilters);
          } else {
            Common.ErrorAlert(response.message);
          }
        } catch (error) {
          console.error("Error in removeUser:", error);
          Common.ErrorAlert("Failed to change status");
        }
      }
    });
  };

  //Function for export data in excel
  const exportExcel = () => {
    let columns = [
      {
        header: "ID",
        key: "id",
        flex: 1,
        minWidth: 250,
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      },
      {
        header: "Name",
        flex: 1,
        key: "name",
        minWidth: 25,
        horizontal: "center",
      },
      {
        header: "Email",
        flex: 1,
        key: "email",
        minWidth: 25,
        horizontal: "center",
      },
      {
        header: "Phone",
        flex: 1,
        key: "phone",
        minWidth: 25,
        horizontal: "center",
      },
      {
        header: "Gender",
        flex: 1,
        key: "gender",
        minWidth: 15,
        horizontal: "center",
      },
      { header: "Age", key: "age", minWidth: 10, horizontal: "center" },
      {
        header: "Reported User",
        key: "reported_user_count",
        minWidth: 10,
        horizontal: "center",
      },
      {
        header: "Birth Date",
        key: "birth",
        flex: 1,
        minWidth: 20,
        horizontal: "center",
      },
      {
        header: "Birth Time",
        key: "birth_time",
        flex: 1,
        minWidth: 10,
        horizontal: "center",
      },
      {
        header: "Height",
        key: "height",
        minWidth: 10,
        flex: 1,
        horizontal: "center",
      },
      {
        header: "Zodiac Sign",
        key: "zodiac_sign",
        flex: 1,
        minWidth: 20,
        horizontal: "center",
      },
      {
        header: "Animal Sign",
        key: "animal_sign",
        flex: 1,
        minWidth: 20,
        horizontal: "center",
      },
      {
        header: "Parenthood Status",
        key: "parenthood_status",
        flex: 1,
        minWidth: 25,
        horizontal: "center",
      },
      {
        header: "Nationality",
        key: "nationality",
        flex: 1,
        minWidth: 20,
        horizontal: "center",
      },
      {
        header: "Country",
        key: "country",
        flex: 1,
        minWidth: 20,
        horizontal: "center",
      },
      {
        flex: 1,
        header: "City",
        key: "city",
        minWidth: 20,
        horizontal: "center",
      },
      {
        header: "Languages",
        key: "languages",
        flex: 1,
        minWidth: 25,
        horizontal: "center",
      },
      {
        header: "Education",
        key: "education",
        flex: 1,
        minWidth: 25,
        horizontal: "center",
      },
      {
        header: "Employment Status",
        key: "employment_status",
        flex: 1,
        minWidth: 25,
        horizontal: "center",
      },
      {
        header: "Religion",
        key: "religion",
        flex: 1,
        minWidth: 25,
        horizontal: "center",
      },
      {
        header: "Mbti Type",
        key: "mbti_type",
        flex: 1,
        minWidth: 25,
        horizontal: "center",
      },
      // { header: "Login Status", key: "login_status", width: 20, wrapText: true }
    ];

    const ExportData = exportUserList.map((user) => {
      return {
        id: user._id,
        name: user.first_name + " " + user.last_name,
        email: user.email,
        phone: user.country_code + " " + user.mobile_number,
        birth: moment(user.birth_date).format("DD-MM-YYYY"),
        height: user.height + " " + user.height_unit,
        ...user,
        // login_status: user.login_status
      };
    });

    const fileName = `User_Report_` + moment().format("DDMMYYYYhmmss");

    Common.exportExcelFile(columns, ExportData, fileName);
  };

  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  const columns = [
    { field: "id", headerName: "ID", flex: 1, minWidth: 225 },
    {
      field: "profile",
      headerName: "Profile",
      minWidth: 100,
      sortable: false,
      renderCell: (params) => (
        <Link
          to={params.row.profile_image}
          className="d-flex image-popup justify-content-center"
        >
          <img
            src={params.row.profile_image || GLOBALS.DefaultImage}
            href={params.row.profile_image}
            className="img-responsive img-thumbnail rounded-circle listing-img"
            alt="User"
          />
        </Link>
      ),
    },
    { field: "name", headerName: "Name", minWidth: 150 },
    // { field: 'name', headerName: 'Name', flex: 1.5, renderCell: (params) => `${params.row.first_name} ${params.row.last_name}` },
    { field: "email", headerName: "Email", flex: 1.5, minWidth: 150 },
    {
      field: "phone",
      headerName: "Contact Number",
      flex: 1.5,
      minWidth: 150,
      renderCell: (params) =>
        `${params.row.country_code} ${params.row.mobile_number}`,
    },
    {
      field: "reportedUserCount",
      headerName: "Reported User",
      headerAlign: "center",
      flex: 1.5,
      align: "center",
      minWidth: 150,
      // renderCell: (params) =>
      //   `${params.row.country_code} ${params.row.mobile_number}`,
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      minWidth: 100,
      renderCell: (params) =>
        params.row.gender === "man"
          ? "Male"
          : params.row.gender === "woman"
          ? "Female"
          : params.row.gender === "non-binary"
          ? "Non-binary"
          : "Unknown",
    },
    {
      field: "is_verify",
      headerName: "KYC Verify",
      flex: 1,
      minWidth: 125,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) =>
        params.row.is_verify === 0 ? (
          <span className="badge bg-danger new-badge">Not Verified</span>
        ) : (
          <span className="badge bg-success new-badge">Verified</span>
        ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 125,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) =>
        params.row.is_active === 0 ? (
          <span
            className="badge bg-danger new-badge"
            onClick={() => changeState(params.row._id, "1")}
          >
            Inactive
          </span>
        ) : (
          <span
            className="badge bg-success new-badge"
            onClick={() => changeState(params.row._id, "0")}
          >
            Active
          </span>
        ),
    },
    {
      field: "is_block",
      headerName: "Block/Unblock",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      sortable: false,
      renderCell: (params) =>
        params.row.is_block === 1 ? (
          <span
            className="badge bg-danger  new-badge"
            onClick={() => handleBlockUnblock(params.row._id, "0")}
          >
            Unblock
          </span>
        ) : (
          <span
            className="badge bg-success new-badge"
            onClick={() => handleBlockUnblock(params.row._id, "1")}
          >
            Block
          </span>
        ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <>
          <Link to={`/user/view/${btoa(params.row._id)}`} title="View">
            {" "}
            <i className="mdi mdi-eye mdi-19px text-info"></i>
          </Link>

          {/* <Link to={`/user/edit/${btoa(params.row._id)}`} title="Edit"> <i className="mdi mdi-lead-pencil mdi-19px text-warning"></i></Link> */}

          <span
            onClick={() => deleteUser(params.row._id)}
            className="action-icon action-button"
          >
            {" "}
            <i className="mdi mdi-delete text-danger mdi-19px"></i>
          </span>
        </>
      ),
    },
  ];

  //Rows for datagrid table
  const rows = userList?.map((item, index) => ({
    ...item,
    id: item._id,
  }));

  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 10, // Adjust this based on your requirement
  });

  const handlePaginationModelChange = useCallback(
    (newModel) => {
      setPaginationModel(newModel);
      const currentPage = newModel.page + 1;
      setCurrentPage(currentPage);
      // setCurrentPage(newModel.page)
      setPageSize(newModel.pageSize);
      fetchUserList(initialFilters);
    },
    [fetchUserList, initialFilters]
  );

  // ----------------------------------- Filter -----------------------------------

  const [filters, setFilters] = useState(initialFilters);
  console.log("filters: ", filters);

  //UserEffect For Filter
  useEffect(() => {
    fetchUserList(filters);
    fetchExportUserList(filters);
  }, [fetchExportUserList, fetchUserList, filters]);

  function clearFilter() {
    // Common.processing();
    setTimeout(() => {
      setFilters(initialFilters);
    }, 1000);
  }

  const setDates = (e, { startDate, endDate }) => {
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        start_date: startDate.format("YYYY-MM-DD"),
        end_date: endDate.format("YYYY-MM-DD"),
      };
    });
  };

  const convertToFt = (value) => {
    return Math.round((value / 30.48) * 10) / 10; // Allowing one decimal place for feet
  };

  const handleFilter = useCallback((key, val) => {
    console.log("key:855 ", key, val);
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: val,
    }));
  }, []);

  return (
    <>
      {/* ================================ Start Page Content ================================== */}
      <div className="content-page">
        <div className="content">
          {/* <!-- Start Content--> */}
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">User</li>
                    </ol>
                  </div>
                  <h4 className="page-title">User</h4>
                </div>
              </div>
            </div>
            {/* <!-- end page title -->  */}

            <div className="row">
              <div className="col-12">
                {/* <!-- Portlet card --> */}
                <div className="card">
                  <Link
                    to="#cardCollpase4"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="true"
                    aria-controls="cardCollpase2"
                    className=""
                  >
                    <div className="card-header bg-app text-white">
                      <div className="card-widgets">
                        {/* <Link to="/#" data-toggle="reload" onClick={() => clearFilter()}><i className="mdi mdi-refresh"></i></Link>

                                                <Link to="#cardCollpase4" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="cardCollpase2" className="" >
                                                    <i className="mdi mdi-minus"></i>
                                                </Link> */}
                      </div>
                      <h5 className="card-title mb-0 text-white">Filter</h5>
                    </div>
                  </Link>

                  <div id="cardCollpase4" className="collapse">
                    <div className="card-body">
                      <div className="row">
                        {/* country */}
                        <div className="col-md-2 mb-2">
                          <label className="form-label">Country</label>
                          <Select
                            name="selectedCountry"
                            onChange={(e) => {
                              handleFilter("selectedCountry", e?._id || null);
                              fetchCityList(e);
                            }}
                            options={countryList}
                            placeholder="Select Country"
                            isSearchable={true}
                            getOptionLabel={(option) => option.name} // Specify the property for displaying the label
                            getOptionValue={(option) => option.name} // Specify the property for the option value
                            isClearable={true} // Enables the "Clear" option
                          />
                        </div>

                        {/* <div className="col-md-2 mb-3">
                                                    <label className="form-label">City</label>
                                                    <Select
                                                        value={selectedCity}
                                                        onChange={handleCityChange}
                                                        options={cityList}
                                                        placeholder="Select City"
                                                        isSearchable={true}
                                                        getOptionLabel={(option) => option.name} // Specify the property for displaying the label
                                                        getOptionValue={(option) => option.name} // Specify the property for the option value
                                                    />
                                                </div> */}

                        {/* city */}
                        {filters.selectedCountry && (
                          <div className="col-md-2 mb-2">
                            <label className="form-label">City</label>
                            <Select
                              name="selectedCity"
                              onChange={(e) => {
                                handleFilter("selectedCity", e?._id || null);
                              }}
                              options={cityList}
                              placeholder="Select City"
                              isSearchable={true}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id.toString()}
                              isClearable={true} // Enables the "Clear" option
                            />
                          </div>
                        )}

                        {/* Nationality */}
                        <div className="col-md-2 mb-2">
                          <label className="form-label">Nationality</label>
                          <Select
                            name="selectedNationality"
                            onChange={(e) => {
                              handleFilter(
                                "selectedNationality",
                                e?._id || null
                              );
                            }}
                            options={nationalityList}
                            placeholder="Select Nationality"
                            isSearchable={true}
                            getOptionLabel={(option) => option.name} // Specify the property for displaying the label
                            getOptionValue={(option) => option.name} // Specify the property for the option value
                            isClearable={true} // Enables the "Clear" option
                          />
                        </div>

                        {/* language */}
                        <div className="col-md-4 mb-2">
                          <label className="form-label">Language</label>
                          <Select
                            value={languageList?.filter((option) =>
                              filters.selectedLanguage?.includes(option?._id)
                            )}
                            name="selectedLanguage"
                            onChange={(e) => {
                              handleFilter(
                                "selectedLanguage",
                                e?.map((i) => i._id)
                              );
                            }}
                            options={languageList}
                            isMulti // Enable multiple selection
                            placeholder="Select Language"
                            isSearchable={true}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option._id}
                          />
                        </div>

                        {/* Hobbies */}
                        <div className="col-md-4 mb-2">
                          <label className="form-label">Hobbies</label>
                          <Select
                            value={hobbiesList?.filter((option) =>
                              filters.selectedHobbies?.includes(option._id)
                            )}
                            name="selectedHobbies"
                            onChange={(e) => {
                              handleFilter(
                                "selectedHobbies",
                                e?.map((i) => i._id)
                              );
                            }}
                            options={hobbiesList}
                            isMulti // Enable multiple selection
                            placeholder="Select Hobbies"
                            isSearchable={true}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option._id}
                          />
                        </div>

                        {/* Gender */}
                        <div className="col-md-2 mb-2">
                          <label className="form-label">Gender</label>
                          <Select
                            name="selectedGender"
                            onChange={(e) => {
                              handleFilter("selectedGender", e?.value || null);
                            }}
                            options={GLOBALS.genderList}
                            placeholder="Select Gender"
                            isSearchable={true}
                            isClearable={true} // Enables the "Clear" option
                          />
                        </div>

                        {/* Zodiac */}
                        <div className="col-md-2 mb-2">
                          <label className="form-label">Zodiac</label>
                          <Select
                            name="selectedZodiac"
                            onChange={(e) => {
                              handleFilter("selectedZodiac", e?.value || null);
                            }}
                            options={GLOBALS.ZODIAC_SIGN}
                            placeholder="Select Zodiac Sign"
                            isSearchable={true}
                            isClearable={true} // Enables the "Clear" option
                          />
                        </div>

                        {/* Birth Animal */}
                        <div className="col-md-2 mb-2">
                          <label className="form-label">Birth Animal</label>
                          <Select
                            name="selectedAnimal"
                            onChange={(e) => {
                              handleFilter("selectedAnimal", e?.value || null);
                            }}
                            options={GLOBALS.BIRTH_YEAR_ANIMAL}
                            placeholder="Select Birth Animal"
                            isSearchable={true}
                            isClearable={true} // Enables the "Clear" option
                          />
                        </div>

                        {/* is active status */}
                        <div className="col-md-2 mb-2">
                          <label className="form-label">KYC Status</label>

                          <Select
                            name="is_verify"
                            onChange={(e) => {
                              handleFilter("is_verify", e?.value);
                            }}
                            options={GLOBALS.kycStatus}
                            placeholder="Select Status"
                            isSearchable={true} // Enables search functionality
                          />
                        </div>
                        <div className="col-md-2 mb-2">
                          <label className="form-label">Status</label>

                          <Select
                            name="is_active"
                            onChange={(e) => {
                              console.log("e:919 ", e);
                              handleFilter("is_active", e?.value);
                            }}
                            options={GLOBALS.statusOption}
                            placeholder="Select Status"
                            isSearchable={true} // Enables search functionality
                          />
                        </div>
                        <div className="col-md-2 mb-2">
                          <label className="form-label">Block/Unblock</label>
                          <Select
                            name="is_active"
                            onChange={(e) => {
                              console.log("e:919 ", e);
                              handleFilter("is_block", e?.value);
                            }}
                            options={GLOBALS.blockOption}
                            placeholder="Block/Unblock Status"
                            isSearchable={true} // Enables search functionality
                          />
                        </div>

                        {/* Height */}
                        <div className="col-md-4 mb-2">
                          <div className="row">
                            <div className="col-md-9 mb-2">
                              <label
                                htmlFor="range-slider"
                                className="form-label"
                              >
                                Select a Height:
                              </label>

                              <Slider
                                range
                                min={140} // Minimum height in feet or centimeters
                                max={214} // Maximum height in feet or centimeters
                                step={1}
                                defaultValue={
                                  filters?.sliderValue?.length > 0
                                    ? filters.sliderValue
                                    : [140, 214]
                                }
                                tooltip="top"
                                onChange={(e) => {
                                  handleFilter("sliderValue", e);
                                  handleFilter("sliderValueFT", [
                                    convertToFt(e[0]),
                                    convertToFt(e[1]),
                                  ]);
                                }}
                              />
                              {
                                <p>
                                  Selected range:{" "}
                                  {filters.sliderValue[0] || 140} to{" "}
                                  {filters.sliderValue[1] || 214} cm (
                                  {convertToFt(filters.sliderValue[0] || 140)}'
                                  to{" "}
                                  {convertToFt(filters.sliderValue[1] || 214)}'
                                  ft)
                                  {/* Selected range: {convertToCm(sliderValue[0])} to {convertToCm(sliderValue[1])} cm  {' '}({convertToFt(sliderValue[0])}' to {convertToFt(sliderValue[1])}' ft) */}
                                </p>
                              }
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-md-2 mb-3">

                                                    <label className="form-label">Login Status</label>

                                                    <Select
                                                        value={loginStatus}
                                                        onChange={handleLoginChange}
                                                        options={GLOBALS.LOGIN_OPTION}
                                                        placeholder="Select Login Status"
                                                        isSearchable={true} // Enables search functionality
                                                    />
                                                </div> */}

                        {/* Birth date */}
                        <div className="col-md-3 mb-2">
                          <label className="form-label">Birth Date</label>

                          <DateRangePicker
                            initialSettings={{
                              singleDatePicker: true,
                              showDropdowns: true,
                              startDate: "10/18/1984",
                              minYear: 1901,
                              maxYear: parseInt(moment().format("YYYY"), 10),
                            }}
                            onCallback={(start) => {
                              handleFilter(
                                "selectedDate",
                                start.format("YYYY-MM-DD")
                              );
                            }}
                          >
                            <input type="text" className="form-control col-4" />
                          </DateRangePicker>
                        </div>

                        {/* Birth time */}
                        <div className="col-md-2 mb-2">
                          <label className="form-label">Birth Time</label>
                          <TimePicker
                            start="0:00"
                            end="23:59"
                            step={15}
                            onChange={(e) => {
                              const duration = moment.duration(e, "seconds");
                              const hours = Math.floor(duration.asHours());
                              const minutes = duration.minutes();
                              const secondsRemaining = duration.seconds();

                              const formattedTime = moment({
                                hours,
                                minutes,
                                seconds: secondsRemaining,
                              }).format("HH:mm");
                              handleFilter("selectedTime", formattedTime);
                            }}
                          />
                        </div>

                        {/* Parenthood */}
                        <div className="col-md-2 mb-2">
                          <label className="form-label">
                            Parenthood Status
                          </label>
                          <Select
                            name="selectedParenthood"
                            onChange={(e) => {
                              handleFilter("selectedParenthood", e?._id);
                            }}
                            options={parenthoodList}
                            placeholder="Select Status"
                            isSearchable={true}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option._id}
                            isClearable={true} // Enables the "Clear" option
                          />
                        </div>

                        {/* Religion */}
                        <div className="col-md-2 mb-2">
                          <label className="form-label">Religion </label>

                          <Select
                            name="selectedReligion"
                            onChange={(e) => {
                              handleFilter("selectedReligion", e?._id);
                            }}
                            options={religionList}
                            placeholder="Select Religion"
                            isSearchable={true}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option._id}
                            isClearable={true} // Enables the "Clear" option
                          />
                        </div>

                        {/* MBTI type */}
                        <div className="col-md-2 mb-2">
                          <label className="form-label">MBTI type </label>

                          <Select
                            name="selectedMbti"
                            onChange={(e) => {
                              handleFilter("selectedMbti", e?._id);
                            }}
                            options={mbtiList}
                            placeholder="Select MBTI type"
                            isSearchable={true}
                            getOptionLabel={(option) => option.type}
                            getOptionValue={(option) => option._id}
                            isClearable={true} // Enables the "Clear" option
                          />
                        </div>

                        {/* Employment type */}
                        <div className="col-md-2 mb-2">
                          <label className="form-label">
                            Employment Status{" "}
                          </label>

                          <Select
                            name="selectedEmployment"
                            onChange={(e) => {
                              handleFilter("selectedEmployment", e?._id);
                            }}
                            options={empList}
                            placeholder="Select Employment"
                            isSearchable={true}
                            getOptionLabel={(option) => option.type}
                            getOptionValue={(option) => option._id}
                            isClearable={true} // Enables the "Clear" option
                          />
                        </div>

                        {/* Education type */}
                        <div className="col-md-2 mb-2">
                          <label className="form-label">
                            Education Status{" "}
                          </label>
                          <Select
                            name="selectedEducation"
                            onChange={(e) => {
                              handleFilter("selectedEducation", e?._id);
                            }}
                            options={educationList}
                            placeholder="Select Education"
                            isSearchable={true}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option._id}
                            isClearable={true} // Enables the "Clear" option
                          />
                        </div>

                        {/* date and time */}
                        <div className="col-md-3 mb-2">
                          <label className="form-label">Register Date</label>

                          <DateRangePicker
                            onApply={setDates}
                            initialSettings={{
                              startDate: moment()
                                .subtract(6, "days")
                                .format("MM/DD/YYYY"),
                              endDate: moment().format("MM/DD/YYYY"),
                              ranges: GLOBALS.datePickerRanges,
                            }}
                          >
                            <input
                              type="text"
                              value={
                                filters.start_date !== ""
                                  ? moment(filters.start_date).format(
                                      "MMMM DD, YYYY"
                                    ) +
                                    " - " +
                                    moment(filters.end_date).format(
                                      "MMMM DD, YYYY"
                                    )
                                  : "Select Period"
                              }
                              className="form-control"
                              onChange={() => {
                                console.log(`changes`);
                              }}
                            />
                          </DateRangePicker>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-auto">
                          <div className="text-lg-end my-1 my-lg-0">
                            <button
                              type="button"
                              onClick={() => clearFilter()}
                              className="btn btn-warning waves-effect waves-light me-1"
                            >
                              Clear
                            </button>

                            <button
                              type="button"
                              onClick={() => exportExcel()}
                              className="btn btn-success waves-effect waves-light me-1"
                            >
                              Export
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- end card--> */}
              </div>

              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    {/* <div className="col-md-12 mb-3">

                                            <Link to={`/user/add`} title="Add">
                                                <button type="button" className="btn btn-custom waves-effect waves-light mr-1">Add</button>
                                            </Link>
                                        </div> */}

                    {userList && (
                      <DataGrid
                        columns={columns}
                        rows={rows}
                        rowHeight={80}
                        disableColumnFilter
                        disableDensitySelector
                        disableExport
                        slots={{ toolbar: GridToolbar }}
                        filterModel={filterModel}
                        onFilterModelChange={(newModel) =>
                          setFilterModel(newModel)
                        }
                        // slotProps={{ toolbar: { showQuickFilter: true, }, }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true },
                          },
                        }}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                          },
                          // filter: {
                          //     filterModel: {
                          //       items: [],
                          //       quickFilterValues: ['quick', 'filter'],
                          //     },
                          //   },
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                        pageSize={paginationModel.pageSize}
                        onPaginationModelChange={handlePaginationModelChange}
                        paginationMode="server" // Make sure to set paginationMode to "server"
                        rowCount={totalItems} // Pass the total count of items to rowCount
                        pagination={true} // Enable pagination
                        // checkboxSelection

                        // checkboxSelection
                        // disableRowSelectionOnClick

                        // onRowSelectionModelChange={(newRowSelectionModel) => {
                        //     setRowSelectionModel(newRowSelectionModel);
                        // }}

                        autoHeight
                        autoSize
                      />
                    )}
                  </div>
                  {/* <!-- end card body--> */}
                </div>
                {/* <!-- end card --> */}
              </div>
              {/* <!-- end col--> */}
            </div>
            {/* <!-- end row--> */}
          </div>
        </div>
      </div>
    </>
  );
}
