import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import moment from "moment";
import * as Common from "../../utils/Common";
import GLOBALS from "../../utils/Constant";
import {
  getAdminList,
  changeAdminStatus,
  removeSubAdmin,
  exportAdminData,
} from "../../Api/apiHandler";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

export default function Listing() {
  const [adminList, setAdminList] = useState(null);
  const [exportAdminList, setExportAdminList] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // State to manage the selected value in the dropdown
  const [selectedPermission, setSelectedPermission] = React.useState([]);
  const [adminStatus, setAdminStatus] = useState(null);
  const [dates, setDatesState] = useState({
    startDate: "",
    endDate: "",
  });

  // For fetch admin list API
  const fetchAdminList = useCallback(
    async (request) => {
      try {
        request.page = currentPage;
        request.pageSize = pageSize;
        const response = await getAdminList(request);

        // Update state with new data and total count
        if (response.code === 1) {
          setAdminList(response.data.data);
          setTotalItems(response.data.totalCount);
          Common.SuccessAlert(response.message);
        } else {
          setAdminList([]);
          Common.ErrorAlert(response.message);
        }
      } catch (error) {
        Common.ErrorAlert("Oops! Something went wrong");
        console.error("Error fetching data:", error);
      }
    },
    [currentPage, pageSize]
  );

  // For fetch user list API
  const fetchExportAdminList = useCallback(async (request) => {
    try {
      const response = await exportAdminData(request);

      // Update state with new data and total count
      if (response.code === 1) {
        setExportAdminList(response.data);
      } else {
        setExportAdminList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, []);

  //Columns for datagrid table
  const columns = [
    { field: "_id", headerName: "ID", flex: 1, minWidth: 250 },
    { field: "name", headerName: "Name", flex: 1, minWidth: 250 },
    { field: "email", headerName: "email", flex: 1, minWidth: 250 },
    {
      field: "phone",
      headerName: "Contact Number",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => `+${params.row.country_code} ${params.row.phone}`,
    },
    { field: "permissions", headerName: "Permissions", flex: 1, minWidth: 250 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 125,
      sortable: false,
      renderCell: (params) =>
        params.row.is_active === 0 ? (
          <span
            className="badge bg-danger new-badge"
            onClick={() => changeState(params.row._id, "1")}
          >
            Inactive
          </span>
        ) : (
          <span
            className="badge bg-success new-badge"
            onClick={() => changeState(params.row._id, "0")}
          >
            Active
          </span>
        ),
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <>
          <Link
            to={`/subAdmin/edit/${btoa(params.row._id)}`}
            title="Edit"
            className="action-icon"
          >
            {" "}
            <i className="mdi mdi-lead-pencil mdi-19px text-warning"></i>
          </Link>

          <span
            onClick={() => deleteAdmin(params.row._id)}
            className="action-icon action-button"
          >
            {" "}
            <i className="mdi mdi-delete text-danger mdi-19px"></i>
          </span>
        </>
      ),
    },
  ];

  //Rows for datagrid table
  const rows = adminList?.map((item, index) => ({
    ...item,
    id: item._id,
  }));

  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 10, // Adjust this based on your requirement
  });

  const handlePaginationModelChange = (newModel) => {
    // console.log(newModel,'newModel');
    setPaginationModel(newModel);
    // Access the current page number
    const currentPage = newModel.page + 1;
    // console.log(currentPage,'currentPage');

    setCurrentPage(currentPage);
    // setCurrentPage(newModel.page)
    setPageSize(newModel.pageSize);

    fetchAdminList({});
  };

  // Function to change status
  const changeState = (admin_id, state) => {
    Swal.fire({
      title: "Change Status",
      text: "Are you sure you want to change status?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let params = {
          admin_id: admin_id,
          is_active: state,
        };

        try {
          const response = await changeAdminStatus(params);

          if (response.code === 1) {
            Common.SuccessAlert(response.message);
            fetchAdminList({});
          } else {
            Common.ErrorAlert(response.message);
          }
        } catch (error) {
          console.error("Error in changeAdminStatus:", error);
          Common.ErrorAlert("Failed to change status");
        }
      }
    });
  };

  // Function to remove
  const deleteAdmin = (admin_id) => {
    Swal.fire({
      title: "Delete",
      text: "Are you sure you want to delete?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let params = {
          admin_id: admin_id,
        };

        try {
          const response = await removeSubAdmin(params);

          if (response.code === 1) {
            Common.SuccessAlert(response.message);
            fetchAdminList({});
          } else {
            Common.ErrorAlert(response.message);
          }
        } catch (error) {
          console.error("Error in remove admin:", error);
          Common.ErrorAlert("Failed to change status");
        }
      }
    });
  };

  //Function for export data in excel
  const exportExcel = () => {
    let columns = [
      {
        header: "ID",
        key: "id",
        width: 25,
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      },
      { header: "Name", key: "name", width: 25, horizontal: "center" },
      { header: "Email", key: "email", width: 20, horizontal: "center" },
      { header: "Phone", key: "phone", width: 25, horizontal: "center" },
      {
        header: "Permissions",
        key: "permissions",
        width: 20,
        horizontal: "center",
      },
    ];

    const ExportData = exportAdminList?.map((admin) => {
      return {
        id: admin._id,
        phone: "+" + admin.country_code + " " + admin.phone,
        ...admin,
      };
    });

    const fileName = `Admin_Report_` + moment().format("DDMMYYYYhmmss");

    Common.exportExcelFile(columns, ExportData, fileName);
  };

  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  // ----------------------------------- Filter -----------------------------------

  //UseEffect For Filter
  useEffect(() => {
    let filter_params = {
      is_active: adminStatus?.value,
      start_date: dates.startDate,
      end_date: dates.endDate,
      permissions: selectedPermission,
    };

    console.log(filter_params, "filter params");

    fetchAdminList(filter_params);
    fetchExportAdminList(filter_params);
  }, [
    adminStatus?.value,
    dates.endDate,
    dates.startDate,
    fetchAdminList,
    fetchExportAdminList,
    selectedPermission,
  ]);

  function clearFilter() {
    // Common.processing();
    setTimeout(() => {
      //     Swal.close()
      setAdminStatus(null);
      setSelectedPermission(null);
      setDatesState({
        startDate: "",
        endDate: "",
      });
    }, 1000);
  }

  // Handler function to update the selected value when the dropdown changes
  const handleStatusChange = (selectedOption) => {
    setAdminStatus(selectedOption);
  };

  const setDates = (e, { startDate, endDate }) => {
    setDatesState({
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
    });
  };

  const handlePermissionChange = (selectedOptions) => {
    console.log(selectedOptions, " selectedOptionsselectedOptions");

    const selectedValues = selectedOptions.map((option) => option.value);
    console.log(selectedValues, " selectedValues");
    // Update your state or do other actions with selected values
    setSelectedPermission(selectedValues);
  };

  return (
    <>
      <div className="content-page">
        <div className="content">
          {/* <!-- Start Content--> */}
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Sub Admin</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Sub Admin</h4>
                </div>
              </div>
            </div>
            {/* <!-- end page title -->  */}

            <div className="row">
              <div className="col-12">
                {/* <!-- Portlet card --> */}
                <div className="card">
                  <Link
                    to="#cardCollpase4"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="true"
                    aria-controls="cardCollpase2"
                    className=""
                  >
                    <div className="card-header bg-app text-white">
                      <div className="card-widgets">
                        {/* <Link to="/#" data-toggle="reload" onClick={() => clearFilter()}><i className="mdi mdi-refresh"></i></Link>

                                                <Link to="#cardCollpase4" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="cardCollpase2" className="" >
                                                    <i className="mdi mdi-minus"></i>
                                                </Link> */}
                      </div>
                      <h5 className="card-title mb-0 text-white">Filter</h5>
                    </div>
                  </Link>

                  <div id="cardCollpase4" className="collapse">
                    <div className="card-body">
                      <div className="row">
                        {/* permission */}
                        <div className="col-md-5 mb-2">
                          <label className="form-label">Permissions</label>

                          <Select
                            value={GLOBALS.PERMISSIONS?.filter((option) =>
                              selectedPermission?.includes(option.value)
                            )}
                            onChange={handlePermissionChange}
                            options={GLOBALS.PERMISSIONS.filter(
                              (i) => i.value !== "Sub Admin"
                            )}
                            placeholder="Select Permissions"
                            isSearchable={true} // Enables search functionality
                            isMulti // Enable multiple selection
                          />
                        </div>

                        {/* is active status */}
                        <div className="col-md-2 mb-2">
                          <label className="form-label">Status</label>

                          <Select
                            value={adminStatus}
                            onChange={handleStatusChange}
                            options={GLOBALS.statusOption}
                            placeholder="Select Status"
                            isSearchable={true} // Enables search functionality
                          />
                        </div>

                        {/* date and time */}
                        <div className="col-md-3 mb-2">
                          <label className="form-label">Register Date</label>

                          <DateRangePicker
                            onApply={setDates}
                            initialSettings={{
                              startDate: moment()
                                .subtract(6, "days")
                                .format("MM/DD/YYYY"),
                              endDate: moment().format("MM/DD/YYYY"),
                              ranges: GLOBALS.datePickerRanges,
                            }}
                          >
                            <input
                              type="text"
                              value={
                                dates.startDate !== ""
                                  ? moment(dates.startDate).format(
                                      "MMMM DD, YYYY"
                                    ) +
                                    " - " +
                                    moment(dates.endDate).format(
                                      "MMMM DD, YYYY"
                                    )
                                  : "Select Period"
                              }
                              className="form-control"
                              onChange={() => {
                                console.log(`changes`);
                              }}
                            />
                          </DateRangePicker>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-auto">
                          <div className="text-lg-end my-1 my-lg-0">
                            <button
                              type="button"
                              onClick={() => clearFilter()}
                              className="btn btn-warning waves-effect waves-light me-1"
                            >
                              Clear
                            </button>

                            <button
                              type="button"
                              onClick={() => exportExcel()}
                              className="btn btn-success waves-effect waves-light me-1"
                            >
                              Export
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- end card--> */}
              </div>

              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="col-md-12 mb-3">
                      <Link to={`/subAdmin/add`} title="Add">
                        <button
                          type="button"
                          className="btn btn-custom waves-effect waves-light mr-1"
                        >
                          Add
                        </button>
                      </Link>
                    </div>

                    {adminList && (
                      <DataGrid
                        columns={columns}
                        rows={rows}
                        rowHeight={80}
                        disableColumnFilter
                        disableDensitySelector
                        disableExport
                        slots={{ toolbar: GridToolbar }}
                        filterModel={filterModel}
                        onFilterModelChange={(newModel) =>
                          setFilterModel(newModel)
                        }
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true },
                          },
                        }}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                          },
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                        pageSize={paginationModel.pageSize}
                        onPaginationModelChange={handlePaginationModelChange}
                        paginationMode="server" // Make sure to set paginationMode to "server"
                        rowCount={totalItems} // Pass the total count of items to rowCount
                        pagination={true} // Enable pagination
                        autoHeight
                        autoSize
                      />
                    )}
                  </div>
                  {/* <!-- end card body--> */}
                </div>
                {/* <!-- end card --> */}
              </div>
              {/* <!-- end col--> */}
            </div>
            {/* <!-- end row--> */}
          </div>
        </div>
      </div>
    </>
  );
}
