import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import * as Common from "../../utils/Common";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { getContactUsList, removeContactUs } from "../../Api/apiHandler";
import "bootstrap-daterangepicker/daterangepicker.css";

export default function UserList() {
  const [contactUsList, setContactUsList] = useState(null);
  const navigate = useNavigate();

  // For fetch user list API
  const fetchContactUsList = async (request) => {
    try {
      const response = await getContactUsList(request);

      if (response.code === 1) {
        setContactUsList(response.data);
      } else {
        setContactUsList([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  };

  // Get Listing
  useEffect(() => {
    fetchContactUsList({});
  }, []);

  function removecontactUSById(contactUsId) {
    Swal.fire({
      title: "Delete Contact Us",
      text: "Are you sure you want to Delete Contact Us?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await removeContactUs({ contactus_id: contactUsId });

        if (response.code === 1) {
          // $('#contactus_table').DataTable().row("#contactUsId" + contactUsId).remove().draw(false);
          fetchContactUsList({});
          Common.SuccessAlert(response.message);
        }
      }
    });
  }

  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  //Navigate the send Reply Page
  const sendReply = (_id) => {
    navigate(`/contact-us/reply/${btoa(_id)}`);
  };

  //Columns for datagrid table
  const columns = [
    { field: "id", headerName: "ID", flex: 1, minWidth: 100 },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 200,
      renderCell: (params) =>
        `${params.row.first_name} ${params.row.last_name}`,
    },
    { field: "email", headerName: "Email", flex: 1, minWidth: 150 },
    { field: "subject", headerName: "Subject", flex: 1, minWidth: 150 },
    { field: "description", headerName: "Description", flex: 1, minWidth: 150 },
    {
      field: "Reply",
      headerName: "Reply Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
      sortable: false,
      renderCell: (params) =>
        params.row.is_reply === 0 ? (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              sendReply(params.row?._id);
            }}
            className="action-icon"
          >
            {" "}
            <i className="mdi mdi-reply mdi-24px text-blue"></i>
          </span>
        ) : (
          <span
            className="mt-1 p-1 badge bg-soft-info text-info"
            onClick={() => {
              sendReply(params.row?._id);
            }}
          >
            {" "}
            Replied
          </span>
        ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <>
          <span
            onClick={() => removecontactUSById(params.row._id)}
            className="action-icon action-button"
          >
            {" "}
            <i className="mdi mdi-delete text-danger mdi-19px"></i>
          </span>
        </>
      ),
    },
  ];

  //Rows for datagrid table
  const rows = contactUsList?.map((item, index) => ({
    ...item,
    id: item._id,
  }));

  return (
    <>
      {/* ================================ Start Page Content ================================== */}
      <div className="content-page">
        <div className="content">
          {/* <!-- Start Content--> */}
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Contact Us</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Contact Us List</h4>
                </div>
              </div>
            </div>
            {/* <!-- end page title -->  */}

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    {contactUsList && (
                      <DataGrid
                        columns={columns}
                        rows={rows}
                        rowHeight={80}
                        disableColumnFilter
                        disableDensitySelector
                        disableExport
                        slots={{ toolbar: GridToolbar }}
                        filterModel={filterModel}
                        onFilterModelChange={(newModel) =>
                          setFilterModel(newModel)
                        }
                        slotProps={{ toolbar: { showQuickFilter: true } }}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                          },
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                        // checkboxSelection
                        // disableRowSelectionOnClick

                        // onRowSelectionModelChange={(newRowSelectionModel) => {
                        //     setRowSelectionModel(newRowSelectionModel);
                        // }}
                      />
                    )}
                  </div>
                  {/* <!-- end card body--> */}
                </div>
                {/* <!-- end card --> */}
              </div>
              {/* <!-- end col--> */}
            </div>
            {/* <!-- end row--> */}
          </div>
        </div>
      </div>
    </>
  );
}
