import React, { useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

const MapComponent = (props) => {
  const {
    setValue: setFormValue,
    location: position,
    setLocation: setPosition,
    map,
    setMap,
  } = props;
  // const [map, setMap] = useState(null);
  // const [position, setPosition] = useState(null);
  console.log("position: ", position);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const onLoad = (map) => {
    // console.log("map: ", map, window.google.maps);
    // const bounds = new window.google.maps.LatLngBounds(
    //   position || { lat: 23.033863, lng: 72.585022 }
    // );
    // map.fitBounds(bounds);
    const initialPosition = position || { lat: 23.033863, lng: 72.585022 };

    map.setCenter(initialPosition);
    map.setZoom(0);

    // setMap(map);
    setMap(map);
    // setMapLoaded(true);
  };

  const onMapClick = (event) => {
    console.log("event: ", event);
    const latLng = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setPosition(latLng);

    // You can use getGeocode and getLatLng to get additional information like address
    getGeocode({ location: latLng })
      .then((results) => {
        console.log("Address:", results[0].formatted_address);
        setFormValue("location", results[0].formatted_address);
      })
      .catch((error) => {
        console.error("Error getting address:", error);
      });
  };

  const onMarkerDragEnd = (event) => {
    onMapClick(event);
  };

  const handleSelect = ({ description }) => {
    setValue(description, false);
    clearSuggestions();
    setFormValue("location", description);
    // Get the location details and set the map position
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setPosition(latLng);
        map.panTo(latLng);
      })
      .catch((error) => {
        console.error("Error selecting place:", error);
      });
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className="row col-lg-12">
        <div className="col-lg-12 mb-3">
          <input
            value={value}
            className="form-control"
            disabled={!ready}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Search for a place"
          />

          {status === "OK" && (
            <ul>
              {data.map((suggestion) => (
                <li
                  key={suggestion.id}
                  onClick={() => handleSelect(suggestion)}
                >
                  {suggestion.description}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {/* <LoadScript googleMapsApiKey="AIzaSyBq1YM-cuTytY4eusITLRuMCuQvddi7Nrs"> */}
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "400px",
        }}
        center={position}
        zoom={10}
        onClick={onMapClick}
        onLoad={onLoad}
      >
        {position && (
          <Marker
            position={position}
            draggable={true}
            onDragEnd={onMarkerDragEnd}
          />
        )}
      </GoogleMap>
      {/* </LoadScript> */}
    </>
  );
};

export default MapComponent;
