import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Common from "../../utils/Common";
import { getAllNotifiction } from "../../Api/apiHandler";
import "bootstrap-daterangepicker/daterangepicker.css";

function Listing() {
  useEffect(() => {
    fetchAllNotif();
  }, []);

  const [notif, setNotif] = useState([]);
  const [ntfttl, setNtfttl] = useState("0");

  const fetchAllNotif = async (request) => {
    try {
      const response = await getAllNotifiction(request);

      if (response.code === 1) {
        setNtfttl(response.data.length);
        setNotif(response.data);
      } else {
        console.log("response notification code zero :", response);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  };

  //Columns for datagrid table
  const columns = [
    { field: "id", headerName: "ID", flex: 1, minWidth: 225 },
    { field: "tag", headerName: "Tag", flex: 1 },
    { field: "message", headerName: "Message", flex: 2, minWidth: 250 },
  ];

  //Rows for datagrid table
  const rows = notif?.map((item, index) => ({
    ...item,
    id: item._id,
  }));

  return (
    <>
      <div className="content-page">
        <div className="content">
          {/* <!-- Start Content--> */}
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Notification</li>
                    </ol>
                  </div>
                  <h4 className="page-title">View Notification</h4>
                </div>
              </div>
            </div>
            {/* <!-- end page title -->  */}

            <div className="row">
              <div className="col-12">
                <div className="col-md-6 col-xl-3">
                  <div className="widget-rounded-circle card new-cards animated">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6">
                          <div className="avatar-lg rounded-circle bg-soft-info border-primary border">
                            <i className="fe-bell font-22 avatar-title text-primary"></i>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="text-end">
                            <h3 className="text-dark mt-1">
                              <span data-plugin="counterup">{ntfttl}</span>
                            </h3>
                            <p className="text-muted mb-1 text-truncate">
                              Total Notification Send
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="col-md-12 mb-3">
                      <Link to={`/notification/send`} title="Add">
                        <button
                          type="button"
                          className="btn btn-custom waves-effect waves-light mr-1"
                        >
                          Send Notification
                        </button>
                      </Link>
                    </div>

                    {notif && (
                      <DataGrid
                        columns={columns}
                        rows={rows}
                        rowHeight={80}
                        disableColumnFilter
                        disableDensitySelector
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true },
                          },
                        }}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                          },
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                      />
                    )}
                  </div>
                  {/* <!-- end card body--> */}
                </div>
                {/* <!-- end card --> */}
              </div>
              {/* <!-- end col--> */}
            </div>
            {/* <!-- end row--> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Listing;
