import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Common from "../../utils/Common";
import {
  exportVitualMeetupReport,
  vitualMeetupReport,
} from "../../Api/apiHandler";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment-timezone";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Swal from "sweetalert2";

function VirtualListing() {
  const [report, setReport] = useState([]);
  console.log("report: ", report);
  const [exportReport, setExportReport] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  console.log("exportReport: ", exportReport);
  const initialFilters = useMemo(() => {
    return {
      user_name: null,
      created_at: null,
    };
  }, []);

  const [filters, setFilters] = useState(initialFilters);
  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });
  const [totalItems, setTotalItems] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 10, // Adjust this based on your requirement
  });
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const getvirtualReportlist = useCallback(
    async (request) => {
      try {
        request.page = currentPage;
        request.pageSize = pageSize;
        request.created_at = request.created_at
          ? moment(request.created_at, "DD-MM-YYYY").format("YYYY-MM-DD")
          : null;
        const response = await vitualMeetupReport(request);
        if (response.code === 1) {
          console.log("response :", response);
          Common.SuccessAlert(response.message);
          setReport(response.data.data);
          setTotalItems(response.data.totalCount);
        } else {
          setReport([]);
          console.log("response notification code zero :", response);
          Common.ErrorAlert(response.message);
        }
      } catch (error) {
        Common.ErrorAlert("Oops! Something went wrong");
        console.error("Error fetching data:", error);
      }
    },
    [currentPage, pageSize]
  );

  const exportVirtualReportlist = useCallback(async (request) => {
    try {
      const response = await exportVitualMeetupReport(request);
      if (response.code === 1) {
        setExportReport(response.data);
        // setTotalItems(response.data.totalCount);
      } else {
        setExportReport([]);
        console.log("response notification code zero :", response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  const handlePaginationModelChange = useCallback(
    (newModel) => {
      setPaginationModel(newModel);
      const currentPage = newModel.page + 1;
      setCurrentPage(currentPage);
      setPageSize(newModel.pageSize);
      getvirtualReportlist(initialFilters);
    },
    [getvirtualReportlist, initialFilters]
  );

  function clearFilter() {
    Common.processing();

    setTimeout(() => {
      setFilters(initialFilters);
      setSelectedDate("");
      Swal.close();
    }, 1000);
  }

  //Columns for datagrid table
  const columns = [
    {
      field: "userName",
      headerName: "User",
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "slot",
      headerName: "Slot",
      flex: 1,
      minWidth: 300,
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {params.row.data.filter((item) => item.match_id).length ? (
              params.row.data
                .filter((item) => item.match_id)
                ?.map((item) => {
                  return (
                    <p key={params.row.user_id + "_" + item?._id}>
                      S{item.slot} - {item.match_name} - (
                      {moment(Number(item?.start_time) * 1000)
                        .tz(item?.event_name)
                        .format("HH:mm")}{" "}
                      -{" "}
                      {moment(Number(item?.end_time) * 1000)
                        .tz(item?.event_name)
                        .format("HH:mm")}
                      )
                    </p>
                  );
                })
            ) : (
              <p> No Match Found </p>
            )}
          </div>
        );
      },
    },
    {
      field: "event_name",
      headerName: "Event",
      sortable: false,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "created_at",
      headerName: "Matched Date",
      sortable: false,
      flex: 1,
      minWidth: 150,
    },
  ];

  //Rows for datagrid table
  const rows = report?.map((item, index) => ({
    ...item,
    index: index + 1,
    id: item.user_id + "_" + index,
    event_name: item?.data?.[0]?.event_name,
    created_at: moment(item.created_at).format("DD-MM-YYYY"),
  }));

  const exportExcel = () => {
    let columns = [
      {
        key: "userName",
        header: "User",
        flex: 1,
        minWidth: 150,
        sortable: false,
      },
      {
        key: "slot",
        header: "Slot",
        flex: 1,
        minWidth: 150,
        sortable: false,
        // valueGetter: (params) => {
        //   // Handle HTML content for slot here
        //   return (
        //     <div>
        //       params: {params?.data}
        //       {params.row.data.filter((item) => item.match_id).length ? (
        //         params.row.data
        //           .filter((item) => item.match_id)
        //           ?.map((item) => {
        //             return (
        //               <p key={params.row.user_id + "_" + item?._id}>
        //                 S{item.slot} - {item.match_name} - (
        //                 {moment.unix(item.start_time).utc().format("HH:mm")} -{" "}
        //                 {moment.unix(item.end_time).utc().format("HH:mm")})
        //               </p>
        //             );
        //           })
        //       ) : (
        //         <p> No Match Found </p>
        //       )}
        //     </div>
        //   );
        // },
      },
      { header: "Event", key: "event_name", width: 25, horizontal: "center" },
      {
        key: "created_at",
        header: "Matched Date",
        sortable: false,
        flex: 1,
        minWidth: 150,
      },
    ];

    const exportRows = exportReport?.map((item, index) => ({
      ...item,
      index: index + 1,
      id: item.user_id + "_" + index,
      event_name: item?.data?.[0]?.event_name,
      created_at: moment(item.created_at).format("DD-MM-YYYY"),
    }));

    const ExportData = exportRows?.map((row) => {
      return {
        id: row._id,
        slot: row.data.filter((item) => item.match_id).length
          ? row.data
              .filter((item) => item.match_id)
              ?.map((item) => {
                return ` S${item.slot} - ${item.match_name} - (${moment(
                  Number(item?.start_time) * 1000
                )
                  .tz(item?.event_name)
                  .format("HH:mm")}} - ${moment(Number(item?.start_time) * 1000)
                  .tz(item?.end_time)
                  .format("HH:mm")})`;
              })
              .join("\n")
          : `No Match Found `,
        ...row,
      };
    });

    const fileName = `Virtual_Event_Report_` + moment().format("DDMMYYYYhmmss");
    console.log("ExportData: ", ExportData);

    Common.exportExcelFile(columns, ExportData, fileName);
  };

  const handleFilter = useCallback((key, val) => {
    console.log("key:855 ", key, val);
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: val,
    }));
  }, []);
  const handleCallback = (start) => {
    const formattedDate = start.format("DD/MM/YYYY");
    setSelectedDate(formattedDate);
    handleFilter("created_at", formattedDate);
  };
  useEffect(() => {
    getvirtualReportlist(filters);
    exportVirtualReportlist(filters);
  }, [exportVirtualReportlist, filters, getvirtualReportlist]);

  return (
    <>
      <div className="content-page">
        <div className="content">
          {/* <!-- Start Content--> */}
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Virtual Event Report
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">Virtual Event Report</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                {/* <!-- Portlet card --> */}
                <div className="card">
                  <Link
                    to="#cardCollpase4"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="true"
                    aria-controls="cardCollpase2"
                    className=""
                  >
                    <div className="card-header bg-app text-white">
                      <div className="card-widgets">
                        {/* <Link to="/#" data-toggle="reload" onClick={() => clearFilter()}><i className="mdi mdi-refresh"></i></Link>

                                                <Link to="#cardCollpase4" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="cardCollpase2" className="" >
                                                    <i className="mdi mdi-minus"></i>
                                                </Link> */}
                      </div>
                      <h5 className="card-title mb-0 text-white">Filter</h5>
                    </div>
                  </Link>

                  <div id="cardCollpase4" className="collapse show">
                    <div className="card-body">
                      <div className="row">
                        {/* is active status */}
                        <div className="col-md-3 mb-2">
                          <label className="form-label">Date</label>

                          {/* <DateRangePicker
                            initialSettings={{
                              singleDatePicker: true,
                              showDropdowns: true,
                              startDate: moment().format("DD/MM/YYYY"),
                              placeholder: "Select Date",
                              locale: {
                                format: "DD/MM/YYYY",
                              },
                              minYear: 1901,
                              maxYear: parseInt(moment().format("YYYY"), 10),
                            }}
                            onCallback={(start) => {
                              handleFilter(
                                "created_at",
                                start.format("DD-MM-YYYY")
                              );
                            }}
                          >
                            <input type="text" className="form-control col-4" />
                          </DateRangePicker> */}
                          <DateRangePicker
                            initialSettings={{
                              singleDatePicker: true,
                              showDropdowns: true,
                              startDate: moment().format("DD/MM/YYYY"),
                              locale: {
                                format: "DD/MM/YYYY",
                              },
                              minYear: 1901,
                              maxYear: parseInt(moment().format("YYYY"), 10),
                            }}
                            onCallback={handleCallback}
                          >
                            <input
                              type="text"
                              className="form-control col-4"
                              value={selectedDate}
                              placeholder="Select Date"
                              readOnly // To prevent direct user input
                            />
                          </DateRangePicker>
                        </div>
                        <div className="col-md-3 mb-2">
                          <label htmlFor="name" className="form-label">
                            User Name
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder="Search User"
                            onChange={(e) => {
                              e.target.value.length >= 3 &&
                                handleFilter("user_name", e.target.value);
                              e.target.value.length === 0 &&
                                handleFilter("user_name", null);
                            }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-auto">
                          <div className="text-lg-end my-1 my-lg-0">
                            <button
                              type="button"
                              onClick={() => clearFilter()}
                              className="btn btn-warning waves-effect waves-light me-1"
                            >
                              Clear
                            </button>
                            <button
                              type="button"
                              onClick={() => exportExcel()}
                              className="btn btn-success waves-effect waves-light me-1"
                            >
                              Export
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- end card--> */}
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    {/* <div className="col-md-12 mb-3">
                      <Link to={`/event/createvirtual`} title="Add">
                        <button
                          type="button"
                          className="btn btn-custom waves-effect waves-light mr-1"
                        >
                          Create Virtual Event
                        </button>
                      </Link>
                    </div> */}

                    {report && (
                      <DataGrid
                        columns={columns}
                        rows={rows}
                        getRowHeight={() => "auto"}
                        // rowHeight={80}
                        disableColumnFilter
                        disableDensitySelector
                        disableExport
                        slots={{ toolbar: GridToolbar }}
                        filterModel={filterModel}
                        onFilterModelChange={(newModel) =>
                          setFilterModel(newModel)
                        }
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true },
                          },
                        }}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                          },
                          // filter: {
                          //     filterModel: {
                          //       items: [],
                          //       quickFilterValues: ['quick', 'filter'],
                          //     },
                          //   },
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                        pageSize={paginationModel.pageSize}
                        onPaginationModelChange={handlePaginationModelChange}
                        paginationMode="server" // Make sure to set paginationMode to "server"
                        rowCount={totalItems} // Pass the total count of items to rowCount
                        pagination={true} // Enable pagination
                        // checkboxSelection

                        // checkboxSelection
                        // disableRowSelectionOnClick

                        // onRowSelectionModelChange={(newRowSelectionModel) => {
                        //     setRowSelectionModel(newRowSelectionModel);
                        // }}
                        autoHeight={true}
                        autoSize
                      />
                    )}
                  </div>
                  {/* <!-- end card body--> */}
                </div>
                {/* <!-- end card --> */}
              </div>
              {/* <!-- end col--> */}
            </div>
            {/* <!-- end row--> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default VirtualListing;
