import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Common from "../../utils/Common";
import GLOBALS from "../../utils/Constant";
import { getOfflineMatchingEarningList } from "../../Api/apiHandler";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";

function RealTimeMatchList() {
  const [vevent, setVevent] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const handleCallback = (start) => {
    const formattedDate = start.format("DD/MM/YYYY");
    setSelectedDate(formattedDate);
    handleFilter("created_at", start.format("YYYY-MM-DD"));
  };

  const initialFilters = useMemo(() => {
    return {
      created_at: null,
    };
  }, []);

  const [filters, setFilters] = useState(initialFilters);
  const getRealTimeMeetMatchList = useCallback(async (request) => {
    try {
      const response = await getOfflineMatchingEarningList(request);
      console.log("response: ", response);

      if (response.code === 1) {
        console.log("response :", response.data);
        Common.SuccessAlert(response.message);
        setVevent(response.data);
      } else {
        console.log("response notification code zero :", response);
        setVevent([]);
        Common.ErrorAlert(response.message);
      }
    } catch (error) {
      Common.ErrorAlert("Oops! Something went wrong");
      console.error("Error fetching data:", error);
    }
  }, []);

  //Columns for datagrid table
  const columns = [
    { field: "id", headerName: "ID", flex: 1, minWidth: 225 },
    {
      field: "profile_img",
      headerName: "",
      sortable: false,
      minWidth: 90,
      renderCell: ({ row }) => (
        <>
          <Link
            to={`/user/view/${btoa(row?.user_id)}`}
            className="d-flex image-popup justify-content-center align-items-center text-dark "
          >
            <img
              src={row?.user?.[0]?.profile_image || GLOBALS.DefaultImage}
              href={row?.user?.[0]?.profile_image}
              className="img-responsive img-thumbnail rounded-circle listing-img"
              alt="User"
            />
          </Link>
        </>
      ),
    },

    {
      field: "user_name",
      headerName: "User Name",
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }) => (
        <Link
          to={`/user/view/${btoa(row?.user_id)}`}
          className="d-flex image-popup justify-content-center align-items-center text-dark "
        >
          <span>{row.user_name}</span>
        </Link>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return <span>${row.amount}</span>;
      },
    },
    {
      field: "payment_intent_id",
      headerName: "Payment Intend Id",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "status",
      headerName: "Payment Status",
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          {row?.payment_status === "succeeded" && (
            <span className="badge bg-success new-badge">
              {row?.payment_status}
            </span>
          )}
          {row?.payment_status === "pending" && (
            <span className="badge bg-warning new-badge">
              {row?.payment_status}
            </span>
          )}
          {row?.payment_status === "failed" && (
            <span className="badge bg-danger new-badge">
              {row?.payment_status}
            </span>
          )}
        </>
      ),
    },
    {
      field: "inserted_at",
      headerName: "Payment Created At",
      minWidth: 200,
      flex: 1,
    },
  ];

  //Rows for datagrid table
  const rows = vevent?.map((item, index) => ({
    ...item,
    id: item._id,
    user_name: item.user[0].name,
    inserted_at: Common.formatDateToTimezone(
      item?.inserted_at,
      GLOBALS.LONG_DATE
    ),
  }));

  const exportExcel = () => {
    let columns = [
      {
        header: "ID",
        key: "id",
        width: 25,
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      },
      { header: "Username", key: "user_name", width: 25, horizontal: "center" },
      { header: "Amount", key: "amount", width: 25, horizontal: "center" },
      {
        header: "Payment Intend Id",
        key: "payment_intent_id",
        width: 20,
        horizontal: "center",
      },
      {
        header: "Payment Status",
        key: "payment_status",
        width: 20,
        horizontal: "center",
      },
      {
        header: "Payment Created At",
        key: "inserted_at",
        width: 20,
        horizontal: "center",
      },
    ];

    const ExportData = rows?.map((row) => {
      return {
        id: row._id,
        phone: "+" + row.country_code + " " + row.phone,
        ...row,
      };
    });

    const fileName =
      `Real_Time_Match_Event_Earning_Report_` +
      moment().format("DDMMYYYYhmmss");

    Common.exportExcelFile(columns, ExportData, fileName);
  };

  const [paymentStatus, setPaymentStatus] = useState(null);
  function clearFilter() {
    // Common.processing();
    setTimeout(() => {
      //     Swal.close()
      setPaymentStatus(null);
      setSelectedDate("");
    }, 1000);
  }
  const handleStatusChange = (selectedOption) => {
    setPaymentStatus(selectedOption);
    setFilters(initialFilters);
  };

  const handleFilter = useCallback((key, val) => {
    console.log("key:855 ", key, val);
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: val,
    }));
  }, []);

  useEffect(() => {
    let filter_params = {
      payment_status: paymentStatus?.value,
      inserted_at: filters.created_at,
    };
    getRealTimeMeetMatchList(filter_params);
  }, [filters.created_at, getRealTimeMeetMatchList, paymentStatus?.value]);

  return (
    <>
      <div className="content-page">
        <div className="content">
          {/* <!-- Start Content--> */}
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Real Time Match Event Earning
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">Real Time Match Event Earning</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                {/* <!-- Portlet card --> */}
                <div className="card">
                  <Link
                    to="#cardCollpase4"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="true"
                    aria-controls="cardCollpase2"
                    className=""
                  >
                    <div className="card-header bg-app text-white">
                      <div className="card-widgets">
                        {/* <Link to="/#" data-toggle="reload" onClick={() => clearFilter()}><i className="mdi mdi-refresh"></i></Link>

                                                <Link to="#cardCollpase4" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="cardCollpase2" className="" >
                                                    <i className="mdi mdi-minus"></i>
                                                </Link> */}
                      </div>
                      <h5 className="card-title mb-0 text-white">Filter</h5>
                    </div>
                  </Link>

                  <div id="cardCollpase4" className="collapse show">
                    <div className="card-body">
                      <div className="row">
                        {/* is active status */}
                        <div className="col-md-2 mb-2">
                          <label className="form-label">Payment Status</label>

                          <Select
                            value={paymentStatus}
                            onChange={handleStatusChange}
                            options={GLOBALS.paymentOption}
                            placeholder="Select"
                            isSearchable={true} // Enables search functionality
                          />
                        </div>
                        <div className="col-md-3 mb-2">
                          <label className="form-label">Date</label>

                          <DateRangePicker
                            initialSettings={{
                              singleDatePicker: true,
                              showDropdowns: true,
                              startDate: moment().format("DD/MM/YYYY"),
                              locale: {
                                format: "DD/MM/YYYY",
                              },
                              minYear: 1901,
                              maxYear: parseInt(moment().format("YYYY"), 10),
                            }}
                            onCallback={handleCallback}
                          >
                            <input
                              type="text"
                              className="form-control col-4"
                              value={selectedDate}
                              placeholder="Select Date"
                              readOnly // To prevent direct user input
                            />
                          </DateRangePicker>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-auto">
                          <div className="text-lg-end my-1 my-lg-0">
                            <button
                              type="button"
                              onClick={() => clearFilter()}
                              className="btn btn-warning waves-effect waves-light me-1"
                            >
                              Clear
                            </button>
                            <button
                              type="button"
                              onClick={() => exportExcel()}
                              className="btn btn-success waves-effect waves-light me-1"
                            >
                              Export
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- end card--> */}
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-body w-100 ">
                    {vevent && (
                      <DataGrid
                        columns={columns}
                        rows={rows}
                        rowHeight={80}
                        disableColumnFilter
                        disableDensitySelector
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true },
                          },
                        }}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                          },
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                      />
                    )}
                  </div>
                  {/* <!-- end card body--> */}
                </div>
                {/* <!-- end card --> */}
              </div>
              {/* <!-- end col--> */}
            </div>
            {/* <!-- end row--> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default RealTimeMatchList;
