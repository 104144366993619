import { useEffect } from 'react'
import { logout } from '../../Api/apiHandler';
import { logOutRedirectCall } from '../../utils/Common';
import * as Alert from '../../utils/Common';
import { useNavigate } from 'react-router-dom'

export default function Logout() {

    const navigate = useNavigate();
    useEffect(() => {

        // For admin logout API
        const adminLogout = async () => {
            try {
                const response = await logout({});

                console.log(response,'response in logout');
                
                if (response.code === 1) {

                    Alert.SuccessAlert(response.data, response.message);

                    logOutRedirectCall();

                    navigate("/");
                } else {
                    Alert.ErrorAlert(response.data, response.message);
                }
            } catch (error) {
                Alert.ErrorAlert('Oops! dd went wrong');
                console.error('Error fetching data:', error);
            }
        };

        adminLogout();

    }, [navigate])
    //     console.log("hello");
    //     useEffect(() => {
    //         // logout({}).then((response) => {

    //         //     if (response.code === 1) {
    //         //         Alert.SuccessAlert(response.data, response.message)
    //         //         logOutRedirectCall();
    //         //         navigate("/")
    //         //     } else {
    //         //         Alert.ErrorAlert(response.data, response.message)
    //         //     }
    //         // })
    //         logoutUser
    //     }, [navigate])

    //     const logoutUser = () => {
    //         logout({}).then((response) => {
    //             if (response.code == 1) {
    //                 // showMessage(response.message)
    //                 Alert.SuccessAlert(response.data, response.message)

    //                 logOutRedirectCall()
    //                 navigate('/')
    //             } else {
    //                 // showErrorMessage(response.message)
    //                 Alert.ErrorAlert(response.data, response.message)

    //             }
    //         })
    //     }

        // return (
        //     <>
        //     </>
        // )
}



